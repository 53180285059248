import { Site } from "@/sites/types/Site";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface SiteState {
  sites: Site[];
  setAll: (sites: Site[]) => void;
}

const useSiteStore = create<SiteState>()(
  persist(
    (set) => ({
      sites: [],
      setAll: (sites) => set((state) => ({ ...state, sites })),
    }),
    {
      name: "site-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useSiteStore;
