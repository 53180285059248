import { useCallback, useMemo } from "react";
import { ConfigProvider, Table } from "antd";
import emptyImage from "@/assets/images/empty-account.svg";
import { withErrorBoundary } from "react-error-boundary";
import FallbackComponent from "@/components/FallbackComponent";
import usePaginatedTable from "@/hooks/usePaginatedTable";
import { UserInfo } from "@/user-control/types/UserInfo";
import StyledTableEmptyState from "@/components/TableEmptyState";
import { QUERY_RETRY_DELAY } from "@/constants/time";
import { useGetUsers } from "@/user-control/api";
import { Condition } from "@/types/Api";
import { Role } from "@/auth/config/roles";
import { displayColumnsBaseOnRole } from "./columns";

interface Props {
  userGroup: Role;
  onRowClick: (record: UserInfo) => void;
}

const UsersTable = ({ userGroup, onRowClick }: Props) => {
  const { pagination, columns } = usePaginatedTable<UserInfo>({
    columns: displayColumnsBaseOnRole(userGroup),
  });

  const onRow = useCallback(
    (record: UserInfo) => {
      return {
        onClick: () => onRowClick(record),
        style: { cursor: "pointer" },
      };
    },
    [onRowClick]
  );

  const selectedUserGroupCondition: Condition = useMemo(() => {
    return {
      column: "Role",
      operator: "EQUALS",
      value: userGroup,
    };
  }, [userGroup]);

  const { isLoading, isError, error, data } = useGetUsers(
    {
      limit: pagination.pageSize,
      page: pagination.current,
      search: [selectedUserGroupCondition],
    },
    {
      networkMode: "online",
      retryDelay: QUERY_RETRY_DELAY,
    }
  );

  if (isError) throw error;

  return (
    <ConfigProvider
      renderEmpty={() => (
        <StyledTableEmptyState
          image={emptyImage}
          description="Start by clicking the “Create New Account” button"
        />
      )}
    >
      <Table<UserInfo>
        dataSource={data?.docs}
        columns={columns}
        loading={isLoading}
        pagination={{
          // Use default page size settings
          ...pagination,
          showSizeChanger: false,
          hideOnSinglePage: true,
        }}
        rowKey={(row) => `${row.UserId}-${row.Email}`}
        showSorterTooltip={false}
        onRow={onRow}
      />
    </ConfigProvider>
  );
};

export default withErrorBoundary(UsersTable, {
  FallbackComponent,
});
