import { Button, message } from "antd";
import { useCallback, useState } from "react";
import PageHeader from "@/components/PageHeader";
import PageTitle from "@/components/PageTitle";
import Page from "@/components/Page";
import PageContent from "@/components/PageContent";
import SitesTable from "@/sites/components/SitesTable";
import SiteDrawer from "@/sites/components/SiteDrawer";
import useBoolean from "@/hooks/useBoolean";
import useSubmitNewSite from "@/sites/hooks/useSubmitNewSite";
import useSubmitSiteUpdate from "@/sites/hooks/useSubmitSiteUpdate";
import { CreateSiteType, Site, UpdateSiteType } from "@/sites/types/Site";
import useSiteStore from "@/sites/stores/sites";

const Index = () => {
  const { sites } = useSiteStore();

  const {
    value: isOpen,
    setTrue: triggerOpen,
    setFalse: triggerClose,
  } = useBoolean(false);
  const [selectedSite, setSelectedSite] = useState<Site | null>(null);
  const {
    save: submitNewSite,
    isLoading: isSubmittingNewSite,
    isError: isSubmittingNewSiteError,
  } = useSubmitNewSite();
  const {
    save: submitSiteUpdate,
    isLoading: isSubmittingSiteUpdate,
    isError: isSubmittingSiteUpdateError,
  } = useSubmitSiteUpdate();
  const createSite = useCallback(
    async (values: CreateSiteType) => {
      // Check if site name already exists
      if (sites.some((site) => site.SiteName === values.SiteName)) {
        message.error("Site name already exists");
        return;
      }

      try {
        await submitNewSite(values);
        triggerClose();
      } catch (error) {
        // TODO: Create error boundary
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    [submitNewSite, triggerClose, sites]
  );
  const updateSite = useCallback(
    async (values: UpdateSiteType) => {
      if (selectedSite?.SiteId) {
        try {
          await submitSiteUpdate({
            ...values,
            SiteId: selectedSite?.SiteId,
          });
          triggerClose();
        } catch (error) {
          // TODO: Create error boundary
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
    },
    [selectedSite?.SiteId, submitSiteUpdate, triggerClose]
  );
  const handleNewClick = useCallback(() => {
    setSelectedSite(null);
    triggerOpen();
  }, [triggerOpen]);
  return (
    <Page>
      <PageHeader>
        <PageTitle title="Sites">
          <Button type="primary" onClick={handleNewClick}>
            Create new Site
          </Button>
        </PageTitle>
      </PageHeader>
      <PageContent>
        <SitesTable
          setSelectedSite={setSelectedSite}
          triggerOpen={triggerOpen}
        />
        <SiteDrawer
          open={isOpen}
          onClose={triggerClose}
          isLoading={isSubmittingNewSite || isSubmittingSiteUpdate}
          isError={isSubmittingNewSiteError || isSubmittingSiteUpdateError}
          onSubmit={selectedSite ? updateSite : createSite}
          selectedSite={selectedSite}
          setSelectedSite={setSelectedSite}
        />
      </PageContent>
    </Page>
  );
};

export default Index;
