import { Role } from "@/auth/config/roles";
import { DrawerType } from "@/user-control/types/Drawer";
import { UserInfo } from "@/user-control/types/UserInfo";
import CreateAccountDrawer from "@/user-control/components/CreateAccountDrawer";
import EditAccountDrawer from "@/user-control/components/EditAccountDrawer";

interface UserControlDrawerProps {
  visible: boolean;
  drawerType?: DrawerType;
  userRole: Role;
  onClose: () => void;
  selectedUser: UserInfo | null;
}

const UserControlDrawers = ({
  visible,
  drawerType,
  userRole,
  onClose,
  selectedUser,
}: UserControlDrawerProps) => {
  if (drawerType === DrawerType.CREATE) {
    return (
      <CreateAccountDrawer
        visible={visible}
        userRole={userRole}
        onClose={onClose}
      />
    );
  }

  if (drawerType === DrawerType.EDIT) {
    return (
      <EditAccountDrawer
        visible={visible}
        userRole={userRole}
        onClose={onClose}
        selectedUser={selectedUser}
      />
    );
  }

  return null;
};

export default UserControlDrawers;
