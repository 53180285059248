import Page from "@/components/Page";
import PageContent from "@/components/PageContent";
import ProfileDrawer from "@/profile/components/ProfileDrawer";

const Index = () => {
  return (
    <Page>
      <PageContent>
        <ProfileDrawer />
      </PageContent>
    </Page>
  );
};

export default Index;
