import { ADMIN, TECHNICIAN, VIEWER } from "@/auth/config/roles";
import { Location, NANAIMO, PRINCE_GEORGE } from "@/reports/config/locations";

export const displayRoleName = (role: string) => {
  switch (role) {
    case ADMIN:
      return "Admin";
    case TECHNICIAN:
      return "Technician";
    case VIEWER:
      return "Viewer";

    default:
      return role;
  }
};

export const displayLocationName = (location: Location) => {
  switch (location) {
    case NANAIMO:
      return "Nanaimo";

    case PRINCE_GEORGE:
      return "Prince George";

    default:
      return location;
  }
};
