import { generateFontSize, generateSizes } from "@/utils/style";

export const colors = {
  daybreakBlue: {
    100: "hsl(217, 100%, 20%)",
    600: "hsl(209, 100%, 55%)",
  },
  dustRed: {
    600: "hsl(357, 91%, 55%)",
    700: "hsl(355, 83%, 44%)",
  },
  gray: {
    400: "#F0F0F0",
    450: "#F5F5F5",
    500: "#D9D9D9",
    700: "#8C8C8C",
    800: "hsl(0, 0%, 35%)",
    900: "#262626",
  },
  green: {
    600: "#52C41A",
  },
  polarGreen: {
    800: "hsl(104, 94%, 24%)",
  },
  black: {
    100: "#000000",
  },
  white: "hsl(0,100%,100%)",
  red: {
    700: "#CF1322",
  },
  yellow: {
    600: "#FAAD14",
  },
};
export const breakpoints = {
  tabletMin: 550,
  laptopMin: 1201, // 1px higher than Samsung Galaxy Tab A8
  desktopMin: 1500,
};
export const queries = {
  tabletAndUp: `(min-width: ${breakpoints.tabletMin}px)`,
  laptopAndUp: `(min-width: ${breakpoints.laptopMin}px)`,
  desktopAndUp: `(min-width: ${breakpoints.desktopMin}px)`,
};
export const spacing = {
  xxxs: "4px",
  xxs: "8px",
  xs: "12px",
  sm: "16px",
  md: "20px",
  lg: "24px",
  xl: "32px",
  xxl: "40px",
  xxxl: "80px",
};
export const sizes = generateSizes(spacing);
export const font = {
  size: generateFontSize(6, 32),
};

const theme = {};

export default theme;
