import { useEffect } from "react";
import { Role } from "@/auth/config/roles";
import { displayRoleName } from "@/utils/display";
import styled from "@emotion/styled";
import useNetwork from "@/stores/network";
import {
  CreateTechnicianAccountType,
  CreateViewerAccountType,
} from "@/user-control/types/Account";
import { Button, Drawer, Space as AntdSpace, Tooltip, message } from "antd";
import { showShareCredentialsModal } from "@/user-control/utils/modal";
import useBoolean from "@/hooks/useBoolean";
import { useUserStore } from "@/auth/stores/user";
import useSubmitCreateAccountCredentials from "@/user-control/hooks/useSubmitCreateAccountCredentials";
import CreateAccountForm from "@/user-control/components/CreateAccountForm";

const Space = styled(AntdSpace)`
  display: flex;
  justify-content: flex-end;
`;
interface Props {
  visible: boolean;
  userRole: Role;
  onClose: () => void;
}

const CreateAccountDrawer = ({ visible, userRole, onClose }: Props) => {
  const { online } = useNetwork();
  const { user } = useUserStore();

  const {
    save: submitAccountCredentials,
    isLoading,
    isError,
  } = useSubmitCreateAccountCredentials({
    onSuccessFn: (values) => {
      showShareCredentialsModal({
        email: values.Email,
        password: values.Password,
        userRole,
        accountName: values.Name,
        adminName: user?.Name,
        onClose,
      });
    },
  });

  const { value: shouldDisable, setValue: changeSubmitBtnDisabledState } =
    useBoolean(true);

  const handleSaveClick = (
    data: CreateTechnicianAccountType | CreateViewerAccountType
  ) => {
    submitAccountCredentials({ ...data, Role: userRole });
  };

  useEffect(() => {
    if (isError) {
      message.error("Something went wrong. Please try again later.");
    }
  }, [isError]);

  return (
    <Drawer
      title={`Create New ${displayRoleName(userRole)} Account`}
      open={visible}
      onClose={onClose}
      closeIcon={null}
      maskClosable={false}
      destroyOnClose
      footer={
        <Space>
          <Button type="default" onClick={onClose}>
            Cancel
          </Button>
          <Tooltip title={online ? "" : "Currently offline"}>
            <Button
              disabled={shouldDisable || isLoading || !online}
              type="primary"
              htmlType="submit"
              form="create-account-form"
            >
              Create
            </Button>
          </Tooltip>
        </Space>
      }
    >
      <CreateAccountForm
        userRole={userRole}
        changeSubmitBtnDisabledState={changeSubmitBtnDisabledState}
        onSubmit={handleSaveClick}
      />
    </Drawer>
  );
};

export default CreateAccountDrawer;
