import { useCallback, useMemo } from "react";
import { CreateSiteType } from "@/sites/types/Site";
import { useCreateSite } from "@/sites/api";

const useSubmitNewSite = () => {
  const { mutateAsync: create, isLoading, isError, error } = useCreateSite();
  const save = useCallback(
    async (values: CreateSiteType) => {
      await create(values);
    },
    [create]
  );
  return useMemo(
    () => ({ save, isLoading, isError, error }),
    [save, isLoading, isError, error]
  );
};

export default useSubmitNewSite;
