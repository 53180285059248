import { useEffect } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useBlocker } from "react-router-dom";

const { confirm } = Modal;

type UnsavedWarningProps = {
  isSaved: boolean | undefined;
};

/**
 * This custom hook is used to warn the user if they are navigating away from the report form without saving.
 * @see {@link https://reactrouter.com/web/api/Hooks/useblocker} for the source code.
 * */
const useUnsavedWarning = ({ isSaved = true }: UnsavedWarningProps) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !isSaved && currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    if (blocker.state === "blocked" && !isSaved) {
      confirm({
        className: "antd-custom-warning-modal",
        title: "Leave Report?",
        icon: <ExclamationCircleOutlined />,
        content:
          "Are you sure you want to leave? Your changes will not be saved",
        okText: "Leave Report",
        okButtonProps: {
          danger: true,
        },
        cancelText: "No, Stay",
        onOk: () => blocker.proceed(),
        onCancel: () => blocker.reset(),
      });
    } else if (blocker.proceed !== undefined) {
      blocker.proceed();
    }
  }, [blocker, isSaved]);
};

export default useUnsavedWarning;
