import axios from "@/lib/axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { PROFILE } from "@/profile/config/api";
import {
  ChangePasswordPayload,
  GetProfileResponse,
  UpdateProfilePayload,
} from "@/profile/types/Api";

const getProfile = () => axios.get<GetProfileResponse>("/api/profile");

export const useGetProfile = () =>
  useQuery({
    queryFn: async () => getProfile(),
    queryKey: [PROFILE],
    select: (response) => response.data,
  });

const updateProfile = (values: UpdateProfilePayload) =>
  axios.put("/api/user", values);

type useUpdateProfileProps = {
  onSuccessFn: () => void;
  onErrorFn: () => void;
};

export const useUpdateProfile = ({
  onSuccessFn,
  onErrorFn,
}: useUpdateProfileProps) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (values: UpdateProfilePayload) => updateProfile(values),
    onSuccess: () => {
      queryClient.invalidateQueries([PROFILE]);
      onSuccessFn();
    },
    onError: () => {
      onErrorFn();
    },
  });
};

type useChangePasswordProps = {
  onSuccessFn: () => void;
};

const changePassword = (values: ChangePasswordPayload) =>
  axios.post("/api/profile/changePassword", values);

export const useChangePassword = ({ onSuccessFn }: useChangePasswordProps) => {
  return useMutation({
    mutationFn: async (values: ChangePasswordPayload) => changePassword(values),
    onSuccess: () => {
      onSuccessFn();
    },
  });
};
