import { useUserStore } from "@/auth/stores/user";
import { ADMIN, TECHNICIAN, VIEWER } from "@/auth/config/roles";

const useRole = () => {
  const user = useUserStore((state) => state.user);
  const role = user?.Role;
  return {
    isAdmin: role === ADMIN,
    isTechnician: role === TECHNICIAN,
    isViewer: role === VIEWER,
    role,
  };
};
export default useRole;
