import { LookupRecord } from "@/lookups/types/Lookup";
import { sortLookupRecordsAlphabetically } from "@/lookups/utils/transform";
import styled from "@emotion/styled";
import { Button, Card, Space, Typography } from "antd";

const Title = styled.h4``;

type LookupItemProps = {
  label: string;
  records: LookupRecord[] | undefined;
  onEditClick: () => void;
};

const LookupItemCard = ({ label, records, onEditClick }: LookupItemProps) => {
  if (!records) return null;

  return (
    <Card
      title={
        <Space align="center">
          <Title>{label}</Title>
          <Typography.Text type="secondary">
            {records.length} {records.length > 1 ? "items" : "item"}
          </Typography.Text>
        </Space>
      }
      extra={<Button onClick={onEditClick}>Edit</Button>}
      style={{ width: "100%" }}
    >
      {sortLookupRecordsAlphabetically(records)
        .map((record) => record.Lookup)
        .join(", ")}
    </Card>
  );
};

export default LookupItemCard;
