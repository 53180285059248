import useBoolean from "@/hooks/useBoolean";
import useNetwork from "@/stores/network";
import styled from "@emotion/styled";
import {
  Button,
  Drawer,
  Space as AntdSpace,
  Tooltip,
  Result,
  message,
} from "antd";
import ProfileForm from "@/profile/components/ProfileForm";
import { useGetProfile, useUpdateProfile } from "@/profile/api";
import Loading from "@/components/Loading";
import { UpdateProfileType } from "@/profile/types/Profile";
import { useCallback } from "react";
import { ADMIN } from "@/auth/config/roles";
import ChangePasswordDrawer from "@/profile/components/ChangePasswordDrawer";
import { useNavigate } from "react-router-dom";

const Space = styled(AntdSpace)`
  display: flex;
  justify-content: flex-end;
`;

const ProfileDrawer = () => {
  const navigate = useNavigate();
  const { online } = useNetwork();

  const { value: showMask, setFalse: hideMask } = useBoolean(true);
  const { value: shouldDisable, setValue: changeSubmitBtnDisabledState } =
    useBoolean(true);
  const {
    value: shouldShowChangePasswordDrawer,
    setFalse: closeChangePasswordDrawer,
    setTrue: openChangePasswordDrawer,
  } = useBoolean(false);

  const { data, isError, isLoading } = useGetProfile();

  const { mutateAsync: updateProfile, isLoading: isUpdateProfileLoading } =
    useUpdateProfile({
      onSuccessFn: () => {
        message.success("Profile updated successfully");
        // Disable save button again after successful update
        changeSubmitBtnDisabledState(true);
      },
      onErrorFn: () => {
        message.error("Profile update failed, please try again");
      },
    });

  const submitUpdateProfile = useCallback(
    (submitData: UpdateProfileType) => {
      if (data) {
        updateProfile({
          UserId: data.UserId,
          Name: submitData.Name,
        });
      }
    },
    [data, updateProfile]
  );

  const onCancel = () => {
    hideMask();
    navigate(-2);
  };

  if (isLoading) return <Loading size="large" />;

  if (isError)
    return (
      <Result
        status="error"
        title="Get Profile Failed"
        subTitle="Please try again later"
      />
    );

  return (
    <Drawer
      title="My Profile"
      placement="right"
      open
      closeIcon={null}
      maskClosable
      mask={showMask}
      footer={
        // Only admin can edit profile
        data !== undefined && (
          <Space>
            <Button type="default" onClick={onCancel}>
              Cancel
            </Button>
            {data.Role === ADMIN && (
              <Tooltip title={online ? "" : "Currently offline"}>
                <Button
                  disabled={shouldDisable || !online || isUpdateProfileLoading}
                  type="primary"
                  htmlType="submit"
                  form="update-profile-form"
                >
                  Save
                </Button>
              </Tooltip>
            )}
          </Space>
        )
      }
    >
      {data !== undefined && (
        <ProfileForm
          userInfo={data}
          onSubmit={submitUpdateProfile}
          changeSubmitBtnDisabledState={changeSubmitBtnDisabledState}
          openChangePasswordDrawer={openChangePasswordDrawer}
        />
      )}
      <ChangePasswordDrawer
        visible={shouldShowChangePasswordDrawer}
        closeDrawer={closeChangePasswordDrawer}
      />
    </Drawer>
  );
};

export default ProfileDrawer;
