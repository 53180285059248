import { ADMIN, Role, TECHNICIAN, VIEWER } from "@/auth/config/roles";
import useNetwork from "@/stores/network";
import { UserInfo } from "@/user-control/types/UserInfo";
import { displayRoleName } from "@/utils/display";
import styled from "@emotion/styled";
import { Button, Drawer, Space as AntdSpace, Tooltip, message } from "antd";
import useBoolean from "@/hooks/useBoolean";
import EditAccountForm from "@/user-control/components/EditAccountForm";
import {
  EditTechnicianAccountType,
  EditViewerAccountType,
} from "@/user-control/types/Account";
import useSubmitEditAccountData from "@/user-control/hooks/useSubmitEditAccountData";
import useSubmitChangePasswordData from "@/user-control/hooks/useSubmitChangePasswordData";
import { showShareCredentialsModal } from "@/user-control/utils/modal";
import { useUserStore } from "@/auth/stores/user";

const Space = styled(AntdSpace)`
  display: flex;
  justify-content: flex-end;
`;
interface Props {
  visible: boolean;
  userRole: Role;
  onClose: () => void;
  selectedUser?: UserInfo | null;
}

const EditAccountDrawer = ({
  visible,
  userRole,
  onClose,
  selectedUser,
}: Props) => {
  const { online } = useNetwork();
  const { user } = useUserStore();

  const { save: submitEditAccountData } = useSubmitEditAccountData();
  const { save: submitChangePasswordData } = useSubmitChangePasswordData({
    onSuccessFn: (newPassword) => {
      showShareCredentialsModal({
        email: selectedUser?.Email,
        password: newPassword,
        userRole,
        accountName: selectedUser?.Name,
        adminName: user?.Name,
        onClose,
      });
    },
  });

  const { value: shouldDisable, setValue: changeSubmitBtnDisabledState } =
    useBoolean(true);
  const { value: shouldResetPassword, setTrue, setFalse } = useBoolean(false);

  const handleSaveClick = async (
    data: EditTechnicianAccountType | EditViewerAccountType
  ) => {
    try {
      if (userRole === TECHNICIAN) {
        await submitEditAccountData({
          UserId: data.UserId,
          Status: data.Status,
          Role: userRole,
          Location: (data as EditTechnicianAccountType).Location,
        });
      } else if (userRole === VIEWER) {
        await submitEditAccountData({
          UserId: data.UserId,
          Status: data.Status,
          Role: userRole,
          Sites: (data as EditViewerAccountType).Sites,
        });
      } else if (userRole === ADMIN) {
        await submitEditAccountData({
          UserId: data.UserId,
          Status: data.Status,
          Role: userRole,
        });
      }

      if (shouldResetPassword) {
        await submitChangePasswordData({
          UserId: data.UserId,
          Password: data.Password,
        });
      }
      // Close drawer if password is not reset after saving changes
      else {
        onClose();
      }
    } catch (error) {
      message.error("Failed to update account, please try again.");
    }
  };

  return (
    <Drawer
      title={`Edit ${displayRoleName(userRole)} Account`}
      open={visible}
      onClose={onClose}
      closeIcon={null}
      maskClosable={false}
      destroyOnClose
      footer={
        <Space>
          <Button type="default" onClick={onClose}>
            Cancel
          </Button>
          <Tooltip title={online ? "" : "Currently offline"}>
            <Button
              disabled={shouldDisable || !online}
              type="primary"
              htmlType="submit"
              form="edit-account-form"
            >
              Save
            </Button>
          </Tooltip>
        </Space>
      }
      afterOpenChange={(open) => {
        // Default reset password  to false when drawer is closed
        if (!open) {
          setFalse();
        }
      }}
    >
      <EditAccountForm
        userRole={userRole}
        onSubmit={handleSaveClick}
        changeSubmitBtnDisabledState={changeSubmitBtnDisabledState}
        selectedUser={selectedUser}
        shouldResetPassword={shouldResetPassword}
        setTrue={setTrue}
      />
    </Drawer>
  );
};

export default EditAccountDrawer;
