import { StyleSheet, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 4,
  },
  rowLight: {
    backgroundColor: "#F0F0F0",
  },
  rowDark: {
    backgroundColor: "#E0E0E0",
  },
  rowCell: {
    flex: 1,
    paddingHorizontal: 4,
  },
  grayText: {
    fontSize: 8,
    color: "#616161",
  },
  darkerGrayText: {
    fontSize: 8,
    color: "#424242",
  },
});

type PartsRowProps = {
  title: string;
  condition: string | undefined | null;
  actionTaken: string | undefined | null;
  recommendation: string | undefined | null;
  dark?: boolean;
};

const PartsRow = ({
  title,
  dark,
  condition,
  actionTaken,
  recommendation,
}: PartsRowProps) => {
  return (
    <View style={[styles.row, dark ? styles.rowDark : styles.rowLight]}>
      <View style={styles.rowCell}>
        <Text style={styles.grayText}>{title}</Text>
      </View>
      <View style={styles.rowCell}>
        <Text style={styles.darkerGrayText}>{condition}</Text>
      </View>
      <View style={styles.rowCell}>
        <Text style={styles.darkerGrayText}>{actionTaken}</Text>
      </View>
      <View style={styles.rowCell}>
        <Text style={styles.darkerGrayText}>{recommendation}</Text>
      </View>
    </View>
  );
};

export default PartsRow;
