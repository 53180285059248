export const IN_PROGRESS = "IN_PROGRESS";
export const FOR_REVIEW = "FOR_REVIEW";
export const SIGNED_OFF = "SIGNED_OFF";
export const PUBLISHED = "PUBLISHED";
export const ARCHIVED = "ARCHIVED";
export const WAREHOUSE = "WAREHOUSE";
export const W_ARCHIVE = "W_ARCHIVE";
export const ALL = "ALL";

export const STATUSES = [
  IN_PROGRESS,
  FOR_REVIEW,
  SIGNED_OFF,
  PUBLISHED,
  ARCHIVED,
  WAREHOUSE,
  W_ARCHIVE,
  ALL,
] as const;

export type Status = (typeof STATUSES)[number];

interface StatusOption {
  value: Status;
  label: string;
  disabled?: boolean;
}

export const STATUS_OPTIONS: StatusOption[] = [
  {
    label: "All Reports",
    value: ALL,
  },
  {
    label: "In Progress",
    value: IN_PROGRESS,
  },
  {
    label: "For Review",
    value: FOR_REVIEW,
  },
  {
    label: "Signed Off",
    value: SIGNED_OFF,
  },
  {
    label: "Published",
    value: PUBLISHED,
  },
  {
    label: "Warehouse",
    value: WAREHOUSE,
  },
  {
    label: "W-Archived",
    value: W_ARCHIVE,
  },
];

export interface SubmitAndTransitionStateArgs {
  newStatus?: Status;
  onComplete?: () => void;
  isSaving: boolean;
  noMessage?: boolean;
}
