import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useUserStore } from "@/auth/stores/user";
import AuthLayout from "@/auth/components/AuthLayout";
import LoginForm from "@/auth/components/LoginForm";
import { LoginType } from "@/auth/types/Login";
import useSubmitLoginCredentials from "@/auth/hooks/useSubmitLoginCredentials";
import { USER_ACTIVE, USER_PENDING } from "@/auth/config/roles";
import { MAX_RETRY_ATTEMPTS } from "@/auth/config/retry";

const Login = () => {
  const { user, clearUser, attempts, setAttempts, retryAfter, setRetryAfter } =
    useUserStore();
  const navigate = useNavigate();
  const {
    save: submitLoginCredentials,
    isLoading: isLoggingIn,
    isError: isLoginError,
  } = useSubmitLoginCredentials();
  useEffect(() => {
    if (user?.Status === USER_ACTIVE) {
      navigate("/");
    }
  }, [user, navigate]);
  useEffect(() => {
    if (dayjs().isAfter(dayjs(retryAfter))) {
      setRetryAfter(null);
      setAttempts(0);
    }
  }, [retryAfter, setAttempts, setRetryAfter]);
  const login = useCallback(
    async (values: LoginType) => {
      try {
        const response = await submitLoginCredentials(values);
        setRetryAfter(null);
        setAttempts(0);
        if (response?.Status === USER_ACTIVE) {
          navigate("/");
        } else if (response?.Status === USER_PENDING) {
          navigate("/reset-password");
        }
      } catch (error) {
        // TODO: Create error boundary
        // eslint-disable-next-line no-console
        console.error(error);
        if (attempts < MAX_RETRY_ATTEMPTS) {
          setAttempts(attempts + 1);
        }
        if (attempts === MAX_RETRY_ATTEMPTS - 1) {
          setRetryAfter(dayjs().add(10, "minutes"));
        }
        if (user) {
          clearUser();
        }
      }
    },
    [
      submitLoginCredentials,
      setAttempts,
      navigate,
      attempts,
      user,
      setRetryAfter,
      clearUser,
    ]
  );
  return (
    <AuthLayout>
      <LoginForm
        onSubmit={login}
        isLoading={isLoggingIn}
        isError={isLoginError}
      />
    </AuthLayout>
  );
};

export default Login;
