import FallbackComponent from "@/components/FallbackComponent";
import Loading from "@/components/Loading";
import { db } from "@/db/index";
import { useGetSafetyValveReport } from "@/reports/api";
import ReportForm from "@/reports/components/ReportForm";
import { FORM_ACTION } from "@/reports/config/formActionType";
import useSaveReport from "@/reports/hooks/useSaveReport";
import useSaveReportOffline from "@/reports/hooks/useSaveReportOffline";
import { locationSchema, ReportId } from "@/reports/types/SafetyValveReport";
import { getPageTitle } from "@/reports/utils/report";
import useNetwork from "@/stores/network";
import { useLiveQuery } from "dexie-react-hooks";
import { useCallback, useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useNavigate, useParams } from "react-router-dom";
import { getASME } from "@/reports/utils/asme";

const Edit = () => {
  const navigate = useNavigate();
  const { online } = useNetwork();
  const { location: locationParam, id: idParam } = useParams();
  const location = locationSchema.parse(locationParam);
  const [id, setId] = useState<ReportId>(() => {
    const isOfflineId = Number.isNaN(Number(idParam));
    if (isOfflineId)
      return {
        value: String(idParam),
        onServer: false,
      };
    return {
      value: Number(idParam),
      onServer: true,
    };
  });

  useEffect(() => {
    if (!idParam) {
      navigate("/");
    }
  }, [idParam, navigate]);

  const { data: defaultOnlineValues, isLoading } = useGetSafetyValveReport(
    id,
    location
  );

  const defaultOfflineValues = useLiveQuery(() =>
    db.reports.get(Number(idParam))
  );

  const title = getPageTitle(
    online ? defaultOnlineValues?.status : defaultOfflineValues?.status
  );

  const onCancel = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const {
    save: onlineSave,
    isSaved: isSavedOnline,
    setSaved: setSavedOnline,
  } = useSaveReport({
    id,
    setId,
    location,
  });

  const {
    save: offlineSave,
    isSaved: isSavedOffline,
    setSaved: setSavedOffline,
  } = useSaveReportOffline({ id });

  if (online && !isLoading) {
    return (
      <ReportForm
        onCancel={onCancel}
        save={onlineSave}
        defaultValues={defaultOnlineValues}
        title={title}
        isSaved={isSavedOnline}
        setSaved={setSavedOnline}
        formActionType={FORM_ACTION.EDIT}
      />
    );
  }

  if (!online && defaultOfflineValues) {
    return (
      <ReportForm
        onCancel={onCancel}
        save={offlineSave}
        defaultValues={{
          ...defaultOfflineValues,
          ASME: getASME(defaultOfflineValues),
        }}
        title={title}
        isSaved={isSavedOffline}
        setSaved={setSavedOffline}
        formActionType={FORM_ACTION.EDIT}
      />
    );
  }

  return <Loading />;
};

export default withErrorBoundary(Edit, {
  FallbackComponent,
});
