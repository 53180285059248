import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Role } from "@/auth/config/roles";
import ShareCredentialsPopup from "@/user-control/components/ShareCredentialsPopup";
import { generateCredentialsDraftEmailLink } from "./email";

const { confirm } = Modal;

type Params = {
  email?: string;
  password?: string;
  userRole: Role;
  accountName?: string;
  adminName?: string;
  onClose: () => void;
};

export const showShareCredentialsModal = ({
  email,
  password,
  userRole,
  accountName,
  adminName,
  onClose,
}: Params) => {
  confirm({
    title: "Share Login Credentials?",
    icon: <ExclamationCircleOutlined />,
    content: (
      <ShareCredentialsPopup
        email={email}
        password={password}
        accountName={accountName}
        userRole={userRole}
        adminName={adminName}
      />
    ),
    okText: "Share New Credentials",
    onOk(e) {
      window.open(
        generateCredentialsDraftEmailLink({
          accountName,
          accountEmail: email,
          accountPassword: password,
          role: userRole,
          adminName,
          // Get mailto link with encodeURIComponent body content
          bodyContentOnly: false,
        }),
        "_blank"
      );
      // Notes: This will return false and prevent the popup close after user click share button.
      return typeof e !== "function";
    },
    cancelText: "Close",
    onCancel() {
      onClose();
    },
  });
};
