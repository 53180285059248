import client from "axios";
import { getItem } from "@/utils/storage";
import { createBrowserRouter } from "react-router-dom";

type Router = ReturnType<typeof createBrowserRouter>;

const axios = client.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const setupInterceptors = (router: Router) => {
  axios.interceptors.request.use(
    (config) => {
      const userInfo = getItem("user-storage");
      if (userInfo) {
        const { state: { user: { Token = null } = {} } = {} } = userInfo;
        if (Token) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${Token}`;
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        router.navigate("/logout");
      }
      return Promise.reject(error);
    }
  );
};

export default axios;
