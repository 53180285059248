import { useCallback, useMemo } from "react";
import { ChangePasswordType } from "@/user-control/types/Account";
import { useChangeAccountPassword } from "@/user-control/api";

type useChangeAccountPasswordProps = {
  onSuccessFn: (newPassword: string) => void;
};

const useSubmitChangePasswordData = ({
  onSuccessFn,
}: useChangeAccountPasswordProps) => {
  const {
    mutateAsync: changePassword,
    isLoading,
    isError,
    isSuccess,
    error,
  } = useChangeAccountPassword({ onSuccessFn });

  const save = useCallback(
    async (values: ChangePasswordType) => {
      try {
        return await changePassword(values);
      } catch (apiError) {
        return apiError;
      }
    },
    [changePassword]
  );

  return useMemo(
    () => ({ save, isLoading, isError, isSuccess, error }),
    [save, isLoading, isError, isSuccess, error]
  );
};

export default useSubmitChangePasswordData;
