import React, { useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import { colors, font, spacing } from "@/config/theme";
import { useQueryClient } from "@tanstack/react-query";
import { Flex, Modal, message } from "antd";
import { QUERY_RETRY_DELAY } from "@/constants/time";
import { DEFAULT_LIMIT } from "@/config/pagination";
import { IN_PROGRESS } from "@/reports/config/status";
import { useGetSafetyValveReports } from "@/reports/api";
import { useUserStore } from "@/auth/stores/user";
import useNetwork from "@/stores/network";
import useBoolean from "@/hooks/useBoolean";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "@/db/index";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { AddedAttachment } from "@/reports/types/Api";
import { populateASME } from "@/reports/utils/asme";
import { useUpsertManyReports } from "@/reports/api/upsert";
import { generateUniqueFileName } from "@/reports/utils/api";
import { filterNullValues } from "@/reports/utils/report";

type Props = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const Title = styled.span`
  font-size: ${font.size[16]};
  font-weight: 600;
  margin-left: ${spacing.xxs};
`;

const ExclamationIcon = styled(ExclamationCircleOutlined)`
  color: ${colors.yellow[600]};
`;

const SyncOfflineReportModal = ({ setIsLoading }: Props) => {
  const {
    value: isModalOpen,
    setTrue: openModal,
    setFalse: closeModal,
  } = useBoolean(false);
  const queryClient = useQueryClient();
  const { online } = useNetwork();
  const { user } = useUserStore();
  const reportsToSync = useLiveQuery(() =>
    db.reports.where("notSynced").equals(1).toArray()
  );
  const { data: reports } = useGetSafetyValveReports(
    {
      limit: DEFAULT_LIMIT,
      page: 1,
      sorter: {
        columnKey: "updatedAt",
        order: "descend",
      },
      search: [
        {
          column: "status",
          operator: "EQUALS",
          value: IN_PROGRESS,
        },
      ],
      location: user?.Location,
    },
    false,
    {
      networkMode: "online",
      retryDelay: QUERY_RETRY_DELAY,
    }
  );
  const { mutateAsync: upsertManyReports, isLoading } = useUpsertManyReports();

  const clearAndPersistReports = useCallback(async () => {
    if (reports?.docs?.length) {
      const reportsToPersist = reports.docs.map((report) => ({
        ...report,
        notSynced: 0,
      }));
      await db.reports.clear();
      await db.reports.bulkPut(reportsToPersist);
    }
  }, [reports?.docs]);

  const syncOfflineReports = useCallback(async () => {
    try {
      if (reports?.docs?.length) {
        if (reportsToSync?.length) {
          openModal();
        } else {
          await clearAndPersistReports();
        }
      }
    } catch (error) {
      // TODO: handle error popup display
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [
    openModal,
    clearAndPersistReports,
    reports?.docs?.length,
    reportsToSync?.length,
    setIsLoading,
  ]);

  useEffect(() => {
    if (online) {
      syncOfflineReports();
    }
  }, [syncOfflineReports, online]);

  const handleOk = useCallback(async () => {
    try {
      const formData = new FormData();
      const stringifiedReportValues = JSON.stringify(
        reportsToSync?.map((report) => {
          const { NewAddedAttachments } = report;
          const attachments: AddedAttachment[] = [];
          if (NewAddedAttachments.length) {
            NewAddedAttachments.forEach((attachment) => {
              const uniqueFileName = generateUniqueFileName(attachment);
              formData.append(
                "attachments",
                attachment.originFileObj as File,
                uniqueFileName
              );
              attachments.push({
                FileName: uniqueFileName,
                IsNew: true,
              });
            });
          }
          return {
            ...filterNullValues(report),
            ...(report.newOfflineReport === 1
              ? {
                  OfflineSafetyValveReportId: report.OfflineSafetyValveReportId,
                  SafetyValveReportId: undefined,
                }
              : {
                  OfflineSafetyValveReportId: undefined,
                }),
            location: user?.Location,
            status: IN_PROGRESS,
            vLift: report.vLift?.toString(),
            ...populateASME(report),
            Attachments: attachments.length ? attachments : undefined,
            // these fields are not allowed in the API
            createdAt: undefined,
            updatedAt: undefined,
            ASME: undefined,
            notSynced: undefined,
            newOfflineReport: undefined,
            NewAddedAttachments: undefined,
            DeletedAttachments: undefined,
          };
        })
      );
      formData.append("reports", stringifiedReportValues);
      await upsertManyReports(formData);
      await clearAndPersistReports();
      queryClient.resetQueries();
      message.success("Sync complete");
    } catch (error) {
      // TODO: handle error popup display
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      closeModal();
      setIsLoading(false);
    }
  }, [
    clearAndPersistReports,
    closeModal,
    queryClient,
    reportsToSync,
    setIsLoading,
    upsertManyReports,
    user?.Location,
  ]);
  return (
    <Modal
      open={isModalOpen}
      title={
        <Flex>
          <ExclamationIcon />
          <Title>Sync offline reports</Title>
        </Flex>
      }
      closeIcon={false}
      okText="Sync"
      confirmLoading={isLoading}
      onOk={handleOk}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      Updates have been made to your offline report. Please sync before
      continuing.
    </Modal>
  );
};

export default SyncOfflineReportModal;
