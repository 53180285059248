import dayjs, { Dayjs } from "dayjs";
import { z } from "zod";
import { LOCATIONS } from "@/reports/config/locations";
import { IN_PROGRESS, STATUSES } from "@/reports/config/status";
import { ASMES } from "@/reports/config/asme";

export const statusSchema = z.enum(STATUSES).default(IN_PROGRESS);

const dateSchema = z
  .string()
  .or(z.instanceof(dayjs as unknown as typeof Dayjs))
  .refine((value) => dayjs(value).isValid(), "Must be a date")
  .optional()
  .nullable();
/* Columns are defined with 50 chars length
 * TODO: Change types in database so that they are longer than 50
 */
const stringSchema = z
  .string()
  .min(0)
  .max(50, "No more than 50 characters")
  .optional()
  .nullable();
const booleanSchema = z.boolean().default(false).nullable();
const numberSchema = z.number().optional().nullable();
// const digitOnlyStringSchema = z
//   .string()
//   .regex(/^\d*\.?\d*$/, "Must be a number")
//   .optional()
//   .nullable();

const AsmeSchema = z.enum(ASMES).optional();

// Generated with ChatGPT :)
export const SafetyValveReportSchema = z.object({
  SafetyValveReportId: numberSchema,
  OfflineSafetyValveReportId: z.string().optional(),
  // site (vCustomer) is the only column that is required
  vCustomer: z.string().min(0).max(50),
  Attachments: z.array(z.any()).optional().default([]),
  vPONumber: stringSchema,
  vPSVNumber: z
    .string()
    .regex(/^[a-zA-Z0-9\-.& ]*$/, "Invalid characters found")
    .optional()
    .nullable(),
  vVVNumber: stringSchema,
  vWONumber: stringSchema,
  vSerialNumber: stringSchema,
  vManufacturer: stringSchema,
  vModelNumber: stringSchema,
  vValveSize: stringSchema,
  vInlet: stringSchema,
  vOutlet: stringSchema,
  vOrifice: stringSchema,
  vBonnet: stringSchema,
  vCapType: stringSchema,
  vBodyMaterial: stringSchema,
  vBellowsMat: stringSchema,
  vSeatMaterial: stringSchema,
  vBDRingLower: stringSchema,
  vBDRingUpper: stringSchema,
  vService: stringSchema,
  vCapacity: stringSchema,
  vCompScrew: stringSchema,
  vUnit: stringSchema,
  vSetPressure: numberSchema,
  vBackPressure: stringSchema,
  vLift: z.coerce
    .number({ invalid_type_error: "Must be a number" })
    .optional()
    .nullable(),
  vSealBroken: booleanSchema,
  vRestrictedLift: booleanSchema,
  vColdDiffSetPressure: numberSchema,
  vCRNNumber: stringSchema,
  vSection: stringSchema,
  rMaintenanceFor: stringSchema,
  rDate: dateSchema,
  rRecievedBy: stringSchema,
  rPreTest: booleanSchema,
  rPrePopped: stringSchema,
  rPreTestNotes: stringSchema,
  rLeaked: stringSchema,
  rPrevRepairCompany: stringSchema,
  rDismbInspBy: stringSchema,
  rSetPressureChange: booleanSchema,
  rNamePlate: booleanSchema,
  rNewPressure: numberSchema,
  rNewCap: stringSchema,
  rLift: stringSchema,
  oSetPressure: stringSchema,
  oTemperature: stringSchema,
  oCapacity: stringSchema,
  oModelNumber: stringSchema,
  pSetPressure: stringSchema,
  pCapacity: stringSchema,
  CapLeverC: stringSchema,
  CapLeverA: stringSchema,
  CapLeverR: stringSchema,
  SpringAdjScrewC: stringSchema,
  SpringAdjScrewA: stringSchema,
  SpringAdjScrewR: stringSchema,
  SpringC: stringSchema,
  SpringA: stringSchema,
  SpringR: stringSchema,
  ButtonsC: stringSchema,
  ButtonsA: stringSchema,
  ButtonsR: stringSchema,
  SpindleC: stringSchema,
  SpindleA: stringSchema,
  SpindleR: stringSchema,
  SpindleRetainerC: stringSchema,
  SpindleRetainerA: stringSchema,
  SpindleRetainerR: stringSchema,
  LiftCollarC: stringSchema,
  LiftCollarA: stringSchema,
  LiftCollarR: stringSchema,
  GuideC: stringSchema,
  GuideA: stringSchema,
  GuideR: stringSchema,
  DiscHolderC: stringSchema,
  DiscHolderA: stringSchema,
  DiscHolderR: stringSchema,
  DiscRetainerC: stringSchema,
  DiscRetainerA: stringSchema,
  DiscRetainerR: stringSchema,
  DiscC: stringSchema,
  DiscA: stringSchema,
  DiscR: stringSchema,
  NozzleC: stringSchema,
  NozzleA: stringSchema,
  NozzleR: stringSchema,
  BDRingUpperC: stringSchema,
  BDRingUpperA: stringSchema,
  BDRingUpperR: stringSchema,
  BDRingLowerC: stringSchema,
  BDRingLowerA: stringSchema,
  BDRingLowerR: stringSchema,
  GasketsC: stringSchema,
  GasketsA: stringSchema,
  GasketsR: stringSchema,
  BellowsC: stringSchema,
  BellowsA: stringSchema,
  BellowsR: stringSchema,
  PistonC: stringSchema,
  PistonA: stringSchema,
  PistonR: stringSchema,
  SleeveC: stringSchema,
  SleeveA: stringSchema,
  SleeveR: stringSchema,
  FlangesC: stringSchema,
  FlangesA: stringSchema,
  FlangesR: stringSchema,
  BonnetC: stringSchema,
  BonnetA: stringSchema,
  BonnetR: stringSchema,
  tAssembledBy: stringSchema,
  tSetPressure: numberSchema,
  tBackPressure: numberSchema,
  tCDTP: numberSchema,
  tFinalSetPressure: numberSchema,
  tTestMedia: stringSchema,
  tBPM: stringSchema,
  tSimmer: numberSchema,
  tBackPressTest: stringSchema,
  tBDRingUpper: stringSchema,
  tBDRingLower: stringSchema,
  tTestedBy: stringSchema,
  tWitnessedBy: stringSchema,
  tDateTested: dateSchema,
  tGauge: stringSchema,
  qPainted: booleanSchema,
  qSealed: booleanSchema,
  qTapedCovered: booleanSchema,
  qTaggedChecked: booleanSchema,
  qShippedUpright: booleanSchema,
  qBellowsVented: booleanSchema,
  qVentHolesPlugged: booleanSchema,
  qSignOff: stringSchema,
  qComments: z.string().optional().nullable(),
  createdAt: dateSchema,
  updatedAt: dateSchema,
  status: statusSchema,
  rUV: booleanSchema.optional(),
  rV: booleanSchema.optional(),
  rIV: booleanSchema.optional(),
  rNV: booleanSchema.optional(),
  ASME: AsmeSchema,
  notSynced: numberSchema.optional(),
  newOfflineReport: numberSchema.optional(),
  NewAddedAttachments: z.array(z.any()).optional().default([]),
  DeletedAttachments: z.array(z.any()).optional().default([]),
  // NOTES: Only viewer can see this field when call get reports
  Location: z.string().optional().nullable(),
});

export const EditSafetyValveReportSchema = SafetyValveReportSchema.extend({
  tBackPressTest: stringSchema,
});

export type Attachment = {
  Link: string;
  AttachmentId: number;
  FileType: string;
  SafetyValveReportId: number;
  FileName: string;
  createdAt: string;
  updatedAt: string;
};

export type SafetyValveReport = z.infer<typeof SafetyValveReportSchema> & {
  Attachments?: Attachment[];
};

export const locationSchema = z.enum(LOCATIONS, {
  description: `Location must be one of: ${LOCATIONS.join(", ")}`,
});
export const ReportWithLocationSchema = SafetyValveReportSchema.extend({
  location: locationSchema,
});

export type SafetyValveReportWithLocation = z.infer<
  typeof ReportWithLocationSchema
> & {
  Attachments?: Attachment[];
};
type OfflineId = {
  onServer: boolean;
  value: string;
};
type OnlineId = {
  onServer: boolean;
  value: number;
};

export type ReportId = OfflineId | OnlineId;
