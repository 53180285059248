import { useCallback, useMemo } from "react";
import { useResetPassword } from "@/auth/api";
import { SubmitResetPasswordType } from "@/auth/types/ResetPassword";
import { useUserStore } from "@/auth/stores/user";

const useSubmitResetCredentials = () => {
  const {
    mutateAsync: resetPassword,
    isLoading,
    isError,
    isSuccess,
    error,
  } = useResetPassword();
  const { clearUser } = useUserStore();
  const save = useCallback(
    async (values: SubmitResetPasswordType) => {
      await resetPassword(values);
      clearUser();
    },
    [clearUser, resetPassword]
  );
  return useMemo(
    () => ({ save, isLoading, isError, isSuccess, error }),
    [save, isLoading, isError, isSuccess, error]
  );
};

export default useSubmitResetCredentials;
