import { Button, Alert, Tooltip } from "antd";
import styled from "@emotion/styled";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  useFormContext,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import PasswordField from "@/components/PasswordField";
import { font, spacing } from "@/config/theme";
import useNetwork from "@/stores/network";
import {
  ResetPasswordSchema,
  ResetPasswordType,
} from "@/auth/types/ResetPassword";
import successIcon from "@/assets/icons/success-check-mark.svg";

type Props = {
  onSubmit: SubmitHandler<ResetPasswordType>;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
};

const Form = styled.form`
  margin-top: ${spacing.lg};
  margin-bottom: ${spacing.lg};
  width: 100%;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${spacing.lg};
`;

const ResetSuccessPrompt = styled.div`
  font-size: ${font.size[16]};
  font-weight: 600;
`;

const ResetPasswordPrompt = styled(ResetSuccessPrompt)`
  text-align: center;
`;

const SuccessIcon = styled.img`
  max-width: 100%;
  margin: ${spacing.xl} auto ${spacing.sm};
`;

type ResetPasswordFormImplProps = {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
};

const ResetPasswordFormImpl = ({
  isLoading,
  isError,
  isSuccess,
}: ResetPasswordFormImplProps) => {
  const {
    formState: { isDirty, isValid },
  } = useFormContext<ResetPasswordType>();
  const { online } = useNetwork();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/login");
  };
  return (
    <FormWrapper>
      {isError && (
        <Alert
          message="An error has occurred, please try again."
          type="error"
          showIcon
          action={
            <Button size="small" ghost danger onClick={handleClick}>
              Try again
            </Button>
          }
        />
      )}
      {isSuccess ? (
        <>
          <ResetSuccessPrompt>
            Password successfully reset. Please log in with your new
            credentials.
          </ResetSuccessPrompt>
          <SuccessIcon src={successIcon} alt="Success" />
          <Button type="primary" onClick={handleClick}>
            Log in
          </Button>
        </>
      ) : (
        <>
          <ResetPasswordPrompt>Reset your password</ResetPasswordPrompt>
          <PasswordField
            label="New Password"
            name="NewPassword"
            type="password"
            showPasswordStrength
          />
          <Tooltip placement="bottom" title={online ? "" : "Currently offline"}>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={!online || !isValid || !isDirty}
            >
              Reset password
            </Button>
          </Tooltip>
        </>
      )}
    </FormWrapper>
  );
};

const ResetPasswordForm = ({ onSubmit, ...props }: Props) => {
  const methods = useForm<ResetPasswordType>({
    resolver: zodResolver(ResetPasswordSchema),
    reValidateMode: "onSubmit",
  });
  const { handleSubmit } = methods;
  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ResetPasswordFormImpl {...props} />
      </Form>
    </FormProvider>
  );
};

export default ResetPasswordForm;
