import Page from "@/components/Page";
import PageHeader from "@/components/PageHeader";
import PageTitle from "@/components/PageTitle";
import { colors, font, spacing } from "@/config/theme";
import {
  EditSafetyValveReportSchema,
  SafetyValveReport,
  SafetyValveReportSchema,
} from "@/reports/types/SafetyValveReport";
import { FormActionType, FORM_ACTION } from "@/reports/config/formActionType";
import {
  ARCHIVED,
  FOR_REVIEW,
  IN_PROGRESS,
  PUBLISHED,
  SIGNED_OFF,
  Status,
  SubmitAndTransitionStateArgs,
  WAREHOUSE,
  W_ARCHIVE,
} from "@/reports/config/status";
import useNetwork from "@/stores/network";
import {
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { Button, Modal, Spin, message } from "antd";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import PageContent from "@/components/PageContent";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useBoolean from "@/hooks/useBoolean";
import { TabItems } from "@/types/Antd";
import PageTabs from "@/components/PageTabs";
import { DEFAULT_SITE_NAME } from "@/reports/config/sites";
import {
  getNextStatusForSubmitButton,
  getSuccessMessage,
} from "@/reports/utils/report";
import SignOffReportModal from "@/reports/components/SignOffReportModal";
import useUnsavedWarning from "@/reports/hooks/useUnsavedWarning";
import useRole from "@/auth/hooks/useRole";
import { IndexableType } from "dexie";
import { duplicateAndArchiveReport } from "@/reports/api/upsert";
import notSyncedIcon from "@/assets/icons/not-syncedBase64String";
import ValveData from "./ValveData";
import PreTestData from "./PreTestData";
import Parts from "./Parts";
import FinalTest from "./FinalTest";
import Attachments from "./Attachments";
import ExportPdfButton from "./ExportPdf/ExportPdfButton";
import CopyReportButton from "./CopyReportButton";

const ButtonContainer = styled.div`
  display: flex;
  gap: ${spacing.xxs};
  flex-wrap: wrap;
`;

const SendToWarehouseButton = styled(Button)`
  color: ${colors.daybreakBlue[600]};
  border-color: ${colors.daybreakBlue[600]};
`;

const showActionButtonTabKeys: string[] = ["Attachments", "Final Test"];

type CustomTabProps = {
  active: boolean;
};

const CustomTab = styled.div<CustomTabProps>`
  display: ${(props) => (props.active ? "block" : "none")};
`;

type Props = {
  defaultValues?: SafetyValveReport;
  save?: (
    values: SafetyValveReport,
    status: Status,
    autoSave: boolean,
    noMessage?: boolean
  ) => Promise<SafetyValveReport | IndexableType>;
  onCancel?: () => void;
  title: React.ReactNode;
  isSaved?: boolean;
  setSaved?: React.Dispatch<React.SetStateAction<boolean>>;
  formActionType?: FormActionType;
  createFromCopy?: boolean;
};

type ReportFormImplProps = Pick<
  Props,
  "title" | "save" | "isSaved" | "setSaved" | "onCancel" | "formActionType"
> & {
  setTab: (tab: string) => void;
  tab: string;
  currentStatus: Status | undefined;
  notSynced?: number | null | undefined;
};

const SaveWrapper = styled.div`
  --save-gap: ${spacing.xxxs};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: var(--save-gap);
  position: relative;
  min-width: 200px;
`;

const SaveMessage = styled.span`
  color: ${colors.gray[800]};
  font-size: ${font.size[12]};
  line-height: 166.667%;
  position: absolute;
  bottom: calc(100% + var(--save-gap));
`;

const NotSyncedWrapper = styled.div`
  margin-bottom: ${spacing.xxs};
  font-weight: 600;
  color: ${colors.red[700]};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
`;

const NotSyncedImage = styled.img`
  width: 20px;
  margin-right: 5px;
`;

const ReportFormImpl = ({
  save,
  setTab,
  tab,
  title,
  isSaved,
  setSaved,
  currentStatus,
  onCancel,
  formActionType,
  notSynced,
}: ReportFormImplProps) => {
  const params = useParams();

  const navigate = useNavigate();

  const {
    value: isSignOffModalOpen,
    setTrue: setSignOffModalOpen,
    setFalse: setSignOffModalClose,
  } = useBoolean(false);
  const {
    value: isEditModeEnabled,
    setTrue: setEditModeEnabled,
    setFalse: setEditModeDisabled,
  } = useBoolean(false);
  const { value: isDataSending, setValue: setIsDataSending } =
    useBoolean(false);

  const [modal, contextHolder] = Modal.useModal();

  const { online } = useNetwork();
  const {
    getValues,
    formState: { isDirty, isValid },
    reset,
    watch,
  } = useFormContext<SafetyValveReport>();
  const attachments = watch("Attachments");
  const existingDeletedAttachments = watch("DeletedAttachments", []);
  const existingNewAddedAttachments = watch("NewAddedAttachments", []);

  const { isAdmin, isTechnician, isViewer } = useRole();
  useEffect(() => {
    if (isDirty) {
      setSaved?.(false);
    }
  }, [isDirty, setSaved]);

  // Warn user if they are navigating away from the report form without saving
  useUnsavedWarning({ isSaved });

  const submitAndTransitionState = useCallback(
    async ({
      newStatus,
      onComplete,
      isSaving,
      noMessage = false,
    }: SubmitAndTransitionStateArgs) => {
      setIsDataSending(true);
      const currentValues = getValues();
      const validValues: SafetyValveReport =
        EditSafetyValveReportSchema.parse(currentValues);
      await save?.(
        validValues,
        newStatus || validValues.status,
        isSaving,
        noMessage
      ).finally(() => {
        setIsDataSending(false);
      });
      reset({
        ...currentValues,
        DeletedAttachments: [],
        ...(online ? { NewAddedAttachments: [] } : {}),
      });
      onComplete?.();
    },
    [getValues, online, reset, save, setIsDataSending]
  );

  const handleSave = useCallback(
    async (noMessage = false) => {
      await submitAndTransitionState({
        isSaving: true,
        noMessage,
      });
      if (isEditModeEnabled) setEditModeDisabled();
    },
    [isEditModeEnabled, setEditModeDisabled, submitAndTransitionState]
  );

  const handleDelete = useCallback(() => {
    return modal.confirm({
      className: "antd-custom-warning-modal",
      title: "Archive Report?",
      icon: <ExclamationCircleOutlined />,
      content:
        "Are you sure you want to archive this report? This action cannot be undone.",
      okText: "Archive Report",
      okButtonProps: {
        danger: true,
      },
      cancelText: "No, Cancel",
      async onOk() {
        submitAndTransitionState({
          newStatus: ARCHIVED,
          onComplete: onCancel,
          isSaving: false,
        });
      },
    });
  }, [onCancel, submitAndTransitionState, modal]);

  const handleDuplicateAndArchive = useCallback(async () => {
    // Save the report before duplicating and archiving if it's not saved
    if (!isSaved) {
      await handleSave(true);
    }

    const { location, id } = params;

    if (location === undefined || id === undefined) {
      message.error(
        "Failed to duplicate and archive report. Missing location or report id."
      );
      return;
    }

    setIsDataSending(true);
    const result = await duplicateAndArchiveReport({
      location,
      reportId: Number(id),
    }).finally(() => {
      setIsDataSending(false);
    });

    // If the request is successful, go to the duplicated report
    if (result.status === 200) {
      message.success(getSuccessMessage(W_ARCHIVE));

      // NOTES: For some reasons,it doesn't jump to the new report directly
      // so we need to navigate to the root first then navigate to the new report
      navigate("/", {
        state: { newReportId: result.data.SafetyValveReportId, location },
      });
    } else {
      message.error("Failed to duplicate and archive report");
    }
  }, [params, handleSave, isSaved, setIsDataSending, navigate]);

  const handleSubmit = useCallback(async () => {
    if (
      currentStatus === IN_PROGRESS ||
      formActionType === FORM_ACTION.CREATE
    ) {
      const confirmed = await modal.confirm({
        title: "Submit Report?",
        className: "antd-custom-warning-modal",
        icon: <ExclamationCircleOutlined />,
        content: "Are you sure you want to submit report for review?",
        okText: "Submit Report",
        cancelText: "No, Not Yet",
      });

      if (confirmed) {
        submitAndTransitionState({
          newStatus: FOR_REVIEW,
          onComplete: onCancel,
          isSaving: false,
        });
      }
    }
    if (currentStatus === FOR_REVIEW) {
      setSignOffModalOpen();
    }
    if (currentStatus === SIGNED_OFF) {
      const confirmed = await modal.confirm({
        title: "Publish Report?",
        className: "antd-custom-warning-modal",
        icon: <ExclamationCircleOutlined />,
        content:
          "Are you sure you want to publish this report? Customers will be able to view the report once published.",
        okText: "Publish Report",
        cancelText: "No, Cancel",
      });

      if (confirmed) {
        submitAndTransitionState({
          newStatus: PUBLISHED,
          onComplete: onCancel,
          isSaving: false,
        });
      }
    }

    if (currentStatus === WAREHOUSE) {
      const confirmed = await modal.confirm({
        title: "Duplicate and Archive Report?",
        className: "antd-custom-warning-modal",
        icon: <ExclamationCircleOutlined />,
        content:
          "Your duplicated report will be sent to In Progress and your original Warehouse report will be moved to W-Archive. Would you like to proceed?",
        okText: "Yes, Proceed",
        cancelText: "No, Cancel",
      });

      if (confirmed) {
        handleDuplicateAndArchive();
      }
    }

    return null;
  }, [
    currentStatus,
    formActionType,
    onCancel,
    setSignOffModalOpen,
    submitAndTransitionState,
    handleDuplicateAndArchive,
    modal,
  ]);

  const handleSendToWarehouse = useCallback(() => {
    return modal.confirm({
      title: "Send Report to Warehouse?",
      className: "antd-custom-warning-modal",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to send this report to the warehouse?",
      okText: "Send to Warehouse",
      cancelText: "No, Cancel",
      async onOk() {
        submitAndTransitionState({
          newStatus: WAREHOUSE,
          onComplete: onCancel,
          isSaving: false,
        });
      },
    });
  }, [onCancel, submitAndTransitionState, modal]);

  const disabled = useMemo(() => {
    return (
      (isAdmin && currentStatus === PUBLISHED && !isEditModeEnabled) ||
      (isTechnician &&
        (currentStatus === SIGNED_OFF || currentStatus === PUBLISHED)) ||
      isViewer
    );
  }, [currentStatus, isAdmin, isEditModeEnabled, isTechnician, isViewer]);

  const items = useMemo<TabItems>(
    () => [
      {
        key: "Valve Data",
        label: "Valve Data",
        children: <ValveData disabled={disabled} />,
        disabled: !isValid,
      },
      {
        key: "Pre Test Data",
        label: "Pre Test Data",
        children: <PreTestData disabled={disabled} />,
        disabled: !isValid,
      },
      {
        key: "Parts",
        label: "Parts",
        children: <Parts disabled={disabled} />,
        disabled: !isValid,
      },
      {
        key: "Final Test",
        label: "Final Test",
        children: (
          <FinalTest currentStatus={currentStatus} disabled={disabled} />
        ),
        disabled: !isValid,
      },
      {
        key: "Attachments",
        label: "Attachments",
        children: (
          <Attachments
            disabled={disabled}
            attachments={attachments}
            existingDeletedAttachments={existingDeletedAttachments}
            existingNewAddedAttachments={existingNewAddedAttachments}
            // NOTES: key is used to force re-render when offline
            key={!online ? existingNewAddedAttachments.length : undefined}
          />
        ),
        disabled: !isValid,
      },
    ],
    [
      attachments,
      currentStatus,
      disabled,
      existingDeletedAttachments,
      existingNewAddedAttachments,
      isValid,
      online,
    ]
  );
  const activeTab = items.find((item) => item.key === tab);
  const handleTabChange = useCallback(
    async (activeKey: string) => {
      if (
        isDirty &&
        (currentStatus !== PUBLISHED ||
          (currentStatus === PUBLISHED && isEditModeEnabled))
      ) {
        await handleSave();
      }
      setTab(activeKey);
    },
    [isDirty, currentStatus, isEditModeEnabled, setTab, handleSave]
  );
  const renderButtons = useCallback(() => {
    const buttons = [];
    if (
      formActionType === FORM_ACTION.EDIT &&
      isAdmin &&
      (currentStatus !== PUBLISHED ||
        (currentStatus === PUBLISHED && isEditModeEnabled))
    ) {
      buttons.push(
        <Button
          key="delete"
          disabled={!online || isDataSending}
          htmlType="button"
          danger
          type="text"
          onClick={handleDelete}
        >
          Archive
        </Button>
      );
    }
    if (
      (isAdmin &&
        (currentStatus !== PUBLISHED ||
          (currentStatus === PUBLISHED && isEditModeEnabled))) ||
      (isTechnician &&
        (formActionType === FORM_ACTION.CREATE ||
          currentStatus === IN_PROGRESS ||
          currentStatus === FOR_REVIEW ||
          currentStatus === WAREHOUSE))
    ) {
      buttons.push(
        <Button
          key="save"
          disabled={!isValid || isDataSending}
          onClick={() => handleSave(false)}
          htmlType="button"
          type={currentStatus === PUBLISHED ? "primary" : "default"}
        >
          Save
        </Button>
      );
    }

    if (
      showActionButtonTabKeys.includes(activeTab?.key ?? "") &&
      (currentStatus === IN_PROGRESS ||
        formActionType === FORM_ACTION.CREATE) &&
      (isAdmin || isTechnician)
    ) {
      buttons.push(
        <SendToWarehouseButton
          key="warehouse"
          htmlType="button"
          disabled={!online || isDataSending}
          onClick={handleSendToWarehouse}
        >
          Send to Warehouse
        </SendToWarehouseButton>
      );
    }

    if (
      // Only show submit button if the active tab in showActionButtonTabKeys
      showActionButtonTabKeys.includes(activeTab?.key ?? "") &&
      ((isAdmin &&
        currentStatus !== PUBLISHED &&
        currentStatus !== W_ARCHIVE) ||
        (isTechnician &&
          currentStatus !== SIGNED_OFF &&
          currentStatus !== PUBLISHED))
    ) {
      buttons.push(
        <Button
          // users can't submit for review while offline
          key="submit"
          disabled={!online || !isValid || isDataSending}
          type="primary"
          htmlType="button"
          onClick={handleSubmit}
        >
          {getNextStatusForSubmitButton(currentStatus)}
        </Button>
      );
    }
    if (isAdmin && currentStatus === PUBLISHED && !isEditModeEnabled) {
      buttons.push(
        <Button key="edit" onClick={setEditModeEnabled}>
          Edit
        </Button>
      );
    }
    if (
      (isAdmin || isTechnician) &&
      currentStatus === PUBLISHED &&
      !isEditModeEnabled
    ) {
      buttons.push(<CopyReportButton key="copy" values={getValues()} />);
    }
    if (
      (isAdmin && currentStatus === PUBLISHED && !isEditModeEnabled) ||
      currentStatus === WAREHOUSE ||
      currentStatus === SIGNED_OFF ||
      isViewer
    ) {
      buttons.push(<ExportPdfButton key="export" />);
    }
    return buttons;
  }, [
    currentStatus,
    formActionType,
    handleDelete,
    handleSave,
    handleSendToWarehouse,
    handleSubmit,
    isAdmin,
    isEditModeEnabled,
    isTechnician,
    isValid,
    isViewer,
    online,
    setEditModeEnabled,
    isDataSending,
    getValues,
    activeTab,
  ]);
  return (
    <Page>
      <PageHeader withTabs>
        <Button type="text" onClick={onCancel} icon={<ArrowLeftOutlined />} />
        <PageTitle title={title}>
          <SaveWrapper>
            {!isSaved && !isViewer && (
              <SaveMessage>You have unsaved changes</SaveMessage>
            )}
            {!online && !!notSynced && (
              <NotSyncedWrapper>
                <NotSyncedImage src={notSyncedIcon} alt="Not Synced" />
                Report is unsynced
              </NotSyncedWrapper>
            )}
            <ButtonContainer>{renderButtons()}</ButtonContainer>
          </SaveWrapper>
        </PageTitle>
        <PageTabs
          activeKey={activeTab?.key}
          items={items.map((item) => ({ ...item, children: undefined }))}
          onChange={handleTabChange}
        />
      </PageHeader>
      <PageContent>
        {items.map((item) => (
          <CustomTab active={activeTab?.key === item.key} key={item.key}>
            {item?.children}
          </CustomTab>
        ))}
      </PageContent>

      <SignOffReportModal
        isOpen={isSignOffModalOpen}
        setModalClose={setSignOffModalClose}
        submitAndTransitionState={submitAndTransitionState}
        onCancel={onCancel}
      />
      <Spin fullscreen spinning={isDataSending} />
      {/* Modal content holder for submit/delete confirmation */}
      <div>{contextHolder}</div>
    </Page>
  );
};

/** The form to create or edit a report.
 * Heads up: Because this form is extremely long, we may want to look into FormProvider's performance
 * @see {@link https://react-hook-form.com/advanced-usage#FormProviderPerformance} for further information on how to improve performance.
 */
const ReportForm = ({
  defaultValues,
  formActionType,
  createFromCopy = false,
  ...props
}: Props) => {
  const [tab, setTab] = useState("Valve Data");

  const defaultValuesSourceForCreate = () => {
    if (createFromCopy) {
      return defaultValues;
    }
    return { vCustomer: DEFAULT_SITE_NAME };
  };

  const methods = useForm<SafetyValveReport>({
    defaultValues:
      formActionType === FORM_ACTION.EDIT || formActionType === FORM_ACTION.VIEW
        ? defaultValues
        : defaultValuesSourceForCreate(),
    resolver: zodResolver(
      formActionType === FORM_ACTION.EDIT || formActionType === FORM_ACTION.VIEW
        ? EditSafetyValveReportSchema
        : SafetyValveReportSchema
    ),
    mode: "all",
    criteriaMode: "firstError",
  });

  return (
    <FormProvider {...methods}>
      <form>
        <ReportFormImpl
          tab={tab}
          setTab={setTab}
          currentStatus={defaultValues?.status}
          notSynced={defaultValues?.notSynced}
          formActionType={formActionType}
          {...props}
        />
      </form>
    </FormProvider>
  );
};

export default ReportForm;
