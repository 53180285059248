import {
  Attachment,
  SafetyValveReport,
} from "@/reports/types/SafetyValveReport";
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { nanoid } from "nanoid";
import { colors } from "@/config/theme";
import PageFooter from "./PageFooter";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    paddingHorizontal: 24,
    paddingTop: 8,
  },
  main: {
    flexDirection: "column",
    flex: 1,
  },
  section: {
    flexDirection: "column",
    paddingBottom: 4,
    borderWidth: 1,
    borderColor: colors.gray[500],
    borderTopWidth: 0,
  },
  sectionHeader: {
    backgroundColor: colors.gray[700],
  },
  sectionTitle: {
    color: "white",
    fontSize: 8,
    fontWeight: "bold",
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  listImageThumbnail: { width: 126, height: 126, objectFit: "cover" },
  attachmentImageContainer: {
    alignItems: "center",
  },
  attachmentListContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    margin: 8,
    gap: 8,
  },
  attachmentImage: {
    width: "98%",
    margin: 8,
    maxHeight: 600,
    objectFit: "contain",
  },
  imgRotSix: {
    transform: "rotate(90deg)",
    width: "100%",
    marginTop: 80,
  },
  borderView: {
    borderWidth: 1,
    borderColor: colors.gray[500],
    borderTopWidth: 0,
  },
});

const AttachmentPages = ({
  data,
  imageAttachments,
  imageOrientations,
}: {
  data: SafetyValveReport;
  imageAttachments: Attachment[];
  imageOrientations: string[];
}) => {
  const renderAttachmentDetailPages = () => {
    const renderAttachmentPages = imageAttachments.map(
      (item, index, totalList) => {
        // If image is portrait, apply rotation style.
        // NOTES: This is because a bug in react-pdf where it does not render portrait images correctly.
        // See: https://github.com/diegomura/react-pdf/issues/1848#issuecomment-1662313194
        const imageStyle =
          imageOrientations[index] === "right-top" ? styles.imgRotSix : {};

        return (
          <Page key={nanoid()} size="A4" style={styles.page}>
            <View style={[styles.main, styles.borderView]}>
              <View style={styles.sectionHeader}>
                <Text style={styles.sectionTitle}>{`Attachment ${index + 1}/${
                  totalList.length
                }`}</Text>
              </View>
              <View style={styles.attachmentImageContainer}>
                <Image
                  style={{ ...styles.attachmentImage, ...imageStyle }}
                  src={item.Link}
                />
              </View>
            </View>

            <PageFooter data={data} />
          </Page>
        );
      }
    );

    return renderAttachmentPages;
  };

  return (
    <>
      {/* Attachment Detail Pages */}
      {renderAttachmentDetailPages()}
    </>
  );
};

export default AttachmentPages;
