import { colors, spacing } from "@/config/theme";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

type HeaderProps = {
  withTabs?: boolean;
};
const PageHeader = styled.header<HeaderProps>`
  background-color: ${colors.white};
  padding: ${spacing.lg};
  display: flex;
  flex-direction: column;
  gap: ${spacing.sm};
  position: sticky;
  top: calc(${spacing.lg} * -1);
  z-index: 2;
  ${(props) =>
    props.withTabs &&
    css`
      padding-bottom: 0;
    `}
`;
export default PageHeader;
