import { useCallback } from "react";
import { useUserStore } from "@/auth/stores/user";
import AuthLayout from "@/auth/components/AuthLayout";
import ResetPasswordForm from "@/auth/components/ResetPasswordForm";
import { ResetPasswordType } from "@/auth/types/ResetPassword";
import useSubmitResetCredentials from "@/auth/hooks/useSubmitResetCredentials";

const ResetPassword = () => {
  const { user, clearUser, oldPassword } = useUserStore();
  const {
    save: submitResetCredentials,
    isLoading,
    isError,
    isSuccess,
  } = useSubmitResetCredentials();
  const resetPassword = useCallback(
    async (values: ResetPasswordType) => {
      try {
        await submitResetCredentials({
          Email: user?.Email || "",
          OldPassword: oldPassword || "",
          NewPassword: values.NewPassword,
          VerifyPassword: values.NewPassword,
        });
      } catch (error) {
        // TODO: Create error boundary
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        clearUser();
      }
    },
    [submitResetCredentials, user?.Email, oldPassword, clearUser]
  );
  return (
    <AuthLayout>
      <ResetPasswordForm
        onSubmit={resetPassword}
        isLoading={isLoading}
        isError={isError}
        isSuccess={isSuccess}
      />
    </AuthLayout>
  );
};

export default ResetPassword;
