import { spacing } from "@/config/theme";
import { UserStatus } from "@/user-control/types/UserStatus";
import styled from "@emotion/styled";
import { Radio, Space } from "antd";
import { useController } from "react-hook-form";
import StatusTag from "@/user-control/components/StatusTag";

const Span = styled.span`
  margin-right: ${spacing.sm};
  display: inline-block;
  width: 60px;
`;

const StatusSelector = () => {
  const { field } = useController({
    name: "Status",
  });

  return (
    <div>
      <Span>Status</Span>
      <Radio.Group id="Status" {...field}>
        <Space direction="vertical">
          <Radio value={UserStatus.ACTIVE}>
            <StatusTag status={UserStatus.ACTIVE} />
          </Radio>
          <Radio value={UserStatus.INACTIVE}>
            <StatusTag status={UserStatus.INACTIVE} />
          </Radio>
        </Space>
      </Radio.Group>
    </div>
  );
};

export default StatusSelector;
