import FallbackComponent from "@/components/FallbackComponent";
import ReportForm from "@/reports/components/ReportForm";
import { FORM_ACTION } from "@/reports/config/formActionType";
import useSaveReport from "@/reports/hooks/useSaveReport";
import useSaveReportOffline from "@/reports/hooks/useSaveReportOffline";
import { ReportId } from "@/reports/types/SafetyValveReport";
import useNetwork from "@/stores/network";
import { nanoid } from "nanoid";
import { useCallback, useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useLocation, useNavigate } from "react-router-dom";

const Create = () => {
  const navigate = useNavigate();
  const { online } = useNetwork();
  const { state } = useLocation();
  const location = state?.location;

  useEffect(() => {
    if (!location) {
      navigate("/");
    }
  }, [location, navigate]);

  const [id, setId] = useState<ReportId>(() => ({
    value: nanoid(),
    onServer: false,
  }));

  const onCancel = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const {
    save: onlineSave,
    isSaved: isSavedOnline,
    setSaved: setSavedOnline,
  } = useSaveReport({
    id,
    setId,
    location,
  });

  const {
    save: offlineSave,
    isSaved: isSavedOffline,
    setSaved: setSavedOffline,
  } = useSaveReportOffline({ id, setId });

  return (
    <ReportForm
      onCancel={onCancel}
      save={online ? onlineSave : offlineSave}
      title="New Report"
      isSaved={online ? isSavedOnline : isSavedOffline}
      setSaved={online ? setSavedOnline : setSavedOffline}
      formActionType={FORM_ACTION.CREATE}
      createFromCopy={state?.copyReportValues !== undefined}
      defaultValues={state?.copyReportValues || undefined}
    />
  );
};

export default withErrorBoundary(Create, {
  FallbackComponent,
});
