import styled from "@emotion/styled";
import { Button, Space, Tooltip } from "antd";
import { useFormContext } from "react-hook-form";
import useNetwork from "@/stores/network";
import { UpdateSiteType } from "@/sites/types/Site";

type Props = {
  onSubmit: (values: UpdateSiteType) => Promise<void>;
  onClose: () => void;
  isLoading: boolean;
};

const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const Footer = ({ onClose, isLoading, onSubmit }: Props) => {
  const {
    formState: { isDirty, isValid },
    handleSubmit,
  } = useFormContext<UpdateSiteType>();
  const { online } = useNetwork();
  return (
    <FooterWrapper>
      <Space>
        <Button onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <Tooltip placement="bottom" title={online ? "" : "Currently offline"}>
          <Button
            type="primary"
            onClick={handleSubmit(onSubmit)}
            loading={isLoading}
            disabled={!online || !isValid || !isDirty}
          >
            Save
          </Button>
        </Tooltip>
      </Space>
    </FooterWrapper>
  );
};

export default Footer;
