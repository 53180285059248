import { z } from "zod";

export type Category =
  | "Action"
  | "AssembledBy"
  | "BellowsMat"
  | "BodyMaterial"
  | "Bonnet"
  | "CapType"
  | "Company"
  | "Condition"
  | "Inlet"
  | "MaintenanceFor"
  | "Manufacturer"
  | "Orifice"
  | "Outlet"
  | "PreTestNotes"
  | "Recommendation"
  | "Section"
  | "Service"
  | "Shipping"
  | "SoftSeat"
  | "Staff"
  | "TestMedia"
  | "Unit"
  | "";

export type LookupRecord = {
  LookupId: number | string;
  FieldName: Category;
  Lookup: string;
  Description?: string;
  Show: boolean;
};

export type UpdatedLookupRecord = {
  LookupId: number | string;
  FieldName?: Category;
  Lookup?: string;
  Description?: string;
  Show?: boolean;
};

export type LookupDictionary = Partial<Record<Category, LookupRecord[]>>;

export const newLookupSchema = z
  .string()
  .min(1)
  .max(50)
  .regex(/^[A-Za-z0-9/. -]*$/, "Invalid characters found");

export const editLookupSchema = newLookupSchema;
