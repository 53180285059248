import { z } from "zod";

export const ResetPasswordSchema = z.object({
  NewPassword: z
    .string()
    .min(8)
    .refine((value) => /[a-z]/g.test(value))
    .refine((value) => /[A-Z]/g.test(value))
    .refine((value) => /[0-9]/g.test(value)),
});

export type ResetPasswordType = z.infer<typeof ResetPasswordSchema>;

export type SubmitResetPasswordType = {
  Email: string;
  OldPassword: string;
  NewPassword: string;
  VerifyPassword: string;
};
