import { useEffect, useState } from "react";
import useLookupStore from "@/lookups/stores/lookups";
import { Category, LookupRecord } from "@/lookups/types/Lookup";
import styled from "@emotion/styled";
import { nanoid } from "nanoid";
import { useAddNewLookups, useUpdateLookups } from "@/lookups/api";
import { colors, font, spacing } from "@/config/theme";
import {
  assembleUpdatedLookups,
  sortLookupRecordsAlphabetically,
} from "@/lookups/utils/transform";
import { Button, Drawer, Space as AntdSpace } from "antd";
import ListItem from "./ListItem";
import AddItemField from "./AddItemField";

const Space = styled(AntdSpace)`
  display: flex;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Subtitle = styled.h3`
  font-size: ${font.size[14]};
  font-weight: bold;
  color: ${colors.gray[800]};
`;

const ListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: ${spacing.xxxs};
  border: 1px solid ${colors.gray[500]};
  overflow-y: scroll;
  flex: 1;
`;

type EditLookupDrawerProps = {
  lookupKey: Category;
  visible: boolean;
  onClose: () => void;
};

const EditLookupDrawer = ({
  lookupKey,
  visible,
  onClose,
}: EditLookupDrawerProps) => {
  const { lookups } = useLookupStore();

  const [recordList, setRecordList] = useState<LookupRecord[]>(
    lookups[lookupKey] ?? []
  );
  // Keep track of the new added records
  const [newAddedRecords, setNewAddedRecords] = useState<LookupRecord[]>([]);
  // Keep track of the removed records
  const [removedRecords, setRemovedRecords] = useState<LookupRecord[]>([]);
  // Keep track of the updated records with name changed
  const [updatedRecords, setUpdatedRecords] = useState<LookupRecord[]>([]);

  const { mutateAsync: addNewLookups, isLoading: isAddingNewLookups } =
    useAddNewLookups({
      onSuccessFn: onClose,
    });
  const { mutateAsync: updateLookups, isLoading: isUpdatingLookups } =
    useUpdateLookups({
      onSuccessFn: onClose,
    });

  useEffect(() => {
    // Reset the record lists when the drawer is opened or lookups are updated
    if (visible) {
      setRecordList(lookups[lookupKey] ?? []);
      setRemovedRecords([]);
      setNewAddedRecords([]);
      setUpdatedRecords([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, lookups]);

  const handleAddItemClick = (value: string) => {
    const newRecord: LookupRecord = {
      LookupId: nanoid(5), // Temporary ID for new record
      FieldName: lookupKey,
      Lookup: value,
      Description: "",
      Show: true,
    };

    setNewAddedRecords((prev) => [...prev, newRecord]);
    setRecordList((prev) => [...prev, newRecord]);
  };

  const handleRemoveRecordItem = (record: LookupRecord) => {
    // If lookup is string, means it is a new record that has not been saved to the database. DO NOT add to removedRecords
    if (typeof record.LookupId === "number") {
      setRemovedRecords((prev) => [...prev, record]);
    }
    // If lookup is string, means it is a new record that has not been saved to the database. Remove from newAddedRecords instead
    else if (typeof record.LookupId === "string") {
      setNewAddedRecords((prev) =>
        prev.filter((item) => item.LookupId !== record.LookupId)
      );
    }

    setRecordList((prev) =>
      prev.filter((item) => item.LookupId !== record.LookupId)
    );
  };

  const handleEditRecordItem = (record: LookupRecord, newValue: string) => {
    // If lookup is string, means it is a new record that has not been saved to the database. DO NOT add to updatedRecords
    if (typeof record.LookupId === "number") {
      setUpdatedRecords((prev) => [
        ...prev,
        {
          ...record,
          Lookup: newValue,
        },
      ]);
    }
    // If lookup is string, means it is a new record that has not been saved to the database. Update the newAddedRecords instead
    else if (typeof record.LookupId === "string") {
      const copyOfNewAddedRecords = [...newAddedRecords];
      const index = copyOfNewAddedRecords.findIndex(
        (item) => item.LookupId === record.LookupId
      );
      copyOfNewAddedRecords[index].Lookup = newValue;
      setNewAddedRecords(copyOfNewAddedRecords);
    }

    setRecordList((prev) =>
      prev.map((item) => {
        if (item.LookupId === record.LookupId) {
          return {
            ...item,
            Lookup: newValue,
          };
        }
        return item;
      })
    );
  };

  const handleSave = async () => {
    const lookupForUpdate = assembleUpdatedLookups(
      updatedRecords,
      removedRecords
    );
    let changeHasBeenMade = false;

    // If there is new added records, add them to the database
    if (newAddedRecords.length > 0) {
      changeHasBeenMade = true;
      addNewLookups(newAddedRecords);
    }

    // If there is removed records, update them in the database
    if (lookupForUpdate.length > 0) {
      changeHasBeenMade = true;
      updateLookups(lookupForUpdate);
    }

    // If there is no change has been made, close the drawer directly
    if (!changeHasBeenMade) {
      onClose();
    }
  };

  const renderList = () => {
    return sortLookupRecordsAlphabetically(recordList).map((item) => {
      return (
        <ListItem
          key={item.LookupId}
          record={item}
          editRecord={handleEditRecordItem}
          removeRecord={handleRemoveRecordItem}
          allowDelete={recordList.length > 1}
        />
      );
    });
  };

  return (
    <Drawer
      title={lookupKey}
      onClose={onClose}
      open={visible}
      closeIcon={null}
      maskClosable={false}
      destroyOnClose
      footer={
        <Space>
          <Button type="default" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            disabled={
              [...newAddedRecords, ...updatedRecords, ...removedRecords]
                .length === 0
            }
            type="primary"
            loading={isUpdatingLookups || isAddingNewLookups}
          >
            Save
          </Button>
        </Space>
      }
    >
      <Wrapper>
        <Subtitle>Item List</Subtitle>
        <AddItemField onAdd={handleAddItemClick} />
        <ListContainer>{renderList()}</ListContainer>
      </Wrapper>
    </Drawer>
  );
};

export default EditLookupDrawer;
