import useBoolean from "@/hooks/useBoolean";
import { useState } from "react";
import useLookupStore from "@/lookups/stores/lookups";
import styled from "@emotion/styled";
import LookupItemCard from "@/lookups/components/LookupItemCard";
import { LOOKUP_TABS_DICTIONARY } from "@/lookups/config/dictionary";
import EditLookupDrawer from "@/lookups/components/EditLookupDrawer";
import { Category } from "@/lookups/types/Lookup";

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns with equal width */
  gap: 16px; /* Gap between columns */
`;

type LookupTabProps = {
  tabKey: string;
};

const LookupTab = ({ tabKey }: LookupTabProps) => {
  const { lookups } = useLookupStore();

  const {
    value: drawerVisible,
    setTrue: showDrawer,
    setFalse: hideDrawer,
  } = useBoolean(false);

  const [selectedLookupKey, setSelectedLookupKey] = useState<Category>("");

  const handleEditClick = (lookupKey: Category) => {
    showDrawer();
    setSelectedLookupKey(lookupKey);
  };

  return (
    <ContentWrapper>
      {LOOKUP_TABS_DICTIONARY[tabKey].map((item) => {
        return (
          <LookupItemCard
            key={`${item.key}-${item.label}`}
            label={item.label}
            records={lookups[item.key]}
            onEditClick={() => handleEditClick(item.key)}
          />
        );
      })}
      <EditLookupDrawer
        visible={drawerVisible}
        lookupKey={selectedLookupKey}
        onClose={hideDrawer}
      />
    </ContentWrapper>
  );
};

export default LookupTab;
