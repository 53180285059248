import { create } from "zustand";

const now = () => new Date().toString();

interface NetworkInfo {
  online: boolean;
  since?: string;
}

const useNetwork = create<NetworkInfo>(() => ({
  online: navigator.onLine,
  since: navigator.onLine ? now() : undefined,
}));

const handleOnline = () => {
  useNetwork.setState({
    online: true,
    since: now(),
  });
};

const handleOffline = () => {
  useNetwork.setState({
    online: false,
    since: now(),
  });
};

window.addEventListener("online", handleOnline);
window.addEventListener("offline", handleOffline);

export default useNetwork;
