import React, { useCallback, useMemo } from "react";
import { uploadAttachments, useUpsertManyReports } from "@/reports/api/upsert";
import { Location } from "@/reports/config/locations";
import { Status } from "@/reports/config/status";
import { ReportId, SafetyValveReport } from "@/reports/types/SafetyValveReport";
import {
  assembleAttachmentFormData,
  readSingleUpsertResult,
} from "@/reports/utils/api";
import useBoolean from "@/hooks/useBoolean";
import { message } from "antd";
import { populateASME } from "@/reports/utils/asme";
import { getSuccessMessage, filterNullValues } from "@/reports/utils/report";
import { useQueryClient } from "@tanstack/react-query";
import { REPORTS_API } from "@/reports/config/api";

interface SaveReportOptions {
  id: ReportId;
  setId: React.Dispatch<React.SetStateAction<ReportId>>;
  location: Location;
}
const useSaveReport = ({ id, setId, location }: SaveReportOptions) => {
  const queryClient = useQueryClient();
  const { mutateAsync: upsertManyReports } = useUpsertManyReports();
  const { value: isSaved, setValue: setSaved } = useBoolean(true);
  const save = useCallback(
    async (
      values: SafetyValveReport,
      status: Status,
      autoSave: boolean,
      noMessage = false
    ) => {
      const stringifiedReportValues = JSON.stringify([
        {
          ...filterNullValues(values),
          ...(id.onServer && {
            SafetyValveReportId: id.value,
          }),
          ...(!id.onServer && {
            OfflineSafetyValveReportId: id.value,
          }),
          location,
          status,
          vLift: values.vLift?.toString(),
          ...populateASME(values),
          // these fields are not allowed in the API
          createdAt: undefined,
          updatedAt: undefined,
          ASME: undefined,
          notSynced: undefined,
          isNewOfflineReport: undefined,
          Attachments: undefined,
          NewAddedAttachments: undefined,
          DeletedAttachments: undefined,
        },
      ]);

      const formData = new FormData();

      formData.append("reports", stringifiedReportValues);

      const {
        data: { result: upsertResult },
      } = await upsertManyReports(formData);
      const result = readSingleUpsertResult(upsertResult);

      if (result.SafetyValveReportId) {
        setId({
          onServer: true,
          value: result.SafetyValveReportId,
        });

        const { NewAddedAttachments, DeletedAttachments } = values;

        // Assemble form data for attachments if there are any
        if (NewAddedAttachments.length > 0 || DeletedAttachments.length > 0) {
          const attachmentFormData = assembleAttachmentFormData(
            NewAddedAttachments,
            DeletedAttachments
          );
          try {
            // Upload attachments to the server
            await uploadAttachments({
              location,
              reportId: result.SafetyValveReportId,
              formData: attachmentFormData,
            });

            // Invalidate the query to refetch the report with the new attachments
            queryClient.invalidateQueries([REPORTS_API, "GET /:id", id]);
          } catch (error) {
            message.error("Error uploading attachments");
          }
        }
      }
      setSaved(true);

      if (!noMessage) {
        message.open({
          type: "success",
          content: autoSave ? "Report saved" : getSuccessMessage(status),
        });
      }

      return result;
    },
    [upsertManyReports, id, location, setId, setSaved, queryClient]
  );
  return useMemo(
    () => ({ isSaved, setSaved, save }),
    [isSaved, setSaved, save]
  );
};
export default useSaveReport;
