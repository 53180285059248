import { createBrowserRouter } from "react-router-dom";
import Layout from "@/components/Layout";
import Reports from "@/reports/pages/Index";
import Login from "@/auth/pages/Login";
import Logout from "@/auth/pages/Logout";
import ResetPassword from "@/auth/pages/ResetPassword";
import CreateReport from "@/reports/pages/Create";
import EditReport from "@/reports/pages/Edit";
import ViewReport from "@/reports/pages/View";
import Lookup from "@/lookups/pages/Index";
import UserControl from "@/user-control/pages/Index";
import Sites from "@/sites/pages/Index";
import Profile from "@/profile/pages/Index";
import PageNotFound from "@/components/PageNotFound";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        index: true,
        element: <Reports />,
      },
      {
        path: "/reports/new",
        element: <CreateReport />,
      },
      {
        path: "/reports/edit/:location/:id",
        element: <EditReport />,
      },
      {
        path: "/reports/view/:location/:id",
        element: <ViewReport />,
      },
      {
        path: "/lookups",
        element: <Lookup />,
      },
      {
        path: "/user-management",
        element: <UserControl />,
      },

      {
        path: "/sites",
        element: <Sites />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/logout",
        element: <Logout />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
]);

export default router;
