import { DeletedAttachment, UpsertResult } from "@/reports/types/Api";
import {
  SafetyValveReport,
  SafetyValveReportWithLocation,
} from "@/reports/types/SafetyValveReport";
import { shortId } from "@/utils/uniqueId";
import { UploadFile } from "antd";

type UpsertOutput = {
  created: SafetyValveReportWithLocation[];
  updated: SafetyValveReportWithLocation[];
};

export const readUpsertResult = (result: UpsertResult[]): UpsertOutput => {
  return result.reduce<UpsertOutput>(
    (prev, current: UpsertResult) => {
      const { location } = current;
      const addLocation = (
        report: SafetyValveReport
      ): SafetyValveReportWithLocation => ({
        ...report,
        location,
      });
      const created = current.created.map(addLocation);
      const updated = current.updated.map(addLocation);
      return {
        created: prev.created.concat(created),
        updated: prev.updated.concat(updated),
      };
    },
    { created: [], updated: [] }
  );
};

/** Reads a single report that was updated/created using the upsert API.
 * Useful when creating or editing a single report.
 * Should not be used for multiple reports.
 */
export const readSingleUpsertResult = (
  result: UpsertResult[]
): SafetyValveReportWithLocation => {
  const { created, updated } = readUpsertResult(result);
  const modifications = [...created, ...updated];
  return modifications[0];
};

export const generateUniqueFileName = (attachment: UploadFile) => {
  let uniqueFileName = shortId(3);

  // Use a regular expression to match the file name and extension
  const regex = /^(.*[^.])\.(\w+)$/;
  const match = attachment.name.match(regex);

  if (match) {
    const baseName = match[1]; // Get everything before the last dot
    const extension = match[2]; // Get the file extension after the last dot

    uniqueFileName = `${baseName}-${uniqueFileName}.${extension}`;
  } else {
    const fileExtension =
      attachment.type !== undefined
        ? attachment.type.split("/").pop()
        : attachment.name.split(".").pop();
    uniqueFileName = `${uniqueFileName}.${fileExtension}`;
  }
  return uniqueFileName;
};

export const assembleAttachmentFormData = (
  newAddedAttachments: UploadFile[] = [],
  deletedAttachments: DeletedAttachment[] = []
): FormData => {
  const attachmentFormData = new FormData();
  let accumulatedIndex = 0;
  newAddedAttachments.forEach((attachment, index) => {
    const uniqueFileName = generateUniqueFileName(attachment);
    attachmentFormData.append(
      `attachments`,
      attachment.originFileObj as File,
      uniqueFileName
    );
    attachmentFormData.append(
      `Attachments[${index}][FileName]`,
      uniqueFileName
    );
    attachmentFormData.append(`Attachments[${index}][IsNew]`, "true");
    attachmentFormData.append(`Attachments[${index}][IsDeleted]`, "false");
    accumulatedIndex = index + 1;
  });

  // Delete attachments from the server
  deletedAttachments.forEach((attachment, index) => {
    // Non attachment id means it's a new attachment, not from server, no need to delete
    if (attachment.AttachmentId === undefined) return;

    attachmentFormData.append(
      `Attachments[${accumulatedIndex + index}][AttachmentId]`,
      attachment.AttachmentId.toString()
    );
    attachmentFormData.append(
      `Attachments[${accumulatedIndex + index}][IsDeleted]`,
      "true"
    );
    attachmentFormData.append(
      `Attachments[${accumulatedIndex + index}][IsNew]`,
      "false"
    );
  });

  return attachmentFormData;
};
