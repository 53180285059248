import { Role } from "@/auth/config/roles";
import { generateCredentialsDraftEmailLink } from "@/user-control/utils/email";
import { displayRoleName } from "@/utils/display";
import { CopyOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Tooltip, message } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CopyButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface Props {
  email?: string;
  password?: string;
  userRole: Role;
  accountName?: string;
  adminName?: string;
}

const ShareCredentialsPopup = ({
  email = "",
  password = "",
  userRole,
  accountName = "",
  adminName = "",
}: Props) => {
  return (
    <div>
      <p>
        Do you want to share the Login Credentials with the newly created
        {` ${displayRoleName(userRole)}`}?
      </p>
      <p>You can send the information at a later time as well.</p>
      <br />
      <InfoContainer>
        <div>
          <p>
            Email: <b>{email}</b>
          </p>
          <p>
            Password: <b>{password}</b>
          </p>
        </div>
        <CopyToClipboard
          text={generateCredentialsDraftEmailLink({
            accountName,
            accountEmail: email,
            accountPassword: password,
            role: userRole,
            adminName,
            // Get body content without mailto link format
            bodyContentOnly: true,
          })}
          onCopy={() => message.success("Copied to Clipboard")}
        >
          <CopyButtonContainer>
            <Tooltip title="Copy to Clipboard">
              <Button icon={<CopyOutlined />} />
            </Tooltip>
          </CopyButtonContainer>
        </CopyToClipboard>
      </InfoContainer>
      <br />
    </div>
  );
};

export default ShareCredentialsPopup;
