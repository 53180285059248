import FallbackComponent from "@/components/FallbackComponent";
import Loading from "@/components/Loading";
import { useGetSafetyValveReport } from "@/reports/api";
import useRole from "@/auth/hooks/useRole";
import ReportForm from "@/reports/components/ReportForm";
import { locationSchema } from "@/reports/types/SafetyValveReport";
import { useCallback, useEffect } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useNavigate, useParams } from "react-router-dom";
import { FORM_ACTION } from "@/reports/config/formActionType";

const View = () => {
  const navigate = useNavigate();
  const { isViewer } = useRole();
  const { location: locationParam, id: idParam } = useParams();
  const location = locationSchema.parse(locationParam);
  const id = {
    value: Number(idParam),
    onServer: true,
  };

  useEffect(() => {
    if (!idParam) {
      navigate("/");
    }
  }, [idParam, navigate]);

  const { data: defaultValues, isLoading } = useGetSafetyValveReport(
    id,
    location,
    isViewer
  );

  const onCancel = useCallback(() => {
    navigate("/");
  }, [navigate]);

  if (isLoading) return <Loading />;

  return (
    <ReportForm
      onCancel={onCancel}
      defaultValues={defaultValues}
      title={`${defaultValues.vCustomer || ""}_${
        defaultValues.vPSVNumber || ""
      }`}
      formActionType={FORM_ACTION.VIEW}
    />
  );
};

export default withErrorBoundary(View, {
  FallbackComponent,
});
