import { useEffect, useRef } from "react";
import Icon, { WifiOutlined } from "@ant-design/icons";
import { ReactComponent as NoWifi } from "@/assets/icons/NoWifi.svg";
import Offline from "@/components/Offline";
import Online from "@/components/Online";
import useNetwork from "@/stores/network";
import useBoolean from "@/hooks/useBoolean";
import NetworkMessage from "@/components/NetworkMessage";

const NetworkStatus = () => {
  const { online } = useNetwork();
  const {
    value: reconnected,
    setFalse: hideReconnected,
    setTrue: showReconnected,
  } = useBoolean(false);

  const mounted = useRef(false);

  useEffect(() => {
    if (online && mounted.current) {
      showReconnected();
      // Automatically hide the message after 5 seconds
      setTimeout(() => {
        hideReconnected();
      }, 5000);
    }
    mounted.current = true;
  }, [online, showReconnected, hideReconnected]);

  return (
    <div>
      <Offline>
        <NetworkMessage type="default">
          <Icon component={NoWifi} />
          <span>Currently offline</span>
        </NetworkMessage>
      </Offline>

      <Online>
        {reconnected && (
          <NetworkMessage type="info">
            <WifiOutlined />
            <span>Reconnected</span>
          </NetworkMessage>
        )}
      </Online>
    </div>
  );
};

export default NetworkStatus;
