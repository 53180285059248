import { useCallback, useMemo } from "react";
import { useLogin } from "@/auth/api";
import { LoginType } from "@/auth/types/Login";
import { useUserStore } from "@/auth/stores/user";
import { USER_PENDING } from "@/auth/config/roles";

const useSubmitLoginCredentials = () => {
  const { mutateAsync: login, isLoading, isError, error } = useLogin();
  const { setUser, setOldPassword } = useUserStore();
  const save = useCallback(
    async (values: LoginType) => {
      const { data } = await login(values);
      setUser(data);
      if (data?.Status === USER_PENDING) {
        setOldPassword(values.Password);
      }
      return data;
    },
    [login, setOldPassword, setUser]
  );
  return useMemo(
    () => ({ save, isLoading, isError, error }),
    [save, isLoading, isError, error]
  );
};

export default useSubmitLoginCredentials;
