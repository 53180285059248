/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useNetwork from "@/stores/network";
import { useLiveQuery } from "dexie-react-hooks";
import styled from "@emotion/styled";
import { db } from "@/db/index";
import { usePreferenceStore } from "@/auth/stores/preference";
import { useGetSafetyValveReports } from "@/reports/api";
import { SafetyValveReport } from "@/reports/types/SafetyValveReport";
import usePaginatedTable from "@/hooks/usePaginatedTable";
import useNonPaginatedTable from "@/hooks/useNonPaginatedTable";
import { Location } from "@/reports/config/locations";
import { withErrorBoundary } from "react-error-boundary";
import FallbackComponent from "@/components/FallbackComponent";
import { QUERY_RETRY_DELAY } from "@/constants/time";
import {
  ALL,
  ARCHIVED,
  PUBLISHED,
  SIGNED_OFF,
  Status,
  W_ARCHIVE,
} from "@/reports/config/status";
import { Condition } from "@/types/Api";
import { useNavigate } from "react-router-dom";
import { spacing } from "@/config/theme";
import useRole from "@/auth/hooks/useRole";
import { isTablet } from "@/utils/deviceCheck";
import { ConfigProvider, Table as AntdTable, Button } from "antd";
import { TableRowSelection } from "antd/es/table/interface";
import StyledTableEmptyState from "@/components/TableEmptyState";
import { getEmptyStateBaseOnStatus } from "@/reports/utils/report";
import {
  onlineTableColumns,
  offlineTableColumns,
  getViewerTableColumns,
} from "./columns";
import BulkExportBanner from "./BulkExportBanner";
import BulkChangeStatusBanner from "./BulkChangeStatusBanner";

const Table = styled(AntdTable)`
  .vv-number-column,
  .last-updated-column {
    .ant-table-column-title {
      flex: none;
    }
  }

  .last-updated-column {
    .ant-table-column-sorters {
      justify-content: flex-start;
    }
  }
` as any as typeof AntdTable;

const ClearFiltersButtonWrapper = styled.div`
  position: relative;
  bottom: -${spacing.xxxs};
`;

interface Props {
  location?: Location;
  status: Status;
  refreshReportTable?: () => void;
}
const ReportsTable = ({ location, status, refreshReportTable }: Props) => {
  const navigate = useNavigate();
  const { online } = useNetwork();
  const {
    rememberedFilters,
    rememberSorter,
    setRememberedFilters,
    clearRememberedFilters,
    clearRememberedSorter,
    setRememberSorter,
  } = usePreferenceStore();
  const { isTechnician, isAdmin, isViewer } = useRole();
  const statusCondition = useMemo<Condition[]>(() => {
    if (status === ALL) {
      const excludeStatuses = [ARCHIVED];

      // Exclude W_ARCHIVE status for technicians
      if (isTechnician) {
        excludeStatuses.push(W_ARCHIVE);
      }

      return excludeStatuses.map((item) => ({
        column: "status",
        operator: "NOT_IN",
        value: item,
      }));
    }

    return [
      {
        column: "status",
        operator: "EQUALS",
        value: status,
      },
    ];
  }, [status, isTechnician]);

  const {
    pagination,
    setPagination,
    onChange,
    columns: onlineColumns,
    filters,
    sorter,
    setFilters,
  } = usePaginatedTable<SafetyValveReport>({
    columns: !isViewer
      ? onlineTableColumns(status, isAdmin)
      : getViewerTableColumns(status),
    initialFilters: rememberedFilters,
    initialSorter: rememberSorter,
  });

  useEffect(() => {
    setRememberedFilters(filters);
    setRememberSorter(sorter);
  }, [filters, sorter, setRememberedFilters, setRememberSorter]);

  const removeMultipleStatusFilter = useCallback(
    (currentFilters: Condition[]) => {
      if (status !== ALL) {
        // NOTE: We are filtering out the IN operator here which is used for multiple status selection
        return [...currentFilters].filter((filter) => filter.operator !== "IN");
      }

      return currentFilters;
    },
    [status]
  );

  // Remove multiple status filter when status not equal to ALL
  useEffect(() => {
    setFilters((currentFilters) => {
      return removeMultipleStatusFilter(currentFilters);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const { columns: offlineColumns, hasSearched: hasSearchedOffline } =
    useNonPaginatedTable<SafetyValveReport>({
      columns: offlineTableColumns,
    });

  const {
    isLoading,
    isError,
    error,
    data: onlineData,
  } = useGetSafetyValveReports(
    {
      limit: pagination.pageSize,
      page: pagination.current,
      sorter,
      ...(isViewer
        ? {
            search: [...filters, ...statusCondition],
          }
        : {
            search: [...filters, ...statusCondition],
            location,
          }),
    },
    isViewer,
    {
      networkMode: "online",
      retryDelay: QUERY_RETRY_DELAY,
    }
  );

  const offlineData = useLiveQuery(() =>
    db.reports.reverse().sortBy("updatedAt")
  );

  const handleRowClick = useCallback(
    (record: SafetyValveReport) => {
      if (isAdmin || isTechnician) {
        navigate(`/reports/edit/${location}/${record.SafetyValveReportId}`);
      }
      if (isViewer) {
        navigate(
          `/reports/view/${record.Location}/${record.SafetyValveReportId}`
        );
      }
    },
    [isAdmin, isTechnician, isViewer, navigate, location]
  );

  const onRow = useCallback(
    (record: SafetyValveReport) => {
      return {
        onClick: () => handleRowClick(record),
        style: { cursor: "pointer" },
      };
    },
    [handleRowClick]
  );

  // States for bulk actions
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<SafetyValveReport[]>([]);

  const rowSelectionBaseOnStatus = ():
    | TableRowSelection<SafetyValveReport>
    | undefined => {
    if (!online) return undefined;

    const defaultRowSelection: TableRowSelection<SafetyValveReport> = {
      selectedRowKeys,
      onChange: (updatedSelectedRowKeys: React.Key[], rows) => {
        setSelectedRowKeys(updatedSelectedRowKeys);
        setSelectedRows(rows);
      },
    };

    // Bulk action for exporting reports in Published status
    if (status === PUBLISHED) {
      return { ...defaultRowSelection, hideSelectAll: true };
    }

    // Bulk action for changing status in Signed Off status
    if (status === SIGNED_OFF && isAdmin) {
      return defaultRowSelection;
    }

    return undefined;
  };

  // Clear selected row keys when location or status or online changes
  useEffect(() => {
    // Statuses that allow multiple selection
    const multipleSelectionStatus: Status[] = [PUBLISHED, SIGNED_OFF];

    if (location || multipleSelectionStatus.includes(status) || !online) {
      setSelectedRowKeys([]);
      setSelectedRows([]);
    }
  }, [location, status, online]);

  if (isError) throw error;
  useEffect(() => {
    if (onlineData) {
      setPagination({
        current: onlineData?.page,
        total: onlineData?.totalDocs,
        pageSize: onlineData?.limit,
      });
    }
  }, [onlineData, setPagination]);
  return (
    <ConfigProvider
      // Display different status empty state when there is no data
      renderEmpty={() => {
        const { imageUrl, description } = getEmptyStateBaseOnStatus(
          status,
          isViewer,
          // Filters other than status means user is searching
          online
            ? filters.some((filter) => filter.column !== "status")
            : hasSearchedOffline
        );
        return (
          <StyledTableEmptyState image={imageUrl} description={description} />
        );
      }}
    >
      {selectedRowKeys.length > 0 && status === PUBLISHED && (
        <BulkExportBanner
          selectedRows={selectedRows}
          setSelectedRowKeys={setSelectedRowKeys}
          location={location}
        />
      )}

      {/* Display bulk change status banner when status is Signed Off */}
      {selectedRowKeys.length > 0 && status === SIGNED_OFF && (
        <BulkChangeStatusBanner
          selectedRows={selectedRows}
          setSelectedRowKeys={setSelectedRowKeys}
          location={location}
        />
      )}
      <ClearFiltersButtonWrapper>
        <Button
          onClick={() => {
            setFilters([]);
            clearRememberedFilters();
            clearRememberedSorter();
            // Force re-render table when filters are cleared to reset to default
            refreshReportTable?.();
          }}
        >
          Clear Filters and Sorting
        </Button>
      </ClearFiltersButtonWrapper>

      <Table<SafetyValveReport>
        dataSource={online ? onlineData?.docs : offlineData}
        columns={online ? onlineColumns : offlineColumns}
        loading={online && isLoading}
        onChange={online ? onChange : undefined}
        scroll={isTablet ? { x: 768 } : undefined}
        pagination={
          online
            ? {
                ...pagination,
                showSizeChanger: false,
                hideOnSinglePage: true,
                onChange: () => {
                  // Clear selected row keys when page changes
                  setSelectedRowKeys([]);
                },
              }
            : false
        }
        rowKey={(row) =>
          `${row.vCustomer}-${row.SafetyValveReportId}-${row.OfflineSafetyValveReportId}`
        }
        // Disable row click when user using bulk actions
        onRow={selectedRowKeys.length === 0 ? onRow : undefined}
        rowSelection={rowSelectionBaseOnStatus()}
      />
    </ConfigProvider>
  );
};

export default withErrorBoundary(ReportsTable, {
  FallbackComponent,
});
