import { SafetyValveReport } from "@/reports/types/SafetyValveReport";
import { UV, IV, V, NC, ASMES } from "@/reports/config/asme";
import { DefaultOptionType } from "antd/es/select";

export const getASME = (report: SafetyValveReport) => {
  if (report.rUV) {
    return UV;
  }
  if (report.rIV) {
    return IV;
  }
  if (report.rV) {
    return V;
  }
  if (report.rNV) {
    return NC;
  }
  return undefined;
};

export const populateASME = (report: SafetyValveReport) => {
  return {
    rUV: report.ASME === UV,
    rV: report.ASME === V,
    rIV: report.ASME === IV,
    rNV: report.ASME === NC,
  };
};

export const getAsmeOptions = (): DefaultOptionType[] =>
  ASMES.map((asme) => ({
    label: asme,
    value: asme,
  }));
