import { CSSProperties } from "react";
import { Empty } from "antd";
import styled from "@emotion/styled";

const TableEmptyState = styled(Empty)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
`;

const emptyImageStyle: CSSProperties = {
  height: 160,
  display: "flex",
  justifyContent: "center",
};

interface Props {
  image?: string;
  description?: string;
}

const StyledTableEmptyState = ({ image, description }: Props) => {
  return (
    <TableEmptyState
      image={image}
      description={description}
      imageStyle={emptyImageStyle}
    />
  );
};

export default StyledTableEmptyState;
