import axios from "@/lib/axios";
import { AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LoginType, LoginTypeResponse } from "@/auth/types/Login";
import { SubmitResetPasswordType } from "@/auth/types/ResetPassword";

export const login = async (
  values: LoginType
): Promise<AxiosResponse<LoginTypeResponse>> =>
  axios.post("/api/auth/login", values);

export const useLogin = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: login,
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
};

export const resetPassword = async (
  values: SubmitResetPasswordType
): Promise<AxiosResponse<void>> =>
  axios.post("/api/auth/resetPassword", values);

export const useResetPassword = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: resetPassword,
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
};
