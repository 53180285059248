import styled from "@emotion/styled";
import { Button, Select } from "antd";
import { colors, font } from "@/config/theme";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReportsTable from "@/reports/components/ReportsTable";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { withErrorBoundary } from "react-error-boundary";
import FallbackComponent from "@/components/FallbackComponent";
import useRole from "@/auth/hooks/useRole";
import { NANAIMO, PRINCE_GEORGE } from "@/reports/config/locations";
import { PUBLISHED, WAREHOUSE, Status } from "@/reports/config/status";
import PageHeader from "@/components/PageHeader";
import PageTitle from "@/components/PageTitle";
import Page from "@/components/Page";
import PageContent from "@/components/PageContent";
import { TabItems } from "@/types/Antd";
import PageTabs from "@/components/PageTabs";
import { displayLocationName } from "@/utils/display";
import { useUserStore } from "@/auth/stores/user";
import useNetwork from "@/stores/network";
import { getReportStatusOptionsByRole } from "@/reports/utils/report";
import { usePreferenceStore } from "@/auth/stores/preference";
import useBoolean from "@/hooks/useBoolean";

const AssignedLocation = styled.span`
  font-size: ${font.size[16]};
  font-weight: 600;
  color: ${colors.gray[800]};
`;

const Index = () => {
  const { isAdmin, isTechnician, isViewer, role } = useRole();
  const { online } = useNetwork();
  const { user } = useUserStore();
  const { rememberedStatus, setRememberedStatus } = usePreferenceStore();
  const [status, setStatus] = useState<Status>(rememberedStatus);
  const routeLocation = useLocation();
  const navigate = useNavigate();

  const { value: hideReport, setValue: setHideReport } = useBoolean(false);

  // Redirect to the new report page if the report is duplicated
  useEffect(() => {
    if (routeLocation.state?.newReportId && routeLocation.state?.location) {
      navigate(
        `/reports/edit/${routeLocation.state.location}/${routeLocation.state.newReportId}`,
        {
          replace: true,
          state: {
            fromDuplicate: true,
          },
        }
      );
    }
  }, [routeLocation, navigate]);

  /**
   * Force the report table to re-render
   * This approach is used to avoid the issue of the table not re-rendering UI correctly when the filters/sorter are reset
   */
  const refreshReportTable = useCallback(async () => {
    setHideReport(true);
    // Notes: The purpose of this timeout is force the component to re-render back to its initial state after filters reset
    const timer = setTimeout(() => {
      setHideReport(false);
      clearTimeout(timer);
    }, 1);
  }, [setHideReport]);

  const adminTabItems = useMemo<TabItems>(
    () => [
      {
        key: PRINCE_GEORGE,
        label: displayLocationName(PRINCE_GEORGE),
        children: (
          <ReportsTable
            status={status}
            location={PRINCE_GEORGE}
            refreshReportTable={refreshReportTable}
          />
        ),
      },
      {
        key: NANAIMO,
        label: displayLocationName(NANAIMO),
        children: (
          <ReportsTable
            status={status}
            location={NANAIMO}
            refreshReportTable={refreshReportTable}
          />
        ),
      },
    ],
    [status, refreshReportTable]
  );

  const viewerTabsItems = [
    {
      label: "Reports",
      key: "Reports",
      children: (
        <ReportsTable
          status={PUBLISHED}
          refreshReportTable={refreshReportTable}
        />
      ),
    },
    {
      label: "Warehouse",
      key: "Warehouse",
      children: (
        <ReportsTable
          status={WAREHOUSE}
          refreshReportTable={refreshReportTable}
        />
      ),
    },
  ];

  const displayTabItems = isAdmin ? adminTabItems : viewerTabsItems;

  const [tab, setTab] = useState(
    isViewer ? "Reports" : user?.Location || adminTabItems[0].key
  );
  const activeTab = isViewer
    ? viewerTabsItems.find((item) => item.key === tab)
    : adminTabItems.find((item) => item.key === tab);

  return (
    <Page>
      <PageHeader withTabs={(isAdmin && online) || isViewer}>
        <PageTitle
          title={
            <>
              <span>Safety Valve Data Report</span>
              {isTechnician && (
                <AssignedLocation>{activeTab?.label}</AssignedLocation>
              )}
            </>
          }
        >
          {(isAdmin || isTechnician) && (
            <Link
              to="/reports/new"
              state={{
                location: activeTab?.key,
              }}
            >
              <Button type="primary">Create New Report</Button>
            </Link>
          )}
        </PageTitle>

        {online && !isTechnician && (
          <PageTabs
            defaultActiveKey={tab}
            items={displayTabItems.map((item) => ({
              ...item,
              children: undefined,
            }))}
            onChange={(activeKey) => {
              setTab(activeKey);

              // NOTES: If viewer switch tab, refresh the report table to clear multiple selection. Admin role has different logic to show tabs, so the handler is different
              if (isViewer) {
                refreshReportTable();
              }
            }}
          />
        )}
      </PageHeader>
      <PageContent>
        {(isAdmin || isTechnician) && online && (
          <Select
            defaultValue={rememberedStatus}
            style={{ width: 120 }}
            onChange={(value) => {
              setStatus(value);
              setRememberedStatus(value);

              refreshReportTable();
            }}
            options={getReportStatusOptionsByRole(role)}
          />
        )}
        {!hideReport && activeTab?.children}
      </PageContent>
    </Page>
  );
};

export default withErrorBoundary(Index, { FallbackComponent });
