import React, { useCallback, useMemo } from "react";
import { ReportId, SafetyValveReport } from "@/reports/types/SafetyValveReport";
import useBoolean from "@/hooks/useBoolean";
import { message } from "antd";
import { db } from "@/db/index";
import dayjs from "dayjs";

interface SaveReportOfflineOptions {
  id: ReportId;
  setId?: React.Dispatch<React.SetStateAction<ReportId>>;
}

const useSaveReportOffline = ({ id, setId }: SaveReportOfflineOptions) => {
  const { value: isSaved, setValue: setSaved } = useBoolean(true);
  const save = useCallback(
    async (values: SafetyValveReport) => {
      let result;
      const valuesToPersist = {
        ...values,
        rDate: values.rDate ? dayjs(values.rDate).toISOString() : undefined,
        tDateTested: values.tDateTested
          ? dayjs(values.tDateTested).toISOString()
          : undefined,
        updatedAt: dayjs().toISOString(),
        notSynced: 1,
      };
      if (id.onServer || typeof id.value === "number") {
        const existingReport = await db.reports
          .where("SafetyValveReportId")
          .equals(id.value)
          .first();
        result = await db.reports.put({
          ...valuesToPersist,
          SafetyValveReportId: Number(id.value),
          OfflineSafetyValveReportId: existingReport?.newOfflineReport
            ? existingReport.OfflineSafetyValveReportId
            : undefined,
          newOfflineReport: existingReport?.newOfflineReport || 0,
        });
      } else {
        result = await db.reports.add({
          ...valuesToPersist,
          OfflineSafetyValveReportId: String(id.value),
          newOfflineReport: 1,
        });
      }
      setId?.({ value: Number(result), onServer: false });
      setSaved(true);
      message.open({
        type: "success",
        content: "Report saved",
      });
      return result;
    },
    [id, setId, setSaved]
  );
  return useMemo(
    () => ({ isSaved, setSaved, save }),
    [isSaved, setSaved, save]
  );
};

export default useSaveReportOffline;
