import { DefaultOptionType } from "antd/es/select";
import { LookupRecord, UpdatedLookupRecord } from "@/lookups/types/Lookup";

export const transformRecordToOption = ({
  Lookup,
  LookupId,
}: LookupRecord): DefaultOptionType => {
  return {
    label: Lookup,
    /* Ideally the LookupId property should be used here as the value,
     * but because the database doesn't have any foreign keys,
     * we would need to perform another search when sending values to API
     * so that we send the Lookup instead of the LookupIdid. */
    value: Lookup,
    key: LookupId,
  };
};

export const transformRecordsToOptions = (
  records?: LookupRecord[]
): DefaultOptionType[] => (records || []).map(transformRecordToOption);

/**
 * Utility function to assemble the updated lookups array from edited and removed lookups.
 * @param editedLookups Array of edited names of lookups.
 * @param removedLookups Array of removed lookups.
 */
export const assembleUpdatedLookups = (
  editedLookups: LookupRecord[],
  removedLookups: LookupRecord[]
) => {
  const updatedLookups: UpdatedLookupRecord[] = [];

  editedLookups.forEach((lookup) => {
    updatedLookups.push({
      LookupId: lookup.LookupId,
      FieldName: lookup.FieldName,
      Lookup: lookup.Lookup,
      Show: true,
    });
  });

  removedLookups.forEach((lookup) => {
    updatedLookups.push({
      LookupId: lookup.LookupId,
      Show: false,
    });
  });

  return updatedLookups;
};

/**
 * Utility function to sort LookupRecord based on Lookup property that contains number and text.
 * @param a LookupRecord
 * @param b LookupRecord
 * @returns order of LookupRecord based on Lookup property
 */
const naturalSortWithNumberText = (a: LookupRecord, b: LookupRecord) => {
  const regex = /(\d+)|(\D+)/g;

  const segmentsA = a.Lookup.match(regex);
  const segmentsB = b.Lookup.match(regex);

  if (!segmentsA || !segmentsB) return 0;

  for (let i = 0; i < Math.max(segmentsA.length, segmentsB.length); i += 1) {
    const segmentA = segmentsA[i] || "";
    const segmentB = segmentsB[i] || "";

    if (Number.isNaN(segmentA) || Number.isNaN(segmentB)) {
      if (segmentA < segmentB) return -1;
      if (segmentA > segmentB) return 1;
    } else {
      const numA = parseInt(segmentA, 10);
      const numB = parseInt(segmentB, 10);

      if (numA < numB) return -1;
      if (numA > numB) return 1;
    }
  }

  return 0;
};

/**
 * Utility function to sort LookupRecord alphabetically.
 * @param lookupRecords LookupRecord[]
 * @returns sorted LookupRecord[]
 */
export const sortLookupRecordsAlphabetically = (
  lookupRecords: LookupRecord[]
): LookupRecord[] => {
  return lookupRecords
    .sort((a, b) => a.Lookup.localeCompare(b.Lookup))
    .sort((a, b) => naturalSortWithNumberText(a, b));
};
