import { useState } from "react";
import { MAX_FILE_SIZE_IN_MB } from "@/config/attachments";
import { colors, font, spacing } from "@/config/theme";
import {
  Attachment,
  SafetyValveReport,
} from "@/reports/types/SafetyValveReport";
import useNetwork from "@/stores/network";
import { PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import {
  Alert,
  Button,
  Modal,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from "antd";
import { RcFile } from "antd/es/upload";
import { useFormContext } from "react-hook-form";
import AttachmentItem from "./AttachmentItem";

const Hint = styled.p`
  color: ${colors.gray[800]};
  font-size: ${font.size[14]};
  font-weight: 400;
  line-height: 157.143%;
`;

const Wrapper = styled.div`
  display: flex;
  gap: ${spacing.lg};
  flex-direction: column;
`;

const UploadBtnText = styled.div`
  margin-top: ${spacing.xxs};
`;

const uploadButton = (
  <div>
    <PlusOutlined />
    <UploadBtnText>Upload</UploadBtnText>
  </div>
);

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const assembleAttachmentList = (
  attachments?: Attachment[] | undefined
): UploadFile[] => {
  if (!attachments) return [];
  return attachments.map((attachment) => ({
    AttachmentId: attachment.AttachmentId,
    uid: `${attachment.AttachmentId}#${attachment.SafetyValveReportId}#${attachment.FileName}`,
    name: attachment.FileName,
    status: "done",
    url: attachment.Link,
  }));
};

type AttachmentsProps = {
  disabled: boolean;
  attachments: Attachment[] | undefined;
  existingDeletedAttachments: UploadFile[];
  existingNewAddedAttachments: UploadFile[];
};

/** The attachments tab for the report form */
const Attachments = ({
  disabled,
  attachments,
  existingDeletedAttachments,
  existingNewAddedAttachments,
}: AttachmentsProps) => {
  const { online } = useNetwork();
  const { setValue } = useFormContext<SafetyValveReport>();
  const [previewTitle, setPreviewTitle] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<Attachment[] | UploadFile[]>([
    ...assembleAttachmentList(attachments),
    ...(existingNewAddedAttachments || []),
  ]);
  const handleCancel = () => setPreviewOpen(false);
  const handleChange: UploadProps["onChange"] = ({
    fileList: newFileList,
    file: changedFile,
  }) => {
    // This is a new file uploaded by the user if it has a size but no status
    if (changedFile.size && !changedFile.status) {
      // Check file size
      if (changedFile.size > MAX_FILE_SIZE_IN_MB * 1024 * 1024) {
        message.error(`File size cannot exceed ${MAX_FILE_SIZE_IN_MB}MB`);
        return;
      }
      setFileList(newFileList);

      // Add the file to the NewAddedAttachments list
      const targetUploadFile = newFileList.find(
        (file) => file.uid === changedFile.uid
      );
      setValue(
        "NewAddedAttachments",
        [...existingNewAddedAttachments, targetUploadFile],
        { shouldValidate: false, shouldDirty: true }
      );

      return;
    }

    // If the file is from the server, update the fileList state directly
    // Notes: For DeleteAttachments, it is handled in onRemove
    setFileList(newFileList);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewTitle(file.name);
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  return (
    <Wrapper>
      {online ? (
        !disabled && (
          <Hint>
            Upload up to 5 files. Supports JPG, PNG and PDF only. Max file size{" "}
            {MAX_FILE_SIZE_IN_MB}MB
          </Hint>
        )
      ) : (
        <Alert
          message="The attachments for this report cannot be previewed while offline"
          type="warning"
          showIcon
          closable
          closeIcon={<Button>Dismiss</Button>}
        />
      )}

      <Upload
        disabled={disabled}
        listType="picture-card"
        fileList={fileList as UploadFile[]}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={(file) => {
          // Only add to deleted list if the file is from the server
          if (file.originFileObj === undefined) {
            setValue(
              "DeletedAttachments",
              [...existingDeletedAttachments, file],
              { shouldValidate: false, shouldDirty: true }
            );
          }
          // if file hasn't been uploaded to server, remove from NewAddedAttachments
          if (file.originFileObj !== undefined) {
            setValue(
              "NewAddedAttachments",
              existingNewAddedAttachments.filter(
                (f) => f.uid !== file.uid
              ) as UploadFile[],
              { shouldValidate: false, shouldDirty: true }
            );
          }
          return true;
        }}
        beforeUpload={() => false}
        accept=".jpg,.jpeg,.png,.pdf"
        // eslint-disable-next-line react/no-unstable-nested-components
        itemRender={(_1, file, _2, actions) => (
          <AttachmentItem file={file} actions={actions} disabled={disabled} />
        )}
        onDownload={(file) => {
          const downloadLink = (file.url || file.thumbUrl) ?? "";
          const link = document.createElement("a");

          // Set the href attribute to the download link
          link.href = downloadLink;
          link.target = "_blank";

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }}
      >
        {fileList.length >= 5 || disabled ? null : uploadButton}
      </Upload>
      <Modal
        title={previewTitle}
        open={previewOpen}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose
      >
        <img alt="previewImage" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </Wrapper>
  );
};

export default Attachments;
