import { IN_PROGRESS, Status } from "@/reports/config/status";
import { SafetyValveReport } from "@/reports/types/SafetyValveReport";
import { Condition } from "@/types/Api";
import { SorterResult } from "antd/es/table/interface";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type PreferenceStore = {
  rememberedStatus: Status;
  rememberedFilters: Condition[];
  rememberSorter: SorterResult<SafetyValveReport> | undefined;
  setRememberedStatus: (newStatus: Status) => void;
  setRememberedFilters: (newFilters: Condition[]) => void;
  setRememberSorter: (
    newSorter: SorterResult<SafetyValveReport> | undefined
  ) => void;
  clearRememberedFilters: () => void;
  clearRememberedSorter: () => void;
  clearAllPreferences: () => void;
};

export const usePreferenceStore = create<PreferenceStore>()(
  persist(
    (set) => ({
      rememberedStatus: IN_PROGRESS,
      rememberedFilters: [],
      rememberSorter: undefined,

      setRememberedStatus: (newStatus: Status) =>
        set((state) => ({ ...state, rememberedStatus: newStatus })),
      setRememberedFilters: (newFilters: Condition[]) =>
        set((state) => ({ ...state, rememberedFilters: newFilters })),
      setRememberSorter: (
        newSorter: SorterResult<SafetyValveReport> | undefined
      ) => set((state) => ({ ...state, rememberSorter: newSorter })),
      clearRememberedSorter: () =>
        set((state) => ({ ...state, rememberSorter: undefined })),
      clearRememberedFilters: () =>
        set((state) => ({ ...state, rememberedFilters: [] })),
      clearAllPreferences: () =>
        set((state) => ({
          ...state,
          rememberedFilters: [],
          rememberSorter: undefined,
          rememberedStatus: IN_PROGRESS,
        })),
    }),

    {
      name: "preference-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
