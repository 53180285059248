import { CSSProperties, useCallback, useState } from "react";
import Page from "@/components/Page";
import PageContent from "@/components/PageContent";
import PageHeader from "@/components/PageHeader";
import PageTitle from "@/components/PageTitle";
import { USER_GROUP_OPTIONS } from "@/user-control/config/userGroup";
import { Select, message } from "antd";
import UsersTable from "@/user-control/components/UsersTable";
import CreateAccountButton from "@/user-control/components/CreateAccountButton";
import UserControlDrawers from "@/user-control/components/UserControlDrawers";
import { Role, TECHNICIAN } from "@/auth/config/roles";
import { DrawerType } from "@/user-control/types/Drawer";
import useBoolean from "@/hooks/useBoolean";
import { UserInfo } from "@/user-control/types/UserInfo";
import { useUserStore } from "@/auth/stores/user";

const selectStyle: CSSProperties = {
  width: 120,
};

const Index = () => {
  const { user } = useUserStore();

  const [selectedUserGroup, setSelectedUserGroup] = useState<Role>(TECHNICIAN);
  const [selectedUser, setSelectedUser] = useState<UserInfo | null>(null);
  const {
    value: drawerOpened,
    setFalse: hideDrawer,
    setTrue: showDrawer,
  } = useBoolean(false);
  const [drawerType, setDrawerType] = useState<DrawerType | undefined>(
    undefined
  );
  const [drawerRole, setDrawerRole] = useState<Role>(TECHNICIAN);

  const onCreateAccountClick = useCallback(
    (role: Role) => {
      setDrawerType(DrawerType.CREATE);
      setDrawerRole(role);
      showDrawer();
    },
    [showDrawer]
  );

  const onTableRowClick = (record: UserInfo) => {
    // Only allow admins to edit other users not themselves
    if (user?.UserId !== record.UserId) {
      setDrawerType(DrawerType.EDIT);
      setDrawerRole(selectedUserGroup);
      setSelectedUser(record);
      showDrawer();
    } else {
      message.error("Please go to My Profile to edit your own account.");
    }
  };

  // Clear selected user and hide drawer
  const closeDrawer = () => {
    setSelectedUser(null);
    hideDrawer();
  };

  return (
    <Page>
      <PageHeader withTabs={false}>
        <PageTitle title="User Management">
          <CreateAccountButton onCreateAccountClick={onCreateAccountClick} />
        </PageTitle>
      </PageHeader>
      <PageContent>
        <Select
          value={selectedUserGroup}
          onChange={setSelectedUserGroup}
          style={selectStyle}
          options={USER_GROUP_OPTIONS}
        />
        <UsersTable
          userGroup={selectedUserGroup}
          onRowClick={onTableRowClick}
        />
        <UserControlDrawers
          visible={drawerOpened}
          drawerType={drawerType}
          userRole={drawerRole}
          onClose={closeDrawer}
          selectedUser={selectedUser}
        />
      </PageContent>
    </Page>
  );
};

export default Index;
