import { CSSProperties, useCallback } from "react";
import { Button, Popover, Flex } from "antd";
import { spacing } from "@/config/theme";
import { VIEWER, Role, TECHNICIAN } from "@/auth/config/roles";
import { displayRoleName } from "@/utils/display";
import styled from "@emotion/styled";
import useBoolean from "@/hooks/useBoolean";

const StyledRoleButton = styled(Button)`
  width: 100%;
  text-align: left;
`;

const popoverOverlayInnerStyle: CSSProperties = {
  borderRadius: 0,
  width: 170,
  padding: spacing.xxs,
};
interface RoleButtonProps {
  userRole: Role;
  onClick: () => void;
}

const RoleButton = ({ userRole, onClick }: RoleButtonProps) => {
  return (
    <StyledRoleButton onClick={onClick} type="text">
      {displayRoleName(userRole)}
    </StyledRoleButton>
  );
};

interface CreateAccountButtonProps {
  onCreateAccountClick: (role: Role) => void;
}

const CreateAccountButton = ({
  onCreateAccountClick,
}: CreateAccountButtonProps) => {
  const { value: opened, setValue, setFalse } = useBoolean(false);

  const handleOpenChange = (newOpen: boolean) => {
    setValue(newOpen);
  };

  const handleCreateAccountClick = useCallback(
    (role: Role) => {
      setFalse();
      onCreateAccountClick(role);
    },
    [setFalse, onCreateAccountClick]
  );

  return (
    <Popover
      open={opened}
      placement="bottom"
      trigger="click"
      arrow={false}
      onOpenChange={handleOpenChange}
      overlayInnerStyle={popoverOverlayInnerStyle}
      content={
        <Flex vertical align="flex-start">
          <RoleButton
            onClick={() => handleCreateAccountClick(TECHNICIAN)}
            userRole={TECHNICIAN}
          />
          <RoleButton
            onClick={() => handleCreateAccountClick(VIEWER)}
            userRole={VIEWER}
          />
        </Flex>
      }
    >
      <Button type="primary">Create new Account</Button>
    </Popover>
  );
};

export default CreateAccountButton;
