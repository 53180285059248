import styled from "@emotion/styled";
import { colors, font, queries, spacing } from "@/config/theme";
import { css, SerializedStyles } from "@emotion/react";
import React from "react";
import { FormElement } from "@/types/Form";

export const checkboxContainerStyles = css`
  span:not(.ant-checkbox) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  grid-column: auto / span 2;
  @media (${queries.tabletAndUp}) {
    grid-column: auto / span 2;
  }
  @media (${queries.laptopAndUp}) {
    grid-column: auto / span 1;
  }
`;
export const fullGridStyles = css`
  grid-column: 1 / -1;
`;

type FieldWrapperProps = {
  columnsLaptop?: number;
};

export const FieldWrapper = styled.div<FieldWrapperProps>`
  display: grid;
  grid-column-gap: ${spacing.xxs};
  grid-row-gap: ${spacing.sm};
  align-items: end;
  grid-template-columns: repeat(2, 1fr);
  @media (${queries.tabletAndUp}) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (${queries.laptopAndUp}) {
    grid-template-columns: repeat(${(p) => p.columnsLaptop || 8}, 1fr);
  }
`;

export const FieldContainer = styled.div<{ css: SerializedStyles }>`
  grid-column: auto / span 2;
  ${(props) => props.css}
`;

export const Subtitle = styled.h2<{ marginTop?: string }>`
  color: ${colors.gray[800]};
  font-size: ${font.size[20]};
  font-weight: 600;
  line-height: 140%;
  margin-top: ${(p) => p.marginTop || spacing.lg};
  text-transform: capitalize;
`;

type FormSubtitleProps = {
  children: React.ReactNode;
  marginTop?: string;
  key?: React.Key;
};

export const FormSubtitle = ({
  children,
  marginTop,
  key,
}: FormSubtitleProps) => (
  <Subtitle key={key} marginTop={marginTop}>
    {children}
  </Subtitle>
);

type FieldRendererProps = {
  fields: FormElement[];
  disabled?: boolean;
};
const FieldRenderer = ({ fields, disabled }: FieldRendererProps) => (
  <>
    {fields.map(
      ({
        Component,
        name,
        key,
        elementContainerCss,
        placeholder,
        label,
        ...props
      }) => {
        return (
          <FieldContainer css={elementContainerCss} key={key || name}>
            <Component
              name={name}
              disabled={disabled}
              {...props}
              placeholder={disabled ? "" : placeholder || label}
              label={label}
            />
          </FieldContainer>
        );
      }
    )}
  </>
);
export default FieldRenderer;
