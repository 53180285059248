import { useMemo } from "react";
import { createFormElement, FormElement } from "@/types/Form";
import AutocompleteField from "@/components/AutocompleteField";
import useLookupStore from "@/lookups/stores/lookups";
import { transformRecordsToOptions } from "@/lookups/utils/transform";
import FieldRenderer, {
  FieldWrapper,
  FormSubtitle,
  fullGridStyles,
} from "./utils";

interface FormSection {
  title: string;
  fieldName: string;
}

type Props = {
  disabled?: boolean;
};

const useFields = () => {
  const { lookups } = useLookupStore();

  return useMemo<FormElement[]>(() => {
    const sections: FormSection[] = [
      {
        title: "Cap / Lever",
        fieldName: "CapLever",
      },
      {
        title: "Spring Adj Screw",
        fieldName: "SpringAdjScrew",
      },
      {
        title: "Spring",
        fieldName: "Spring",
      },
      {
        title: "Buttons",
        fieldName: "Buttons",
      },
      {
        title: "Spindle",
        fieldName: "Spindle",
      },
      {
        title: "Spindle Retainer",
        fieldName: "SpindleRetainer",
      },
      {
        title: "Guide",
        fieldName: "Guide",
      },
      {
        title: "Disc Holder",
        fieldName: "DiscHolder",
      },
      {
        title: "Disc Retainer",
        fieldName: "DiscRetainer",
      },
      {
        title: "Disc",
        fieldName: "Disc",
      },
      {
        title: "Nozzle",
        fieldName: "Nozzle",
      },
      {
        title: "BD Ring Upper",
        fieldName: "BDRingUpper",
      },
      {
        title: "BD Ring Lower",
        fieldName: "BDRingLower",
      },
      {
        title: "Gaskets",
        fieldName: "Gaskets",
      },
      {
        title: "Bellows",
        fieldName: "Bellows",
      },
      {
        title: "Piston",
        fieldName: "Piston",
      },
      {
        title: "Sleeve",
        fieldName: "Sleeve",
      },
      {
        title: "Flanges",
        fieldName: "Flanges",
      },
      {
        title: "Body / Bonnet",
        fieldName: "Bonnet",
      },
    ];
    const generateFields = (): FormElement[] => {
      return sections
        .map((section, index) => {
          return [
            createFormElement(FormSubtitle, {
              elementContainerCss: fullGridStyles,
              children: section.title,
              key: section.title,
              ...(index === 0 && {
                marginTop: "0",
              }),
            }),
            createFormElement(AutocompleteField, {
              label: "Condition",
              name: `${section.fieldName}C`,
              options: transformRecordsToOptions(lookups.Condition),
              showSearch: true,
            }),
            createFormElement(AutocompleteField, {
              label: "Action Taken",
              name: `${section.fieldName}A`,
              options: transformRecordsToOptions(lookups.Action),
              showSearch: true,
            }),
            createFormElement(AutocompleteField, {
              label: "Recommendation",
              name: `${section.fieldName}R`,
              options: transformRecordsToOptions(lookups.Recommendation),
              showSearch: true,
            }),
          ];
        })
        .flat();
    };
    const fields: FormElement[] = generateFields();
    return fields;
  }, [lookups]);
};

const Parts = ({ disabled }: Props) => {
  const fields = useFields();
  return (
    <FieldWrapper columnsLaptop={6}>
      <FieldRenderer fields={fields} disabled={disabled} />
    </FieldWrapper>
  );
};

export default Parts;
