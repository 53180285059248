import { useEffect } from "react";
import { useGetSites } from "@/sites/api";
import useSiteStore from "@/sites/stores/sites";

const useDownloadSites = () => {
  const { data: allSites } = useGetSites();
  const { setAll } = useSiteStore();

  useEffect(() => {
    if (allSites) {
      setAll(allSites);
    }
  }, [allSites, setAll]);
};
export default useDownloadSites;
