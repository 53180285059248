import { z } from "zod";

export const CreateSiteSchema = z.object({
  SiteName: z
    .string()
    .min(1)
    .refine((value) => !/[^a-zA-Z0-9\-.& ]/g.test(value)),
});

export type CreateSiteType = z.infer<typeof CreateSiteSchema>;

export const UpdateSiteSchema = z.object({
  SiteName: z.string().min(1),
  Show: z.boolean(),
});

export type UpdateSiteType = {
  SiteId: number;
} & z.infer<typeof UpdateSiteSchema>;

export type User = {
  UserId: number;
  Name: string;
  Status: string;
};

export type Site = {
  SiteId: number;
  SiteName: string;
  Show: boolean;
  Users: User[];
};
