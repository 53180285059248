import { z } from "zod";

export const ChangePasswordSchema = z.object({
  CurrentPassword: z
    .string()
    .min(8)
    .refine((value) => /[a-z]/g.test(value))
    .refine((value) => /[A-Z]/g.test(value))
    .refine((value) => /[0-9]/g.test(value)),
  NewPassword: z
    .string()
    .min(8)
    .refine((value) => /[a-z]/g.test(value))
    .refine((value) => /[A-Z]/g.test(value))
    .refine((value) => /[0-9]/g.test(value)),
});
export type ChangePasswordType = z.infer<typeof ChangePasswordSchema>;
