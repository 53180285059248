import { useCallback, useMemo } from "react";
import { EditAccountType } from "@/user-control/types/Account";
import { useEditAccount } from "@/user-control/api";

const useSubmitEditAccountData = () => {
  const {
    mutateAsync: editAccount,
    isLoading,
    isError,
    isSuccess,
    error,
  } = useEditAccount();

  const save = useCallback(
    async (values: EditAccountType) => {
      try {
        return await editAccount(values);
      } catch (apiError) {
        return apiError;
      }
    },
    [editAccount]
  );

  return useMemo(
    () => ({ save, isLoading, isError, isSuccess, error }),
    [save, isLoading, isError, isSuccess, error]
  );
};

export default useSubmitEditAccountData;
