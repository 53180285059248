import logo from "@/assets/images/vanvalve.svg";
import { colors, spacing } from "@/config/theme";
import { Button, Layout as AntdLayout, Menu as AntdMenu, Spin } from "antd";
import styled from "@emotion/styled";
import { useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useNavigationItems from "@/hooks/useNavigationItems";
import { MenuOutlined } from "@ant-design/icons";
import { useUserStore } from "@/auth/stores/user";
import useRole from "@/auth/hooks/useRole";
import NetworkStatus from "@/components/NetworkStatus";
import { withErrorBoundary } from "react-error-boundary";
import FallbackComponent from "@/components/FallbackComponent";
import SetupReports from "@/reports/components/SetupReports";
import { isTablet } from "@/utils/deviceCheck";
import { USER_ACTIVE } from "@/auth/config/roles";
import useRouteProtector from "@/hooks/useRouteProtector";
import useBoolean from "@/hooks/useBoolean";

const { Content: AntdContent, Sider: AntdSider } = AntdLayout;

const LayoutImpl = styled(AntdLayout)`
  min-height: 100%;
  max-height: 100%;
`;

const Sider = styled(AntdSider)`
  background-color: ${colors.daybreakBlue[100]} !important;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }
`;

const Header = styled.div`
  padding: 0 ${spacing.lg};
  background-color: ${colors.white};
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled(AntdContent)`
  --main-padding: ${spacing.lg};
  padding: var(--main-padding);
  overflow: auto;
  max-height: 100%;
`;

const Logo = styled.img`
  max-width: 100%;
  margin: ${spacing.xl} auto ${spacing.sm};
`;

const OpacityLayer = styled.div`
  opacity: 0.65;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

const Menu = styled(AntdMenu)`
  background-color: transparent;
  padding-left: 0 !important;
  padding-inline: ${spacing.xs} !important;
  padding-block: ${spacing.xxs} !important;

  .ant-menu-item-selected {
    background-color: transparent;
  }
  .ant-menu-title-content {
    color: ${colors.white};
  }
  .ant-menu-item {
    &:first-of-type {
      margin-top: 0;
    }
    .active {
      font-weight: 700;
    }

    padding-inline: ${spacing.xxs} !important;
  }

  .anticon {
    color: ${colors.white};
    path {
      fill: ${colors.white} !important;
    }
  }
`;

const MenuButton = styled(Button)`
  padding: 0;
`;

const Layout = () => {
  const navItems = useNavigationItems();
  const { user } = useUserStore();
  const { isAdmin, isTechnician } = useRole();
  const { value: isLoading, setValue: setIsLoading } = useBoolean(false);

  // Collapse sider on tablet by default
  const [collapsed, setCollapsed] = useState<boolean>(isTablet);

  const navigate = useNavigate();
  useEffect(() => {
    if (user?.Status !== USER_ACTIVE) {
      navigate("/login");
    }
  }, [user, navigate]);

  useRouteProtector();

  const onMenuSelect = useCallback(
    ({ key }: { key: string }) => {
      const item = navItems.find((navItem) => navItem.key === key);
      if (item) {
        navigate(item.to);
        // Collapse sider on tablet after menu item is selected
        if (isTablet) setCollapsed(true);
      }
    },
    [navigate, navItems]
  );
  return (
    <LayoutImpl>
      <Sider
        collapsed={collapsed}
        collapsible
        trigger={null}
        collapsedWidth={0}
        width={245}
      >
        <Logo src={logo} alt="Vanvalve logo" />
        <OpacityLayer>
          <Menu
            mode="inline"
            items={navItems.filter((item) => item.position === "top")}
            onClick={onMenuSelect}
          />
          <Menu
            mode="inline"
            items={navItems.filter((item) => item.position === "bottom")}
            onClick={onMenuSelect}
          />
        </OpacityLayer>
      </Sider>
      <LayoutImpl>
        <Header>
          <MenuButton
            size="large"
            type="text"
            onClick={() => setCollapsed((value) => !value)}
            icon={<MenuOutlined />}
          />
          {(isAdmin || isTechnician) && <NetworkStatus />}
        </Header>
        <Content>
          <Outlet />
        </Content>
      </LayoutImpl>
      {(isAdmin || isTechnician) && (
        <SetupReports setIsLoading={setIsLoading} />
      )}
      <Spin fullscreen spinning={isLoading} />
    </LayoutImpl>
  );
};

export default withErrorBoundary(Layout, {
  FallbackComponent,
});
