import {
  Input as AntdInput,
  InputNumber as AntdInputNumber,
  InputNumberProps,
} from "antd";
import type { InputProps as AntdInputProps } from "antd";
import React, { useState } from "react";
import { useController } from "react-hook-form";
import { SerializedStyles } from "@emotion/react";
import FieldWrapper from "@/components/FieldWrapper";
import { TextAreaProps } from "antd/es/input";
import styled from "@emotion/styled";
import { ErrorMessage } from "@hookform/error-message";
import FieldError from "@/components/FieldError";
import RequiredIndicator from "@/components/RequiredIndicator";

const InputNumber = styled(AntdInputNumber)`
  width: 100%;
`;

const TextArea = styled(AntdInput.TextArea)`
  width: 100%;
`;

// because this is an intersection, only props that are in all types will be available.
type InputProps = AntdInputProps & InputNumberProps & TextAreaProps;

type InputType = AntdInputProps["type"] | "textarea";

export type TextFieldProps = {
  name: string;
  label: React.ReactNode;
  css?: SerializedStyles;
  type?: InputType;
  transformInput?: (value: string, previousValue?: string) => string;
} & InputProps;

const getInputComponent = (type: InputType) => {
  if (type === "textarea") return { Component: TextArea };
  if (type === "number") return { Component: InputNumber };
  return { Component: AntdInput, type };
};

const TextField = ({
  name,
  label,
  css: styles,
  type,
  transformInput,
  ...props
}: TextFieldProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
  });

  const [value, setValue] = useState(field.value);
  const { Component, type: htmlType } = getInputComponent(type);

  return (
    <FieldWrapper css={styles}>
      <label htmlFor={name}>
        <RequiredIndicator show={props.required} />
        {label}
      </label>
      <Component
        id={name}
        placeholder={typeof label === "string" ? label : undefined}
        type={htmlType}
        status={error ? "error" : undefined}
        {...props}
        {...field}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(event: any) => {
          let targetValue: string | number = "";

          // When the input field is cleared, the event is null
          if (event === null) {
            field.onChange(event);
            setValue(null);
            return;
          }

          // Number Input Field event is a number
          if (typeof event === "number") {
            targetValue = event;
          }
          // Text Input Field event is object, get the string value from the object
          else if (
            typeof event === "object" &&
            event.target.value !== undefined
          ) {
            targetValue = event.target.value;
          }

          field.onChange(event);
          if (
            typeof transformInput === "function" &&
            typeof targetValue === "string"
          ) {
            const transformedValue = transformInput(targetValue, value);
            field.onChange(transformedValue); // data send back to hook form
            setValue(transformedValue); // UI state
          } else {
            field.onChange(targetValue);
            setValue(targetValue);
          }
        }}
        value={value}
      />
      <ErrorMessage name={name} as={FieldError} />
    </FieldWrapper>
  );
};

export default TextField;
