import { CSSProperties, useCallback } from "react";
import { spacing, colors, font } from "@/config/theme";
import useNetwork from "@/stores/network";
import {
  ExclamationCircleOutlined,
  FileImageOutlined,
  FileOutlined,
  MinusCircleFilled,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Modal, Tooltip, UploadFile } from "antd";

const { confirm } = Modal;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px solid ${colors.gray[500]};
  border-radius: ${spacing.xxxs};
  position: relative;
  margin-right: ${spacing.lg};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 95%;
  height: 95%;
  margin: ${spacing.xxxs};
  object-fit: contain;
  display: block;
  cursor: pointer;
`;

const RemoveButton = styled(MinusCircleFilled)`
  font-size: ${font.size[18]};
  color: red;
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -8px;
`;

const AttachmentMaskWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.gray[450]};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const MaskIconStyle: CSSProperties = {
  fontSize: font.size[32],
  color: colors.gray[800],
};

const FileNameSpan = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  padding-inline: ${spacing.xxs};
`;

type AttachmentMaskProps = {
  fileName: string;
  isImage: boolean;
  isNewUploadedFile: boolean;
  onClick?: () => void;
};

const AttachmentMask = ({
  fileName = "",
  isImage = true,
  isNewUploadedFile,
  onClick,
}: AttachmentMaskProps) => {
  const { online } = useNetwork();

  // If the attachment is not image, allow user to download it when online
  const allowDownload = !isImage && !isNewUploadedFile && online;

  return (
    <Tooltip title={allowDownload ? `Download attachment [${fileName}]` : ""}>
      <AttachmentMaskWrapper
        onClick={allowDownload ? onClick : undefined}
        style={{
          cursor: allowDownload ? "pointer" : "default",
        }}
      >
        {isImage ? (
          <FileImageOutlined style={MaskIconStyle} />
        ) : (
          <FileOutlined style={MaskIconStyle} />
        )}
        <FileNameSpan>{isImage ? "Image" : fileName}</FileNameSpan>
      </AttachmentMaskWrapper>
    </Tooltip>
  );
};

type Props = {
  file: UploadFile;
  actions: { download: () => void; preview: () => void; remove: () => void };
  disabled: boolean;
};
const AttachmentItem = ({ actions, file, disabled }: Props) => {
  const { online } = useNetwork();

  // Only newly user uploaded file has originFileObj
  const isNewUploadedFile = file.originFileObj !== undefined;

  const checkIsImage = useCallback((): boolean => {
    // File is from the server
    if (!isNewUploadedFile) {
      return ["jpeg", "jpg", "png"].includes(
        file.name.toLowerCase().split(".").pop() ?? ""
      );
    }

    return file.type?.includes("image") ?? false;
  }, [file, isNewUploadedFile]);

  const renderImageBasedOnType = () => {
    if (checkIsImage()) {
      if (online || isNewUploadedFile)
        return (
          <Image
            // User newly uploaded file has thumbUrl, otherwise use url from server
            src={isNewUploadedFile ? file.thumbUrl : file.url}
            alt={file.name}
            onClick={actions.preview}
          />
        );
    }

    return (
      <AttachmentMask
        fileName={file.name}
        isImage={checkIsImage()}
        onClick={actions.download}
        isNewUploadedFile={isNewUploadedFile}
      />
    );
  };

  return (
    <Wrapper>
      {renderImageBasedOnType()}
      {/* When offline, only allow user remove the file if it is newly uploaded */}
      {!disabled && (online || isNewUploadedFile) && (
        <RemoveButton
          onClick={() => {
            confirm({
              className: "antd-custom-warning-modal",
              title: "Delete attachment?",
              icon: <ExclamationCircleOutlined />,
              content: "Are you sure you want to delete this attachment?",
              okText: "Yes",
              okButtonProps: {
                danger: true,
              },
              onOk: () => actions.remove(),
            });
          }}
        />
      )}
    </Wrapper>
  );
};

export default AttachmentItem;
