import { colors } from "@/config/theme";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

const RequiredIndicator = styled.span<{ show?: boolean }>`
  ${(props) =>
    props.show &&
    css`
      color: ${colors.dustRed[600]};
      user-select: none;
      ::before {
        content: "* ";
      }
    `}
`;
export default RequiredIndicator;
