/* eslint-disable no-param-reassign */

const BASE_FONT_SIZE = 16;
/**
 * Generates an object which translate pixel to rem values (e.g 24px = 1.5rem)
 * @param {Number} from - The smallest font number in px
 * @param {Number} to - The largest font number in px
 * @see https://www.joshwcomeau.com/css/surprising-truth-about-pixels-and-accessibility/#calculated-values-14
 * @returns An object which maps pixel-sized keys to rem-sized values
 */
export const generateFontSize = (from: number, to: number) => {
  const result = Array.from({ length: to + 1 - from }).reduce<
    Record<string, string>
  >((fontSizes, _, index) => {
    const pixelSize = from + index;
    const remSize = from + index;
    fontSizes[pixelSize] = `calc(${remSize}rem / ${BASE_FONT_SIZE})`;
    return fontSizes;
  }, {});
  return result;
};
/**
 * Generates an object using the spacing of the theme. The object is the same
 * as the spacing, but without units.
 */
export const generateSizes = (spacing: Record<string, string>, unit = "px") =>
  Object.keys(spacing).reduce<Record<string, number>>((prev, space: string) => {
    const size = spacing[space];
    prev[space] = Number(size.replace(unit, ""));
    return prev;
  }, {});
