import { useUpsertManyReports } from "@/reports/api/upsert";
import { Status } from "@/reports/config/status";
import { SafetyValveReport } from "@/reports/types/SafetyValveReport";
import { populateASME } from "@/reports/utils/asme";
import { Location } from "@/reports/config/locations";

const useBulkChangeReportStatus = () => {
  const { mutateAsync: upsertManyReports } = useUpsertManyReports();

  const changeStatus = async (
    reports: SafetyValveReport[],
    newStatus: Status,
    location: Location | undefined
  ) => {
    const stringifiedMultipleReportsValues = JSON.stringify(
      reports.map((report) => ({
        ...report,
        vLift: report.vLift?.toString(),
        ...populateASME(report),
        location,
        // these fields are not allowed in the API
        createdAt: undefined,
        updatedAt: undefined,
        ASME: undefined,
        notSynced: undefined,
        isNewOfflineReport: undefined,
        Attachments: undefined,
        NewAddedAttachments: undefined,
        DeletedAttachments: undefined,

        // status is the only field that we want to change
        status: newStatus,
      }))
    );

    const formData = new FormData();
    formData.append("reports", stringifiedMultipleReportsValues);

    try {
      const {
        data: { result },
      } = await upsertManyReports(formData);

      // if any of the reports were updated, return true
      if (result.some((item) => item.updated.length > 0)) return true;
      return false;
    } catch {
      return false;
    }
  };

  return {
    changeStatus,
  };
};

export default useBulkChangeReportStatus;
