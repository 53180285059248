import { ADMIN, VIEWER } from "@/auth/config/roles";
import TextField from "@/components/TextField";
import { colors, font, spacing } from "@/config/theme";
import {
  UpdateProfileSchema,
  UpdateProfileType,
} from "@/profile/types/Profile";
import { UserInfo } from "@/user-control/types/UserInfo";
import styled from "@emotion/styled";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "antd";
import React, { useEffect } from "react";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  useFormContext,
} from "react-hook-form";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${spacing.lg};
`;

const Form = styled.form`
  margin-bottom: ${spacing.lg};
  width: 100%;
`;

const ChangePasswordSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing.lg};
`;

const ChangePasswordButton = styled(Button)`
  width: 167px;
  margin-top: ${spacing.xxxs};
`;

const Subtitle = styled.p`
  font-size: ${font.size[16]};
  font-weight: 600;
  color: ${colors.gray[800]};
`;

const OrganizationInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing.lg};
`;

const ListTitle = styled.span`
  margin-top: ${spacing.lg};
  margin-bottom: ${spacing.xxxs};
  font-size: ${font.size[14]};
  color: ${colors.gray[800]};
`;

const SiteList = styled.ul`
  padding-left: 20px;
`;

type ProfileFormImplProps = {
  userInfo: UserInfo;
  changeSubmitBtnDisabledState: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProfileFormImpl = ({
  userInfo,
  changeSubmitBtnDisabledState,
}: ProfileFormImplProps) => {
  const {
    formState: { isDirty, isValid },
  } = useFormContext<UpdateProfileType>();

  useEffect(() => {
    changeSubmitBtnDisabledState(!isValid || !isDirty);
  }, [changeSubmitBtnDisabledState, isDirty, isValid]);

  return (
    <FormWrapper>
      {userInfo.Role === VIEWER && <Subtitle>Personal Information</Subtitle>}
      {/* Only allow admin to edit name */}
      <TextField
        label="Full Name"
        name="Name"
        disabled={userInfo.Role !== ADMIN}
      />
      <TextField label="Email" name="Email" disabled />
    </FormWrapper>
  );
};

type ProfileFormProps = {
  userInfo: UserInfo;
  onSubmit: SubmitHandler<UpdateProfileType>;
  changeSubmitBtnDisabledState: React.Dispatch<React.SetStateAction<boolean>>;
  openChangePasswordDrawer: () => void;
};

const ProfileForm = ({
  userInfo,
  onSubmit,
  changeSubmitBtnDisabledState,
  openChangePasswordDrawer,
}: ProfileFormProps) => {
  const methods = useForm<UpdateProfileType>({
    defaultValues: {
      Name: userInfo.Name,
      Email: userInfo.Email,
    },
    resolver: zodResolver(UpdateProfileSchema),
    mode: "onBlur",
    reValidateMode: "onSubmit",
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <Form id="update-profile-form" onSubmit={handleSubmit(onSubmit)}>
        <ProfileFormImpl
          userInfo={userInfo}
          changeSubmitBtnDisabledState={changeSubmitBtnDisabledState}
        />
        <ChangePasswordSection>
          <span>Password</span>
          <ChangePasswordButton
            type="default"
            onClick={openChangePasswordDrawer}
          >
            Change Password
          </ChangePasswordButton>
        </ChangePasswordSection>
        {/* Display associated sites for viewer */}
        {userInfo.Role === VIEWER && (
          <OrganizationInfoContainer>
            <Subtitle>Organization Information</Subtitle>
            <ListTitle>Associated Sites</ListTitle>
            <SiteList>
              {userInfo.Sites &&
                userInfo.Sites.map((site) => (
                  <li key={site.SiteId}>{site.SiteName}</li>
                ))}
            </SiteList>
          </OrganizationInfoContainer>
        )}
      </Form>
    </FormProvider>
  );
};

export default ProfileForm;
