import { spacing } from "@/config/theme";
import { NANAIMO, PRINCE_GEORGE } from "@/reports/config/locations";
import { displayLocationName } from "@/utils/display";
import styled from "@emotion/styled";
import { Radio, Space } from "antd";
import { useController } from "react-hook-form";

const Span = styled.span`
  margin-right: ${spacing.sm};
  display: inline-block;
  width: 60px;
`;

const LocationSelector = () => {
  const { field } = useController({
    name: "Location",
  });

  return (
    <div>
      <Span>Location</Span>
      <Radio.Group id="Location" {...field}>
        <Space direction="vertical">
          <Radio value={PRINCE_GEORGE}>
            {displayLocationName(PRINCE_GEORGE)}
          </Radio>
          <Radio value={NANAIMO}>{displayLocationName(NANAIMO)}</Radio>
        </Space>
      </Radio.Group>
    </div>
  );
};

export default LocationSelector;
