import { useState } from "react";
import { colors, spacing } from "@/config/theme";
import styled from "@emotion/styled";
import { Button, Checkbox, Space } from "antd";
import { ColumnFilterItem, FilterConfirmProps } from "antd/es/table/interface";
import { CheckboxValueType } from "antd/es/checkbox/Group";

const Wrapper = styled.div`
  padding: ${spacing.xs};
  display: flex;
  flex-direction: column;
  gap: ${spacing.xxs};
`;

const ActionsContainer = styled(Space)`
  border-top: 1px solid ${colors.gray[500]};
  padding-top: ${spacing.xxs};
`;

type CheckboxDropdownProps = {
  options: ColumnFilterItem[] | undefined;
  defaultCheckedValues?: string[];
  confirm: (param?: FilterConfirmProps) => void;
  onClearFilters: () => void;
  onApplyFilters: (values: string[]) => void;
};

const CheckboxDropdown = ({
  options,
  defaultCheckedValues = [],
  confirm,
  onClearFilters,
  onApplyFilters,
}: CheckboxDropdownProps) => {
  const [selectedValues, setSelectedValues] =
    useState<string[]>(defaultCheckedValues);

  if (!options) {
    return null;
  }

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedValues(checkedValues as string[]);
  };

  return (
    <Wrapper>
      <Checkbox.Group
        defaultValue={defaultCheckedValues}
        onChange={onChange}
        value={selectedValues}
      >
        <Space direction="vertical">
          {options.map((option) => (
            <Checkbox
              key={`${option.text}-${option.value}`}
              value={option.value}
            >
              {option.text}
            </Checkbox>
          ))}
        </Space>
      </Checkbox.Group>
      <ActionsContainer>
        <Button
          size="small"
          type="link"
          disabled={selectedValues.length === 0}
          onClick={() => {
            setSelectedValues([]);
            onClearFilters();
          }}
        >
          Clear Filters
        </Button>
        <Button
          size="small"
          type="primary"
          onClick={() => {
            onApplyFilters(selectedValues);
            confirm({ closeDropdown: true });
          }}
        >
          Apply
        </Button>
      </ActionsContainer>
    </Wrapper>
  );
};

export default CheckboxDropdown;
