import React from "react";
import styled from "@emotion/styled";
import { Input as AntdInput } from "antd";
import type { InputProps as AntdInputProps } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useController } from "react-hook-form";
import { SerializedStyles } from "@emotion/react";
import FieldWrapper from "@/components/FieldWrapper";
import { colors, spacing } from "@/config/theme";
import { ErrorMessage } from "@hookform/error-message";
import FieldError from "@/components/FieldError";

type InputProps = AntdInputProps;

export type PasswordFieldProps = {
  name: string;
  label: React.ReactNode;
  css?: SerializedStyles;
  showPasswordStrength?: boolean;
  showErrorMessage?: boolean;
} & InputProps;

type LiProps = {
  conditionMet: boolean;
  inputValue: string;
};

const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;
  row-gap: ${spacing.xxs};
  margin-top: ${spacing.lg};
  padding-inline-start: ${spacing.sm};
`;

const Li = styled.li<LiProps>`
  flex-basis: 100%;
  color: ${(props) =>
    props.conditionMet ? colors.black[100] : colors.gray[700]};

  &::marker {
    color: ${(props) => (props.conditionMet ? colors.green[600] : "")};
  }
`;

const PasswordField = ({
  name,
  label,
  css: styles,
  showPasswordStrength,
  showErrorMessage = false,
}: PasswordFieldProps) => {
  const { field } = useController({ name });
  const { value } = field;
  const passwordRequirements = [
    {
      text: "Minimum 8 characters",
      condition: value?.length >= 8,
    },
    {
      text: "1 uppercase character",
      condition: value?.match(/[A-Z]/),
    },
    {
      text: "1 lowercase character",
      condition: value?.match(/[a-z]/),
    },
    {
      text: "1 number",
      condition: value?.match(/\d/),
    },
  ];
  return (
    <FieldWrapper css={styles}>
      <label htmlFor={name}>{label}</label>
      <AntdInput.Password
        id={name}
        placeholder={typeof label === "string" ? label : undefined}
        // Implemented based on official docs (https://ant.design/components/input)
        // TODO: Implement this without using unstable nested components
        // eslint-disable-next-line react/no-unstable-nested-components
        iconRender={(visible) =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
        {...field}
      />
      {showErrorMessage && <ErrorMessage name={name} as={FieldError} />}
      {showPasswordStrength && (
        <Ul>
          {passwordRequirements.map((requirement) => (
            <Li
              conditionMet={requirement.condition}
              inputValue={value}
              key={requirement.text}
            >
              {requirement.text}
            </Li>
          ))}
        </Ul>
      )}
    </FieldWrapper>
  );
};

export default PasswordField;
