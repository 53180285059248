import { ADMIN, Role, TECHNICIAN } from "@/auth/config/roles";
import useRole from "@/auth/hooks/useRole";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// List of protected paths and the roles that can access them.
// Add a new entry to this list if you want to protect a new route.
const PROTECTED_ROUTES: Array<{
  path: string;
  roles: Role[];
}> = [
  {
    path: "/reports/new",
    roles: [ADMIN, TECHNICIAN],
  },
  {
    path: "/reports/edit",
    roles: [ADMIN, TECHNICIAN],
  },
  {
    path: "/sites",
    roles: [ADMIN],
  },
  {
    path: "/lookups",
    roles: [ADMIN],
  },
  {
    path: "/user-management",
    roles: [ADMIN],
  },
];

/**
 * This hook protects routes from being accessed by users who don't have the correct role.
 */
const useRouteProtector = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { role } = useRole();

  useEffect(() => {
    // Check if the current path is protected.
    const targetProtectedPath = PROTECTED_ROUTES.find((route) =>
      location.pathname.includes(route.path)
    );

    if (targetProtectedPath && role) {
      if (targetProtectedPath.roles.includes(role)) {
        return;
      }

      // If the user doesn't have the correct role, redirect them to the home page.
      navigate("/page-not-found", {
        replace: true,
      });
    }
  }, [location, navigate, role]);
};

export default useRouteProtector;
