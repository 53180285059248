import { css, Global as GlobalStyles } from "@emotion/react";

const Reset = () => {
  return (
    <GlobalStyles
      styles={css`
        *,
        *::before,
        *::after {
          box-sizing: border-box;
        }

        * {
          margin: 0;
        }

        html {
          height: 100%;
        }
        body {
          line-height: 1.5;
          -webkit-font-smoothing: antialiased;
          font-family: -apple-system, BlinkMacSystemFont, sans-serif;
          height: 100%;
        }

        img,
        picture,
        video,
        canvas,
        svg {
          display: block;
          max-width: 100%;
        }

        input,
        button,
        textarea,
        select {
          font: inherit;
        }

        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          overflow-wrap: break-word;
        }

        #root {
          isolation: isolate;
          height: 100%;
        }
      `}
    />
  );
};

export default Reset;
