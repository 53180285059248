import React, { useEffect } from "react";
import { spacing } from "@/config/theme";
import { CustomColumn, SearchHandler } from "@/types/Table";
import styled from "@emotion/styled";
import { Input as AntdInput, InputRef } from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";

const Wrapper = styled.div`
  padding: ${spacing.xxs};
`;

const { Search } = AntdInput;

const Input = styled(Search)``;

type Props<T> = FilterDropdownProps & {
  column: CustomColumn<T>;
  searchInputRef: React.RefObject<InputRef>;
  handleSearch: SearchHandler<T>;
  initialValue?: string;
};

/** A dropdown filter to search text within a column of an Antd table */
const SearchDropdown = <T,>({
  setSelectedKeys,
  selectedKeys,
  column,
  searchInputRef,
  handleSearch,
  confirm,
  initialValue = "",
}: Props<T>) => {
  // Set the initial value of the search input when the component mounts
  useEffect(() => {
    setSelectedKeys([initialValue]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper role="menu">
      <div role="menuitem" tabIndex={0} onKeyDown={(e) => e.stopPropagation()}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <Input
          ref={searchInputRef}
          placeholder={column.filterPlaceholder || "Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onSearch={(value) => {
            // Notes: Because allowClear will also trigger onSearch, we need to update selectedKeys to the current value
            setSelectedKeys(value ? [value] : []);
            handleSearch([value], confirm, column);
          }}
          allowClear
          onBlur={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            handleSearch([e.target.value], confirm, column);
          }}
        />
      </div>
    </Wrapper>
  );
};

export default SearchDropdown;
