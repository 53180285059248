import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { LoginTypeResponse } from "@/auth/types/Login";
import { Dayjs } from "dayjs";

type UserStore = {
  user: LoginTypeResponse | undefined;
  setUser: (user: LoginTypeResponse) => void;
  clearUser: () => void;
  oldPassword: string | undefined;
  setOldPassword: (oldPassword: string) => void;
  attempts: number;
  setAttempts: (retries: number) => void;
  retryAfter: Dayjs | null;
  setRetryAfter: (retryAfter: Dayjs | null) => void;
};

export const useUserStore = create<UserStore>()(
  persist(
    (set) => ({
      user: undefined,
      oldPassword: undefined,
      attempts: 0,
      setUser: (user) => set((state) => ({ ...state, user })),
      setOldPassword: (oldPassword) =>
        set((state) => ({ ...state, oldPassword })),
      clearUser: () =>
        set((state) => ({ ...state, user: undefined, oldPassword: undefined })),
      setAttempts: (attempts) => set((state) => ({ ...state, attempts })),
      retryAfter: null,
      setRetryAfter: (retryAfter) => set((state) => ({ ...state, retryAfter })),
    }),
    {
      name: "user-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
