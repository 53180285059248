import { useCallback, useMemo } from "react";
import { SubmitCreateAccount } from "@/user-control/types/Account";
import { useCreateAccount } from "@/user-control/api";

type useSubmitCreateAccountCredentialsProps = {
  onSuccessFn: (values: SubmitCreateAccount) => void;
};

const useSubmitCreateAccountCredentials = ({
  onSuccessFn,
}: useSubmitCreateAccountCredentialsProps) => {
  const {
    mutateAsync: createAccount,
    isLoading,
    isError,
    isSuccess,
    error,
  } = useCreateAccount({ onSuccessFn });

  const save = useCallback(
    async (values: SubmitCreateAccount) => {
      try {
        return await createAccount(values);
      } catch (apiError) {
        return apiError;
      }
    },
    [createAccount]
  );

  return useMemo(
    () => ({ save, isLoading, isError, isSuccess, error }),
    [save, isLoading, isError, isSuccess, error]
  );
};

export default useSubmitCreateAccountCredentials;
