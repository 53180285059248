import { SafetyValveReport } from "@/reports/types/SafetyValveReport";
import { FilePdfFilled } from "@ant-design/icons";
import { Alert, Button, Space, Spin } from "antd";
import ImageAttachmentWarningModal from "@/reports/components/ReportForm/ExportPdf/ImageAttachmentWarningModal";
import useBoolean from "@/hooks/useBoolean";
import { Location } from "@/reports/config/locations";
import { exportReportsZipFile } from "@/reports/utils/pdf";
import styled from "@emotion/styled";
import { colors } from "@/config/theme";

const Title = styled.b`
  color: ${colors.daybreakBlue[100]};
`;

type BulkExportBannerProps = {
  selectedRows: SafetyValveReport[];
  setSelectedRowKeys: (keys: string[]) => void;
  location?: Location;
};

const BulkExportBanner = ({
  selectedRows,
  setSelectedRowKeys,
  location,
}: BulkExportBannerProps) => {
  const {
    value: showImageAttachmentWarning,
    setValue: setShowImageAttachmentWarning,
  } = useBoolean(false);

  const { value: spinning, setValue: setSpinning } = useBoolean(false);

  const onCancel = () => setShowImageAttachmentWarning(false);

  const handleBulkExport = async (includeImage: boolean) => {
    setShowImageAttachmentWarning(false);
    setSpinning(true);
    exportReportsZipFile(selectedRows, includeImage, location).finally(() => {
      setSpinning(false);
      setSelectedRowKeys([]);
    });
  };

  return (
    <>
      <Alert
        message={<Title>Export selected reports</Title>}
        type="info"
        action={
          <Space>
            <Button type="default" onClick={() => setSelectedRowKeys([])}>
              Deselect All
            </Button>
            <Button
              icon={<FilePdfFilled />}
              type="primary"
              onClick={() => {
                setShowImageAttachmentWarning(true);
              }}
            >
              Export to PDFs
            </Button>
          </Space>
        }
      />
      <ImageAttachmentWarningModal
        type="bulk"
        visible={showImageAttachmentWarning}
        onCancel={onCancel}
        onExport={handleBulkExport}
      />
      <Spin spinning={spinning} fullscreen />
    </>
  );
};

export default BulkExportBanner;
