import useBoolean from "@/hooks/useBoolean";
import { Checkbox, Modal } from "antd";

type ImageAttachmentWarningModalProps = {
  type?: "individual" | "bulk";
  visible: boolean;
  onCancel: () => void;
  onExport: (includeImage: boolean) => void;
};

const ImageAttachmentWarningModal = ({
  type = "individual",
  visible,
  onCancel,
  onExport,
}: ImageAttachmentWarningModalProps) => {
  const {
    value: includeImageAttachments,
    setValue: setIncludeImageAttachments,
  } = useBoolean(false);

  return (
    <Modal
      className="antd-custom-warning-modal"
      open={visible}
      title={`Export to PDF${type === "bulk" ? "s" : ""}`}
      onCancel={onCancel}
      onOk={() => onExport(includeImageAttachments)}
      okText="Export"
      afterClose={() => setIncludeImageAttachments(false)}
      width={400}
    >
      <Checkbox
        onChange={(e) => {
          setIncludeImageAttachments(e.target.checked);
        }}
        checked={includeImageAttachments}
      >
        Include image attachments
      </Checkbox>
    </Modal>
  );
};

export default ImageAttachmentWarningModal;
