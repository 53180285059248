import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Drawer, Alert, Space } from "antd";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  UpdateSiteSchema,
  UpdateSiteType,
  Site,
  CreateSiteSchema,
} from "@/sites/types/Site";
import TextField from "@/components/TextField";
import RadioField from "@/components/RadioField";
import { colors, spacing, font } from "@/config/theme";
import Footer from "./Footer";

type Props = {
  onSubmit: (values: UpdateSiteType) => Promise<void>;
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  isError: boolean;
  selectedSite: Site | null;
  setSelectedSite: React.Dispatch<React.SetStateAction<Site | null>>;
};

type SiteDrawerImplProps = Pick<Props, "isError" | "selectedSite">;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.md};

  .ant-input-disabled {
    color: ${colors.gray[800]} !important;
  }
`;

const Hr = styled.hr`
  border: 1px solid ${colors.gray[400]};
`;

const Title = styled.div`
  font-size: ${font.size[24]};
  color: ${colors.gray[900]};
`;

const ClientListBody = styled.ul`
  border: 1px solid ${colors.gray[500]};
  border-radius: 2px;
  height: 240px;
  overflow-y: scroll;
  list-style-type: none;
  padding-inline-start: 0px;
`;

const ClientListItem = styled.li`
  margin: ${spacing.xs} ${spacing.sm};
`;

const ClientListPlaceholder = styled.div`
  font-size: ${font.size[14]};
  color: ${colors.gray[700]};
`;

const ClientListTitle = styled.div`
  font-size: ${font.size[16]};
  font-weight: 600;
  color: ${colors.gray[800]};
`;

const SiteDrawerImpl = ({ isError, selectedSite }: SiteDrawerImplProps) => {
  return (
    <Container>
      {isError && (
        <Alert
          closable
          showIcon
          type="error"
          message="An error has occurred, please try again."
        />
      )}
      <Title>{`${selectedSite ? "Edit" : "Create New"} Site`}</Title>
      <Hr />
      <TextField label="Site Name" name="SiteName" disabled={!!selectedSite} />
      {selectedSite && (
        <>
          <RadioField
            name="Show"
            label="Status"
            activeText="Active"
            inactiveText="Inactive"
          />
          <Space direction="vertical">
            <ClientListTitle>Assigned Viewers</ClientListTitle>
            {selectedSite?.Users.length ? (
              <ClientListBody>
                {selectedSite.Users.map((user) => (
                  <ClientListItem key={user.UserId}>{user.Name}</ClientListItem>
                ))}
              </ClientListBody>
            ) : (
              <ClientListPlaceholder>
                This site has no viewers. Go to User Management via the menu to
                add viewers to this site.
              </ClientListPlaceholder>
            )}
          </Space>
        </>
      )}
    </Container>
  );
};

const SiteDrawer = ({
  open,
  onClose,
  isLoading,
  onSubmit,
  selectedSite,
  setSelectedSite,
  isError,
}: Props) => {
  const methods = useForm<UpdateSiteType>({
    resolver: zodResolver(selectedSite ? UpdateSiteSchema : CreateSiteSchema),
    defaultValues: {
      SiteName: selectedSite?.SiteName,
      Show: selectedSite?.Show,
    },
    reValidateMode: "onBlur",
  });
  const { reset } = methods;
  useEffect(() => {
    reset({
      SiteName: selectedSite?.SiteName,
      Show: selectedSite?.Show,
    });
  }, [selectedSite?.SiteName, selectedSite?.Show, reset]);
  const handleClose = () => {
    reset();
    setSelectedSite(null);
    onClose();
  };
  return (
    <FormProvider {...methods}>
      <form>
        <Drawer
          key={selectedSite?.SiteId}
          open={open}
          onClose={handleClose}
          closeIcon={null}
          maskClosable={false}
          destroyOnClose
          footer={
            <Footer
              onClose={handleClose}
              isLoading={isLoading}
              onSubmit={onSubmit}
            />
          }
        >
          <SiteDrawerImpl isError={isError} selectedSite={selectedSite} />
        </Drawer>
      </form>
    </FormProvider>
  );
};

export default SiteDrawer;
