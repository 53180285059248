import dayjs, { Dayjs } from "dayjs";
import {
  MAX_RETRY_ATTEMPTS,
  RETRY_WARNING_THRESHOLD,
} from "@/auth/config/retry";

const addPadding = (value: number) => String(value).padStart(2, "0");

export const generateErrorMessage = (
  attempts: number,
  retryAfter: Dayjs | null
) => {
  if (attempts >= MAX_RETRY_ATTEMPTS) {
    const diffSeconds = dayjs(retryAfter)?.diff(dayjs(), "seconds");
    const totalMinutes = Math.floor(diffSeconds / 60);
    const totalSeconds = diffSeconds - totalMinutes * 60;
    return `You have used all your attempts. You can retry in ${addPadding(
      totalMinutes
    )} minutes ${addPadding(totalSeconds)} seconds.`;
  }
  if (attempts > RETRY_WARNING_THRESHOLD) {
    return `You have ${MAX_RETRY_ATTEMPTS - attempts} more attempt(s).`;
  }
  return "";
};
