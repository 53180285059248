import { Flex, Space, Radio, Tag } from "antd";
import React from "react";
import { useController } from "react-hook-form";
import { SerializedStyles } from "@emotion/react";
import FieldWrapper from "@/components/FieldWrapper";

export type Props = {
  name: string;
  label: React.ReactNode;
  css?: SerializedStyles;
  activeText: string;
  inactiveText: string;
};

const RadioField = ({
  name,
  label,
  activeText,
  inactiveText,
  css,
  ...props
}: Props) => {
  const { field } = useController({ name });
  return (
    <FieldWrapper css={css}>
      <Flex gap="large">
        <label htmlFor={name}>{label}</label>
        <Radio.Group {...field} {...props}>
          <Space direction="vertical">
            <Radio value={false}>
              <Tag>{inactiveText}</Tag>
            </Radio>
            <Radio value>
              <Tag color="green">{activeText}</Tag>
            </Radio>
          </Space>
        </Radio.Group>
      </Flex>
    </FieldWrapper>
  );
};

export default RadioField;
