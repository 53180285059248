import { Key } from "react";
import { Space, Tag } from "antd";
import { SITE_ACTIVE, SITE_INACTIVE } from "@/sites/config/status";
import { User, Site } from "@/sites/types/Site";
import { CustomColumns } from "@/types/Table";
import { handleFilter } from "@/utils/table";

const columns: CustomColumns<Site> = [
  {
    title: "Site",
    dataIndex: "SiteName",
    key: "SiteName",
    width: "25%",
    filterType: "search",
    onFilter: (value: boolean | Key, record: Site) =>
      handleFilter(value, record, "SiteName"),
  },
  {
    title: "Viewers on Site",
    dataIndex: "Users",
    key: "Users",
    render: (value: User[]) => (
      <Space size={[0, "small"]} wrap>
        {value.map((user) => {
          const { UserId, Name } = user as { UserId: number; Name: string };
          return <Tag key={UserId}>{Name}</Tag>;
        })}
      </Space>
    ),
    width: "60%",
  },
  {
    title: "Status",
    dataIndex: "Show",
    key: "Show",
    render: (value: boolean) => (
      <Tag color={value ? "green" : ""}>
        {value ? SITE_ACTIVE : SITE_INACTIVE}
      </Tag>
    ),
    filters: [
      { text: SITE_ACTIVE, value: SITE_ACTIVE },
      { text: SITE_INACTIVE, value: SITE_INACTIVE },
    ],
    onFilter: (value: boolean | Key, record: Site) => {
      return value === SITE_ACTIVE ? record.Show : !record.Show;
    },
  },
];

export default columns;
