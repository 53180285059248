import styled from "@emotion/styled";
import { Spin } from "antd";

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
`;

type Props = {
  size?: "small" | "default" | "large";
};

const Loading = ({ size = "default" }: Props) => {
  return (
    <Wrapper>
      <Spin size={size} />
    </Wrapper>
  );
};

export default Loading;
