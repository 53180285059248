import styled from "@emotion/styled";
import { font } from "@/config/theme";

const Message = styled.div`
  display: grid;
  place-items: center;
  font-size: ${font.size[24]};
  font-weight: 600;
  height: 100%;
`;

const PageNotFound = () => {
  return <Message>Page Not Found</Message>;
};

export default PageNotFound;
