import { Key } from "react";

export const handleFilter = (
  value: boolean | Key,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  record: any,
  field: string
) =>
  (record[field] || "")
    .toString()
    .toLowerCase()
    .includes((value as string).toLowerCase());
