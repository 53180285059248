import {
  Status,
  IN_PROGRESS,
  FOR_REVIEW,
  SIGNED_OFF,
  PUBLISHED,
  ARCHIVED,
  W_ARCHIVE,
  STATUS_OPTIONS,
  WAREHOUSE,
} from "@/reports/config/status";
import { Role, TECHNICIAN } from "@/auth/config/roles";
import emptyInprogressImage from "@/assets/images/empty-inprogress.svg";
import emptyInReviewImage from "@/assets/images/empty-inreview.svg";
import emptySignOffedImage from "@/assets/images/empty-signedOff.svg";
import emptyPublishedImage from "@/assets/images/empty-published.svg";
import emptyWarehouseImage from "@/assets/images/empty-warehouse.svg";
import emptyWArchivedImage from "@/assets/images/empty-warchived.svg";
import emptyNoResultsImage from "@/assets/images/empty-noResults.svg";
import { SafetyValveReport } from "@/reports/types/SafetyValveReport";
import { getASME } from "./asme";

export const getPageTitle = (status: Status | undefined) => {
  switch (status) {
    case IN_PROGRESS:
      return "Edit Report";
    case FOR_REVIEW:
      return "Review Report";
    case SIGNED_OFF:
      return "Signed Off Report";
    case PUBLISHED:
      return "Published Report";
    case WAREHOUSE:
      return "Warehouse Report";
    case W_ARCHIVE:
      return "W-Archived Report";
    default:
      return "Edit Report";
  }
};

export const getSuccessMessage = (status: Status) => {
  switch (status) {
    case FOR_REVIEW:
      return "Report submitted";
    case SIGNED_OFF:
      return "Report signed off";
    case PUBLISHED:
      return "Report published";
    case ARCHIVED:
      return "Report archived";
    case WAREHOUSE:
      return "Report sent to warehouse";
    case W_ARCHIVE:
      return "Report duplicated and archived";
    default:
      return "Report saved";
  }
};

export const getNextStatusForSubmitButton = (status: Status | undefined) => {
  switch (status) {
    case IN_PROGRESS:
      return "Submit for Review";
    case FOR_REVIEW:
      return "Sign Off";
    case SIGNED_OFF:
      return "Publish";
    case WAREHOUSE:
      return "Duplicate and Archive";
    default:
      return "Submit for Review";
  }
};

const EMPTY_STATE = {
  IN_PROGRESS: {
    imageUrl: emptyInprogressImage,
    description: "Start a report by clicking the “Create New” button",
  },
  FOR_REVIEW: {
    imageUrl: emptyInReviewImage,
    description: "No reports need reviewing",
  },
  SIGNED_OFF: {
    imageUrl: emptySignOffedImage,
    description: "All signed off reports have been published!",
  },
  PUBLISHED: {
    imageUrl: emptyPublishedImage,
    description: "No reports are currently published",
  },
  WAREHOUSE: {
    imageUrl: emptyWarehouseImage,
    description: "There are no valves currently stored in the warehouse",
  },
  W_ARCHIVE: {
    imageUrl: emptyWArchivedImage,
    description: "There are currently no archived warehouse valves",
  },
  DEFAULT: {
    imageUrl: emptyInprogressImage,
    description: "There are no reports",
  },
  NO_RESULTS: {
    imageUrl: emptyNoResultsImage,
    description: "No reports found",
  },
};

export const getEmptyStateBaseOnStatus = (
  status: Status,
  isViewer = false,
  isSearch = false
): {
  imageUrl: string;
  description: string;
} => {
  // If the user is searching and there are no results, no matter the status, the empty state will be no results
  if (isSearch) {
    return EMPTY_STATE.NO_RESULTS;
  }

  // If the user is a viewer, the empty state will be either warehouse or published
  if (isViewer) {
    if (status === WAREHOUSE) {
      return EMPTY_STATE.WAREHOUSE;
    }

    return EMPTY_STATE.PUBLISHED;
  }

  switch (status) {
    case IN_PROGRESS:
      return EMPTY_STATE.IN_PROGRESS;

    case FOR_REVIEW:
      return EMPTY_STATE.FOR_REVIEW;

    case SIGNED_OFF:
      return EMPTY_STATE.SIGNED_OFF;

    case PUBLISHED:
      return EMPTY_STATE.PUBLISHED;

    case WAREHOUSE:
      return EMPTY_STATE.WAREHOUSE;

    case W_ARCHIVE:
      return EMPTY_STATE.W_ARCHIVE;

    // This will never be reached ideally
    default:
      return EMPTY_STATE.DEFAULT;
  }
};

/**
 * Utility function to create a new report from an existing report by copying some fields
 * @param existingReport SafetyValveReport object copied from published report
 * @returns Some SafetyValveReport fields that copy required
 */
export const createDefaultReportValuesFromCopy = (
  existingReport: SafetyValveReport
) => {
  return {
    vCustomer: existingReport.vCustomer,
    vPSVNumber: existingReport.vPSVNumber,
    vManufacturer: existingReport.vManufacturer,
    vModelNumber: existingReport.vModelNumber,
    vSerialNumber: existingReport.vSerialNumber,
    vOrifice: existingReport.vOrifice,
    vValveSize: existingReport.vValveSize,
    vInlet: existingReport.vInlet,
    vOutlet: existingReport.vOutlet,
    vUnit: existingReport.vUnit,
    vCRNNumber: existingReport.vCRNNumber,
    vCapType: existingReport.vCapType,
    vService: existingReport.vService,
    vBonnet: existingReport.vBonnet,
    vBellowsMat: existingReport.vBellowsMat,
    vBodyMaterial: existingReport.vBodyMaterial,
    vSeatMaterial: existingReport.vSeatMaterial,
    ASME: getASME(existingReport),
    vRestrictedLift: existingReport.vRestrictedLift,
    vLift: existingReport.vLift,
  };
};

export const filterNullValues = (values: SafetyValveReport) => {
  const filteredValues = Object.fromEntries(
    Object.entries(values).filter(([, value]) => value !== null)
  );
  return filteredValues;
};

export const getReportStatusOptionsByRole = (role: Role | undefined) => {
  const availableStatusOptions = STATUS_OPTIONS;

  // Some statuses are not available for technicians
  const technicianExcludedStatuses = [W_ARCHIVE];

  if (role === TECHNICIAN) {
    return availableStatusOptions.filter(
      (status) => !technicianExcludedStatuses.includes(status.value)
    );
  }

  return availableStatusOptions;
};
