export const generateRandomPassword = () => {
  const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
  const numbers = "0123456789";

  // Generate a random uppercase letter
  const randomUppercase = uppercaseLetters.charAt(
    Math.floor(Math.random() * uppercaseLetters.length)
  );

  // Generate a random lowercase letter
  const randomLowercase = lowercaseLetters.charAt(
    Math.floor(Math.random() * lowercaseLetters.length)
  );

  // Generate a random number
  const randomNumber = numbers.charAt(
    Math.floor(Math.random() * numbers.length)
  );

  // Generate remaining random characters
  const remainingChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomChars = "";
  for (let i = 0; i < 5; i += 1) {
    randomChars += remainingChars.charAt(
      Math.floor(Math.random() * remainingChars.length)
    );
  }

  // Shuffle all generated characters
  const allChars =
    randomUppercase + randomLowercase + randomNumber + randomChars;
  const shuffledChars = allChars
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");

  return shuffledChars;
};
