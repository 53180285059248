import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { PRINCE_GEORGE } from "@/reports/config/locations";
import TextField from "@/components/TextField";
import { colors, font, spacing } from "@/config/theme";
import { zodResolver } from "@hookform/resolvers/zod";
import { Role, TECHNICIAN, VIEWER } from "@/auth/config/roles";
import { generateRandomPassword } from "@/user-control/utils/password";
import {
  CreateTechnicianAccountSchema,
  CreateTechnicianAccountType,
  CreateViewerAccountSchema,
  CreateViewerAccountType,
} from "@/user-control/types/Account";
import LocationSelector from "@/user-control/components/LocationSelector";
import SiteSelector from "@/user-control/components/SiteSelector";
import FirstTimePasswordInputField from "@/user-control/components/FirstTimePasswordInputField";
import { displayRoleName } from "@/utils/display";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${spacing.lg};
`;

const Form = styled.form`
  margin-bottom: ${spacing.lg};
  width: 100%;
`;

const Subtitle = styled.p`
  font-size: ${font.size[16]};
  font-weight: 600;
  color: ${colors.gray[800]};
`;

interface CreateAccountFormImplProps {
  userRole: Role;
  changeSubmitBtnDisabledState: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateAccountFormImpl = ({
  userRole,
  changeSubmitBtnDisabledState,
}: CreateAccountFormImplProps) => {
  const {
    formState: { isDirty, isValid },
  } = useFormContext<CreateTechnicianAccountType | CreateViewerAccountType>();

  useEffect(() => {
    changeSubmitBtnDisabledState(!isValid || !isDirty);
  }, [changeSubmitBtnDisabledState, isDirty, isValid]);

  return (
    <FormWrapper>
      <Subtitle>{displayRoleName(userRole)} Info</Subtitle>
      <TextField label="Full Name" name="Name" autoComplete="off" />
      <TextField label="Email" name="Email" autoComplete="off" />
      <FirstTimePasswordInputField
        label="First Time Password"
        name="Password"
      />
      {userRole === TECHNICIAN && <LocationSelector />}
      {userRole === VIEWER && (
        <>
          <Subtitle>Sites</Subtitle>
          <SiteSelector />
        </>
      )}
    </FormWrapper>
  );
};

interface CreateAccountFormProps {
  userRole: Role;
  changeSubmitBtnDisabledState: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (
    data: CreateTechnicianAccountType | CreateViewerAccountType
  ) => void;
}

const CreateAccountForm = ({
  userRole,
  changeSubmitBtnDisabledState,
  onSubmit,
}: CreateAccountFormProps) => {
  const methods = useForm<
    CreateTechnicianAccountType | CreateViewerAccountType
  >({
    defaultValues: {
      Name: "",
      Email: "",
      Password: generateRandomPassword(),
      Location: PRINCE_GEORGE,
      Sites: [],
    },
    resolver: zodResolver(
      userRole === TECHNICIAN
        ? CreateTechnicianAccountSchema
        : CreateViewerAccountSchema,
      {},
      { mode: "async" }
    ),
    mode: "onBlur",
    reValidateMode: "onSubmit",
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <Form
        id="create-account-form"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <CreateAccountFormImpl
          userRole={userRole}
          changeSubmitBtnDisabledState={changeSubmitBtnDisabledState}
        />
      </Form>
    </FormProvider>
  );
};

export default CreateAccountForm;
