import { colors } from "@/config/theme";
import { Image, StyleSheet, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  checkmark: {
    width: 8,
    height: 8,
  },
  emptyCheckbox: {
    width: 8,
    height: 8,
    border: `1px solid ${colors.gray[500]}`,
    borderRadius: 1,
  },
});

const Checkbox = ({ isTrue }: { isTrue: boolean }) => {
  return isTrue ? (
    <Image
      // Checkmark base64 image
      source="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAVCAYAAAC33pUlAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHHSURBVHgB7ZQ/T8JAGMYPKrBWYGA8B/4kLLDhZPwGMLrB5qZ8AuATIJsjmyOOOtXRTRYSAgwdHYDUzUBCfR69JqWxtkS7+STN3du7669373OvEP/6hfL5fL9QKNgxEbGKxeKVbds37EcKK5fLle12+6JCMzJYqVSS2JGBRzJOJBLVIxGRdrvdCI1kPxaLtSeTyTgSGMzQQVNhHzsbzGazaHLmNgRkAnTijMXFH0rlqeuA4vH4uXv8yDO5ibM+wwJL07TBdDo1xWEgA12dMUAt7/q9nQEwRlNHQq8BNfgBEVIA9R3nQT2Anrxz9mB0DEA9FUrlqEDREADVVThEnrrfzdO8L1ar1XM6nT4GtIYwl81mdbx7FP4gQm5VaG42m4ZlWe+hYNR6vX7IZDL8SA5PDcA3/oR3njrmO/GVJwt5Ol0sFq/CR75uxMIGdmeyjyPqsPS4x6WUurtCoG0HGcoXxoWAtVSoo8aNCHDGk8nkniHm8/lQBOjHe0ZHsdSoUKZSqU/DqArRZB/j936G8EoLmsBcIX+sAjxGifyxvVTDvLgXy+XSEiEUqlzx+HBshgI6oiGqh1z8UOXKNE1+uEGA84738RAQ9QF/FMpY4oVmJgAAAABJRU5ErkJggg=="
      style={styles.checkmark}
    />
  ) : (
    <View style={styles.emptyCheckbox} />
  );
};

export default Checkbox;
