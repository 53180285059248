import { spacing } from "@/config/theme";
import useSiteStore from "@/sites/stores/sites";
import styled from "@emotion/styled";
import { Select as AntdSelect, SelectProps } from "antd";
import { useController } from "react-hook-form";

const Span = styled.span`
  display: block;
  margin-bottom: ${spacing.xxxs};
`;

const Select = styled(AntdSelect)`
  width: 100%;
`;

const SiteSelector = () => {
  const { field } = useController({
    name: "Sites",
  });
  const { sites } = useSiteStore();

  const options: SelectProps["options"] = sites.map((site) => ({
    label: site.SiteName,
    value: site.SiteId,
  }));

  const filterOption = (
    input: string,
    option: { label: string; value: number }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div>
      <Span>Assign Sites</Span>
      <Select
        mode="multiple"
        placeholder="Select"
        options={options}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filterOption={filterOption as any}
        {...field}
      />
    </div>
  );
};

export default SiteSelector;
