// /* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback } from "react";
import { useController } from "react-hook-form";
import FieldWrapper from "@/components/FieldWrapper";
import { Button as AntdButton, Input } from "antd";
import { generateRandomPassword } from "@/user-control/utils/password";
import styled from "@emotion/styled";

const Button = styled(AntdButton)`
  padding-inline: 0;
`;

interface Props {
  name: string;
  label: React.ReactNode;
}

const FirstTimePasswordInputField = ({ label, name }: Props) => {
  const { field } = useController({
    name,
  });

  const handleGeneratePasswordClick = useCallback(() => {
    field.onChange(generateRandomPassword());
  }, [field]);

  return (
    <FieldWrapper>
      <label htmlFor={name}>{label}</label>
      <Input
        id={name}
        suffix={
          <Button type="link" onClick={handleGeneratePasswordClick}>
            Generate new password
          </Button>
        }
        {...field}
      />
    </FieldWrapper>
  );
};

export default FirstTimePasswordInputField;
