import { QueryClientProvider } from "@tanstack/react-query";
import Reset from "@/components/Reset";
import { RouterProvider } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { queryClient } from "@/config/queryClient";
import router from "@/config/router";
import { withErrorBoundary } from "react-error-boundary";
import FallbackComponent from "@/components/FallbackComponent";
import { setupInterceptors } from "./lib/axios";

setupInterceptors(router);

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      {/* React Query Devtools are only included in bundles when NODE_ENV === 'development' */}
      <ReactQueryDevtools initialIsOpen={false} />
      <Reset />
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
};

export default withErrorBoundary(App, {
  FallbackComponent,
});
