import { Checkbox as AntdCheckbox } from "antd";
import type { CheckboxProps } from "antd";
import React from "react";
import { useController } from "react-hook-form";
import { SerializedStyles } from "@emotion/react";
import FieldWrapper from "@/components/FieldWrapper";
import isPropValid from "@emotion/is-prop-valid";
import styled, { StyledOptions } from "@emotion/styled";
import { ErrorMessage } from "@hookform/error-message";
import FieldError from "@/components/FieldError";

// a line height value that matches the height of an antd text input.
const TEXTFIELD_LINE_HEIGHT = 2.3;

const styleConfig: StyledOptions = {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "lineHeight",
};
const Checkbox = styled(AntdCheckbox, styleConfig)<{
  lineHeight?: string | number;
}>`
  line-height: ${(props) => props.lineHeight || TEXTFIELD_LINE_HEIGHT};
`;

export type CheckboxFieldProps = {
  name: string;
  label: React.ReactNode;
  css?: SerializedStyles;
  lineHeight?: string | number;
} & CheckboxProps;

const CheckboxField = ({
  name,
  label,
  css,
  lineHeight,
  ...props
}: CheckboxFieldProps) => {
  const {
    field: { value, ...field },
  } = useController({
    name,
  });
  return (
    <FieldWrapper css={css}>
      <Checkbox lineHeight={lineHeight} checked={value} {...field} {...props}>
        {label}
      </Checkbox>
      <ErrorMessage name={name} as={FieldError} />
    </FieldWrapper>
  );
};

export default CheckboxField;
