import axios from "@/lib/axios";
import { AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SITES_API } from "@/sites/config/api";
import { CreateSiteType, UpdateSiteType } from "@/sites/types/Site";
import { message } from "antd";

export const getSites = async () => axios.get("/api/site");

export const useGetSites = () => {
  return useQuery({
    queryFn: async () => getSites(),
    queryKey: [SITES_API],
    select: (response) => {
      return response.data;
    },
  });
};

export const createSite = async (
  values: CreateSiteType
): Promise<AxiosResponse<void>> =>
  axios.post("/api/site", { ...values, Show: true });

export const useCreateSite = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createSite,
    onSuccess: () => {
      queryClient.invalidateQueries([SITES_API]);
      message.success("Site created");
    },
    onError: () => {
      message.error("Failed to create site, please try again later");
    },
  });
};

export const updateSite = async (values: UpdateSiteType) =>
  axios.put("/api/site", values);

export const useUpdateSite = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateSite,
    onSuccess: () => {
      queryClient.invalidateQueries([SITES_API]);
      message.success("Site saved");
    },
    onError: () => {
      message.error("Failed to save site, please try again later");
    },
  });
};
