import { colors, spacing } from "@/config/theme";
import styled from "@emotion/styled";
import { Button } from "antd";
import { FallbackProps } from "react-error-boundary";

const ErrorContainer = styled.pre`
  color: ${colors.dustRed[700]};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  gap: ${spacing.sm};
`;

const FallbackComponent = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <Container>
      <h2>Oops, something went wrong!</h2>
      <p>We are working on fixing this issue.</p>
      <ErrorContainer>{error.message}</ErrorContainer>
      <Button onClick={resetErrorBoundary} type="primary">
        Retry
      </Button>
    </Container>
  );
};

export default FallbackComponent;
