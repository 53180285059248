import { UserStatus } from "@/user-control/types/UserStatus";
import { Tag } from "antd";

interface Props {
  status: UserStatus;
}

const statusColors = (status: UserStatus) => {
  switch (status) {
    case UserStatus.ACTIVE:
      return "green";

    case UserStatus.PENDING:
      return "gold";

    default:
      return undefined;
  }
};

const StatusTag = ({ status }: Props) => {
  return <Tag color={statusColors(status)}>{status}</Tag>;
};
export default StatusTag;
