import { colors } from "@/config/theme";
import styled from "@emotion/styled";

/** The red error message that shows up in fields.
 * @todo - Ask UX team for a design
 */
const FieldError = styled.span`
  color: ${colors.dustRed[700]};
  position: absolute;
  top: 100%;
`;

export default FieldError;
