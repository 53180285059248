import { useMemo } from "react";
import TextField from "@/components/TextField";
import { createFormElement, FormElement } from "@/types/Form";
import CheckboxField from "@/components/CheckboxField";
import AutocompleteField from "@/components/AutocompleteField";
import DateField from "@/components/DateField";
import { queries, spacing } from "@/config/theme";
import styled from "@emotion/styled";
import { Divider } from "antd";
import { css } from "@emotion/react";
import useLookupStore from "@/lookups/stores/lookups";
import useRole from "@/auth/hooks/useRole";
import { transformRecordsToOptions } from "@/lookups/utils/transform";
import { PUBLISHED, SIGNED_OFF, Status } from "@/reports/config/status";
import FieldRenderer, {
  FieldWrapper,
  FormSubtitle,
  fullGridStyles,
} from "./utils";

type Props = {
  currentStatus: Status | undefined;
  disabled?: boolean;
};

const useFields = (currentStatus: Status | undefined) => {
  const { lookups } = useLookupStore();
  const { isViewer } = useRole();

  const testFields = useMemo<FormElement[]>(
    () => [
      createFormElement(FormSubtitle, {
        elementContainerCss: fullGridStyles,
        marginTop: "0",
        children: "Final Test Data",
        key: "Final Test Data",
      }),

      createFormElement(TextField, {
        label: "Set Pressure",
        name: "tSetPressure",
        type: "number",
        inputMode: "decimal",
      }),
      createFormElement(TextField, {
        label: "Back Pressure",
        name: "tBackPressure",
        type: "number",
        inputMode: "decimal",
      }),
      createFormElement(TextField, {
        label: "CDTP",
        name: "tCDTP",
        type: "number",
        inputMode: "decimal",
      }),
      createFormElement(TextField, {
        label: "Final Set Pressure",
        name: "tFinalSetPressure",
        type: "number",
        inputMode: "decimal",
      }),
      createFormElement(TextField, {
        label: "Simmer",
        name: "tSimmer",
        type: "number",
        inputMode: "decimal",
      }),
      createFormElement(TextField, {
        label: "BPM @ 90%",
        name: "tBPM",
      }),
      createFormElement(TextField, {
        label: "Back Press Test @",
        name: "tBackPressTest",
      }),
      createFormElement(TextField, {
        label: "Gauge",
        name: "tGauge",
      }),
      createFormElement(TextField, {
        label: "BD Ring Upper",
        name: "tBDRingUpper",
      }),
      createFormElement(TextField, {
        label: "BD Ring Lower",
        name: "tBDRingLower",
      }),
      createFormElement(DateField, {
        label: "Date Tested",
        name: "tDateTested",
      }),
      createFormElement(AutocompleteField, {
        label: "Test Media",
        name: "tTestMedia",
        options: transformRecordsToOptions(lookups.TestMedia),
        showSearch: true,
      }),
      createFormElement(AutocompleteField, {
        label: "Assembled By",
        name: "tAssembledBy",
        options: transformRecordsToOptions(lookups.Staff),
        showSearch: true,
      }),
      createFormElement(AutocompleteField, {
        label: "Tested By",
        name: "tTestedBy",
        options: transformRecordsToOptions(lookups.Staff),
        showSearch: true,
      }),
      createFormElement(AutocompleteField, {
        label: "Witnessed By",
        name: "tWitnessedBy",
        options: transformRecordsToOptions(lookups.Staff),
        showSearch: true,
      }),
      createFormElement(FormSubtitle, {
        elementContainerCss: fullGridStyles,
        marginTop: spacing.xl,
        children: "QC / Check List",
        key: "QC / Check List",
      }),
    ],
    [lookups]
  );
  const extraFields = useMemo<FormElement[]>(
    () => [
      ...(currentStatus === SIGNED_OFF ||
      currentStatus === PUBLISHED ||
      isViewer
        ? [
            createFormElement(TextField, {
              label: "Signed off by",
              name: "qSignOff",
              disabled: true,
            }),
          ]
        : []),
      createFormElement(TextField, {
        label: "Comments",
        name: "qComments",
        type: "textarea",
        rows: 6,
        elementContainerCss: css`
          grid-column: 1 / -1;
          // half of screen size
          @media (${queries.laptopAndUp}) {
            grid-column: 1 / 5;
          }
        `,
      }),
    ],
    [currentStatus, isViewer]
  );
  const checkList = useMemo<FormElement[]>(
    () => [
      createFormElement(CheckboxField, {
        label: "Painted",
        name: "qPainted",
      }),
      createFormElement(CheckboxField, {
        label: "Sealed",
        name: "qSealed",
      }),
      createFormElement(CheckboxField, {
        label: "Taped / Covered",
        name: "qTapedCovered",
      }),
      createFormElement(CheckboxField, {
        label: "Tagged / Checked",
        name: "qTaggedChecked",
      }),
      createFormElement(CheckboxField, {
        label: "Shipped Upright",
        name: "qShippedUpright",
      }),
      createFormElement(CheckboxField, {
        label: "Vent Holes Plugged",
        name: "qVentHolesPlugged",
      }),
      createFormElement(CheckboxField, {
        label: "Bellows Vented",
        name: "qBellowsVented",
      }),
    ],
    []
  );
  return useMemo(
    () => ({ checkList, extraFields, testFields }),
    [checkList, extraFields, testFields]
  );
};
const ChecklistWrapper = styled.div`
  display: flex;
  column-gap: 30px;
  flex-wrap: wrap;
  margin-top: ${spacing.sm};
  padding-block: ${spacing.xxs};
`;

const CheckboxContainer = styled.div`
  padding-inline: ${spacing.xxs};
  padding-block: ${spacing.xs};
`;

const FinalData = ({ currentStatus, disabled }: Props) => {
  const { testFields, checkList, extraFields } = useFields(currentStatus);
  return (
    <>
      <FieldWrapper>
        <FieldRenderer fields={testFields} disabled={disabled} />
      </FieldWrapper>
      <ChecklistWrapper>
        {checkList.map(({ Component, key, name, ...props }) => (
          <CheckboxContainer key={key || name}>
            <Component
              name={name}
              lineHeight="normal"
              disabled={disabled}
              {...props}
            />
          </CheckboxContainer>
        ))}
      </ChecklistWrapper>
      <Divider />
      <FieldWrapper>
        <FieldRenderer fields={extraFields} disabled={disabled} />
      </FieldWrapper>
    </>
  );
};

export default FinalData;
