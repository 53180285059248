/* eslint-disable import/no-cycle */
import { z } from "zod";
import { checkEmailExists } from "@/user-control/api";

export const nameRegexRule = /[a-zA-Z]/;

const CreateAccountCommonSchema = z.object({
  Name: z
    .string()
    .min(1)
    .refine((value) => nameRegexRule.test(value)),
  Email: z
    .string()
    .email()
    .superRefine(async (value, ctx) => {
      if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Invalid email",
          fatal: true,
        });
        return z.NEVER;
      }

      // Check if email exists
      const result = await checkEmailExists({ email: value });
      if (result.status === 200) {
        if (result.data.data.docs.length > 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "This Email is already related to an existing account.",
            fatal: true,
          });
          return z.NEVER;
        }
        return true;
      }
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Something went wrong, please try again later.",
        fatal: true,
      });
      return z.NEVER;
    }),
  Password: z
    .string()
    .min(8)
    .refine((value) => /[a-z]/g.test(value))
    .refine((value) => /[A-Z]/g.test(value))
    .refine((value) => /[0-9]/g.test(value)),
});

export const CreateTechnicianAccountSchema = z.intersection(
  CreateAccountCommonSchema,
  z.object({
    Location: z.string(),
  })
);

export type CreateTechnicianAccountType = z.infer<
  typeof CreateTechnicianAccountSchema
>;

export const CreateViewerAccountSchema = z.intersection(
  CreateAccountCommonSchema,
  z.object({
    Sites: z.array(z.number()),
  })
);

export type CreateViewerAccountType = z.infer<typeof CreateViewerAccountSchema>;

const EditAccountCommonSchema = z.object({
  UserId: z.number(),
  Name: z.string(),
  Email: z.string().email(),
  Password: z
    .string()
    .min(8)
    .refine((value) => /[a-z]/g.test(value))
    .refine((value) => /[A-Z]/g.test(value))
    .refine((value) => /[0-9]/g.test(value)),
  Status: z.string(),
});

export const EditTechnicianAccountSchema = z.intersection(
  EditAccountCommonSchema,
  z.object({
    Location: z.string(),
  })
);

export type EditTechnicianAccountType = z.infer<
  typeof EditTechnicianAccountSchema
>;

export const EditViewerAccountSchema = z.intersection(
  EditAccountCommonSchema,
  z.object({
    Sites: z.array(z.number()),
  })
);

export type EditViewerAccountType = z.infer<typeof EditViewerAccountSchema>;

export type SubmitCreateAccount = {
  Name: string;
  Email: string;
  Password: string;
  Role: string;
  Location?: string;
  Sites?: number[];
};

export type EditAccountType = {
  UserId: number;
  Role: string;
  Location?: string;
  Status?: string;
  Sites?: number[];
};

export type ChangePasswordType = {
  UserId: number;
  Password: string;
};
