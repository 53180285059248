import React, { useCallback } from "react";
import { Table } from "antd";
import { withErrorBoundary } from "react-error-boundary";
import FallbackComponent from "@/components/FallbackComponent";
import useNonPaginatedTable from "@/hooks/useNonPaginatedTable";
import { useGetSites } from "@/sites/api";
import { Site } from "@/sites/types/Site";
import tableColumns from "./columns";

type Props = {
  setSelectedSite: React.Dispatch<React.SetStateAction<Site | null>>;
  triggerOpen: () => void;
};

const SitesTable = ({ setSelectedSite, triggerOpen }: Props) => {
  const { isLoading, isError, error, data } = useGetSites();
  const { columns } = useNonPaginatedTable<Site>({
    columns: tableColumns,
  });
  const handleRowClick = useCallback(
    (row: Site) => ({
      style: { cursor: "pointer" },
      onClick: () => {
        setSelectedSite(row);
        triggerOpen();
      },
    }),
    [setSelectedSite, triggerOpen]
  );
  if (isError) throw error;
  return (
    <Table
      columns={columns}
      dataSource={data}
      loading={isLoading}
      pagination={false}
      rowKey={(row) => `${row.SiteId}-${row.SiteName}}`}
      onRow={handleRowClick}
    />
  );
};

export default withErrorBoundary(SitesTable, {
  FallbackComponent,
});
