import { Button, Tooltip, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { SafetyValveReport } from "@/reports/types/SafetyValveReport";
import { createDefaultReportValuesFromCopy } from "@/reports/utils/report";
import useNetwork from "@/stores/network";

type CopyReportButtonProps = {
  values: SafetyValveReport;
};

const CopyReportButton = ({ values }: CopyReportButtonProps) => {
  const { location } = useParams();
  const navigate = useNavigate();

  const { online } = useNetwork();

  return (
    <Tooltip title="Create a new report with autofill based on this report">
      <Button
        disabled={!online}
        onClick={() => {
          navigate("/reports/new", {
            state: {
              location,
              copyReportValues: createDefaultReportValuesFromCopy(values),
            },
          });
          message.success("Report copied");
        }}
      >
        Copy Report
      </Button>
    </Tooltip>
  );
};

export default CopyReportButton;
