import { useState } from "react";
import { spacing } from "@/config/theme";
import styled from "@emotion/styled";
import { Button, Input, Space } from "antd";
import { newLookupSchema } from "@/lookups/types/Lookup";
import useBoolean from "@/hooks/useBoolean";

const Root = styled.div`
  margin-block: ${spacing.xxs};
`;

const Wrapper = styled(Space.Compact)`
  width: 100%;
`;

type AddItemFieldProps = {
  onAdd: (value: string) => void;
};

const AddItemField = ({ onAdd }: AddItemFieldProps) => {
  const [newItem, setNewItem] = useState<string>("");

  // Default to true so that the user can't add an empty item when the drawer is first opened
  const { value: hasError, setValue: setHasError } = useBoolean(true);

  const handleAddClick = () => {
    if (newItem.trim().length > 0) {
      onAdd(newItem.trim());
      // Clean up the input field when the item is added
      setNewItem("");
    }

    // Default to true so that the user can't add an empty item every time they click the button
    setHasError(true);
  };

  return (
    <Root>
      <Wrapper>
        <Input
          value={newItem}
          onChange={(e) => {
            setNewItem(e.target.value);

            const validationResult = newLookupSchema.safeParse(e.target.value);
            setHasError(!validationResult.success);
          }}
          onPressEnter={!hasError ? handleAddClick : undefined}
          placeholder="New item"
        />
        <Button disabled={hasError} onClick={handleAddClick}>
          + Add
        </Button>
      </Wrapper>
    </Root>
  );
};

export default AddItemField;
