import { useMemo } from "react";
import TextField from "@/components/TextField";
import { createFormElement, FormElement } from "@/types/Form";
import AutocompleteField from "@/components/AutocompleteField";
import CheckboxField from "@/components/CheckboxField";
import DateField from "@/components/DateField";
import { spacing } from "@/config/theme";
import { transformRecordsToOptions } from "@/lookups/utils/transform";
import useLookupStore from "@/lookups/stores/lookups";
import FieldRenderer, {
  FieldWrapper,
  FormSubtitle,
  fullGridStyles,
} from "./utils";

type Props = {
  disabled?: boolean;
};

const useFields = () => {
  const { lookups } = useLookupStore();

  return useMemo<FormElement[]>(
    () => [
      createFormElement(AutocompleteField, {
        label: "Maintenance For",
        name: "rMaintenanceFor",
        options: transformRecordsToOptions(lookups.MaintenanceFor),
        showSearch: true,
      }),
      createFormElement(DateField, {
        label: "Date",
        name: "rDate",
      }),
      createFormElement(AutocompleteField, {
        label: "Received By",
        // TODO: Fix typo in database
        name: "rRecievedBy",
        options: transformRecordsToOptions(lookups.Staff),
        showSearch: true,
      }),
      createFormElement(TextField, {
        label: "Leaked",
        name: "rLeaked",
        inputMode: "decimal",
      }),
      createFormElement(TextField, {
        label: "Pre Popped",
        name: "rPrePopped",
        inputMode: "decimal",
      }),
      createFormElement(AutocompleteField, {
        label: "Pre Test Notes",
        name: "rPreTestNotes",
        options: transformRecordsToOptions(lookups.PreTestNotes),
      }),
      createFormElement(CheckboxField, {
        label: "Name Plate",
        name: "rNamePlate",
      }),
      createFormElement(TextField, {
        label: "Prev Repair",
        name: "rPrevRepairCompany",
      }),
      createFormElement(AutocompleteField, {
        label: "Dismb / Insp By",
        name: "rDismbInspBy",
        options: transformRecordsToOptions(lookups.Staff),
        showSearch: true,
      }),
      createFormElement(CheckboxField, {
        label: "Set Pressure Change",
        name: "rSetPressureChange",
      }),
      createFormElement(TextField, {
        label: "New Pressure",
        name: "rNewPressure",
        type: "number",
        inputMode: "decimal",
      }),
      createFormElement(TextField, {
        label: "New Cap",
        name: "rNewCap",
      }),
      createFormElement(FormSubtitle, {
        elementContainerCss: fullGridStyles,
        marginTop: spacing.xl,
        children: "Original Nameplate Info",
        key: "Original Nameplate Info",
      }),
      createFormElement(TextField, {
        label: "Set Pressure",
        name: "oSetPressure",
      }),
      createFormElement(TextField, {
        label: "Temperature",
        name: "oTemperature",
      }),
      createFormElement(TextField, {
        label: "Capacity",
        name: "oCapacity",
      }),
      createFormElement(TextField, {
        label: "Model Number",
        name: "oModelNumber",
      }),
      createFormElement(FormSubtitle, {
        elementContainerCss: fullGridStyles,
        marginTop: spacing.xl,
        children: "Previous Repair Nameplate",
        key: "Previous Repair Nameplate",
      }),
      createFormElement(TextField, {
        label: "Set Pressure",
        name: "pSetPressure",
      }),
      createFormElement(TextField, {
        label: "Capacity",
        name: "pCapacity",
      }),
    ],
    [lookups]
  );
};
const PreTestData = ({ disabled }: Props) => {
  const fields = useFields();
  return (
    <FieldWrapper>
      <FieldRenderer fields={fields} disabled={disabled} />
    </FieldWrapper>
  );
};

export default PreTestData;
