import { SerializedStyles } from "@emotion/react";
import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createFormElement = <T extends React.ComponentType<any>>(
  Component: T,
  props: React.ComponentProps<T> & {
    elementContainerCss?: SerializedStyles;
  }
): {
  Component: T;
} & React.ComponentProps<T> => {
  return { Component, name: props.name, ...props };
};

export type FormElement = ReturnType<typeof createFormElement>;
