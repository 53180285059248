import { PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { colors, spacing } from "@/config/theme";
import logo from "@/assets/images/valvalve-dark.svg";

const Container = styled.div`
  height: 100%;
  background-color: ${colors.daybreakBlue[100]};
  display: grid;
  place-items: center;
`;

const FormWrapper = styled.div`
  padding: ${spacing.xxl} ${spacing.xxxl};
  width: 455px;
  min-height: 500px;
  border-radius: ${spacing.xxs};
  background: ${colors.white};
`;

const Logo = styled.img`
  max-width: 100%;
  margin: ${spacing.xl} auto ${spacing.sm};
`;

const AuthLayout = ({ children }: PropsWithChildren) => (
  <Container>
    <FormWrapper>
      <Logo src={logo} alt="Vanvalve logo" />
      {children}
    </FormWrapper>
  </Container>
);

export default AuthLayout;
