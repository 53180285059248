/* eslint-disable prefer-template */
import { Role, TECHNICIAN } from "@/auth/config/roles";
import { displayRoleName } from "@/utils/display";

type Params = {
  accountName?: string;
  accountEmail?: string;
  accountPassword?: string;
  role: Role;
  adminName?: string | undefined;
  bodyContentOnly?: boolean;
};

const LOGIN_URL =
  process.env.NODE_ENV === "production"
    ? "https://app.vanvalve.com/login"
    : "https://dadrdprhkibl0.cloudfront.net/login";

export const generateCredentialsDraftEmailLink = ({
  accountName = "",
  accountEmail = "",
  accountPassword = "",
  role = TECHNICIAN,
  adminName = "Van Valve Admin",
  bodyContentOnly = false,
}: Params): string => {
  const body = `Hello, ${accountName}\r\n\r\nYour ${displayRoleName(
    role
  )} account with VanValve has been created.\r\nPlease log in to your account using the following credentials\n\rEmail: ${accountEmail}\r\nTemporary Password: ${accountPassword}\n\rAfter logging in you will be prompted to change your password to finish setting up your account.\r\nAccess the login page at ${LOGIN_URL}\r\n\r\n${adminName}\r\nVanValve\r\n1415 Santa Fe Road, V2N 5T5\r\nPrince George, BC\r\nVanValve Tel: (250) 561-8769`;
  return bodyContentOnly
    ? body
    : "mailto:" +
        encodeURIComponent(accountEmail) +
        "?subject=Welcome! Here are Your VanValve Account Credentials" +
        "&body=" +
        encodeURIComponent(body);
};
