import { Select as AntdSelect } from "antd";
import type { SelectProps } from "antd";
import React from "react";
import { useController } from "react-hook-form";
import { SerializedStyles } from "@emotion/react";
import FieldWrapper from "@/components/FieldWrapper";
import { ErrorMessage } from "@hookform/error-message";
import FieldError from "../FieldError";
import RequiredIndicator from "../RequiredIndicator";
import NotFoundContent from "../NotFoundContent";

export type SelectFieldProps = {
  name: string;
  label: React.ReactNode;
  css?: SerializedStyles;
  required?: boolean;
} & SelectProps;

const SelectField = ({ name, label, css, ...props }: SelectFieldProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
  });

  return (
    <FieldWrapper css={css}>
      <label htmlFor={name}>
        <RequiredIndicator show={props.required} />
        {label}
      </label>
      <AntdSelect
        placeholder={label}
        id={name}
        status={error ? "error" : undefined}
        {...field}
        {...props}
        notFoundContent={<NotFoundContent />}
      />
      <ErrorMessage name={name} as={FieldError} />
    </FieldWrapper>
  );
};

export default SelectField;
