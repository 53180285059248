import styled from "@emotion/styled";
import { colors, font, spacing } from "@/config/theme";
import { css, SerializedStyles } from "@emotion/react";

type MessageType = "default" | "success" | "info" | "warning";
const MessageTypeStyles: Record<MessageType, SerializedStyles> = {
  default: css`
    color: ${colors.gray[800]};
  `,
  info: css`
    color: ${colors.daybreakBlue[600]};
  `,
  success: css`
    color: ${colors.polarGreen[800]};
  `,
  warning: css`
    color: ${colors.dustRed[700]};
  `,
};
interface MessageProps {
  type: MessageType;
}

const NetworkMessage = styled.div<MessageProps>`
  display: flex;
  align-items: center;
  gap: ${spacing.xxs};
  font-size: ${font.size[12]};
  font-weight: 600;
  line-height: 1.66;
  ${(props) => MessageTypeStyles[props.type]}
`;
export default NetworkMessage;
