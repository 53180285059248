import styled from "@emotion/styled";
import { colors, spacing } from "@/config/theme";

const NotFoundMessage = styled.span`
  padding: ${spacing.xxs};
  color: ${colors.gray[700]};
`;

const NotFoundContent = () => {
  return <NotFoundMessage>No results found</NotFoundMessage>;
};

export default NotFoundContent;
