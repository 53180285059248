import { spacing } from "@/config/theme";
import styled from "@emotion/styled";

const PageContent = styled.div`
  padding: var(--main-padding);
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: ${spacing.xs};
  padding-bottom: ${spacing.xxxs};
`;
export default PageContent;
