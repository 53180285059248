import { CustomColumns } from "@/types/Table";
import { UserInfo } from "@/user-control/types/UserInfo";
import { UserStatus } from "@/user-control/types/UserStatus";
import { Location } from "@/reports/config/locations";
import { displayLocationName } from "@/utils/display";
import { ADMIN, Role, TECHNICIAN, VIEWER } from "@/auth/config/roles";
import { Site } from "@/user-control/types/Site";
import StatusTag from "@/user-control/components/StatusTag";

const commonColumns: CustomColumns<UserInfo> = [
  {
    title: "Full Name",
    dataIndex: "Name",
    key: "Name",
    sorter: (a, b) => a.Name.localeCompare(b.Name),
    defaultSortOrder: "ascend",
  },
  {
    title: "Email",
    dataIndex: "Email",
    key: "Email",
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    render: (value: UserStatus) => <StatusTag status={value} />,
  },
];

const adminColumns: CustomColumns<UserInfo> = [...commonColumns];

const technicianColumns: CustomColumns<UserInfo> = [
  ...commonColumns,
  {
    title: "Current Location",
    dataIndex: "Location",
    key: "Location",
    render: (value: Location) => displayLocationName(value),
  },
];

// Get common columns and add Site column to viewer columns
const viewerColumns = [...commonColumns];

viewerColumns.splice(2, 0, {
  title: "Site",
  dataIndex: "Sites",
  key: "Sites",
  render: (value: Site[]) =>
    value
      // Only show sites that are active
      .filter((site) => site.Show)
      .map((site) => site.SiteName)
      .join(", "),
});

const displayColumnsBaseOnRole = (role: Role) => {
  switch (role) {
    case ADMIN:
      return adminColumns;
    case TECHNICIAN:
      return technicianColumns;
    case VIEWER:
      return viewerColumns;
    default:
      return commonColumns;
  }
};

export { displayColumnsBaseOnRole };
