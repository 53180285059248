import axios from "@/lib/axios";
import { AxiosResponse } from "axios";
import { message } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { LOOKUPS_API } from "@/lookups/config/api";
import { AllLookupsResponse } from "@/lookups/types/Api";
import { LookupRecord, UpdatedLookupRecord } from "@/lookups/types/Lookup";

const getAllLookups = () => axios.get<AllLookupsResponse>("/api/lookup");

export const useGetAllLookups = () =>
  useQuery({
    queryFn: async () => getAllLookups(),
    queryKey: [LOOKUPS_API, "GET /"],
    select: (response) => response.data,
  });

const addNewLookups = async (
  newLookups: LookupRecord[]
): Promise<AxiosResponse<void>> => {
  const formattedLookups = newLookups.map((lookup) => ({
    FieldName: lookup.FieldName,
    Lookup: lookup.Lookup,
    Show: true,
    Sort: 1,
  }));

  return axios.post("/api/lookup", formattedLookups);
};

type useAddNewLookupsProps = {
  onSuccessFn: () => void;
};

export const useAddNewLookups = ({ onSuccessFn }: useAddNewLookupsProps) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addNewLookups,
    onSuccess: () => {
      queryClient.invalidateQueries([LOOKUPS_API]);
      message.success("New lookup(s) added");
      onSuccessFn();
    },
    onError: () => {
      message.error("Error adding new lookup, please try again.");
    },
  });
};

const updateLookups = async (
  updatedLookups: UpdatedLookupRecord[]
): Promise<AxiosResponse<void>> => axios.put("/api/lookup", updatedLookups);

type useUpdateLookupsProps = {
  onSuccessFn: () => void;
};

export const useUpdateLookups = ({ onSuccessFn }: useUpdateLookupsProps) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateLookups,
    onSuccess: () => {
      queryClient.invalidateQueries([LOOKUPS_API]);
      message.success("Lookup saved");
      onSuccessFn();
    },
    onError: () => {
      message.error("Error saving lookup, please try again.");
    },
  });
};
