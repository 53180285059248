import { customAlphabet } from "nanoid";

/**
 * Utility function to generate an unique alphabet id with a specified length
 * @param length required length of the id, default is 5
 * @returns unique id of the specified length
 */
export const shortId = (length = 5) => {
  const nanoid = customAlphabet(
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
    length
  );
  return nanoid();
};
