import useNetwork from "@/stores/network";
import { PropsWithChildren } from "react";

const Offline = ({ children }: PropsWithChildren) => {
  const { online } = useNetwork();
  if (online) return null;
  return <>{children}</>;
};

export default Offline;
