import useNetwork from "@/stores/network";
import styled from "@emotion/styled";
import { Button, Drawer, Space as AntdSpace, Tooltip, message } from "antd";
import ChangePasswordForm from "@/profile/components/ChangePasswordForm";
import { useChangePassword } from "@/profile/api";
import { ChangePasswordType } from "@/profile/types/ChangePassword";
import { useCallback } from "react";
import { AxiosError } from "axios";
import useBoolean from "@/hooks/useBoolean";

const Space = styled(AntdSpace)`
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  visible: boolean;
  closeDrawer: () => void;
};

const ChangePasswordDrawer = ({ visible, closeDrawer }: Props) => {
  const { online } = useNetwork();

  const { value: shouldDisable, setValue: changeSubmitBtnDisabledState } =
    useBoolean(true);

  const { mutateAsync: changePassword, isLoading } = useChangePassword({
    onSuccessFn: () => {
      message.success("Profile updated successfully");
      closeDrawer();
    },
  });

  const submitUpdateProfile = useCallback(
    (submitData: ChangePasswordType) => {
      changePassword({
        OldPassword: submitData.CurrentPassword,
        NewPassword: submitData.NewPassword,
        VerifyPassword: submitData.NewPassword,
      }).catch((apiError: AxiosError<{ error: string | undefined }>) => {
        if (typeof apiError.response?.data.error === "string") {
          message.error(apiError.response?.data.error);
        } else {
          message.error("Profile update failed, please try again");
        }
      });
    },
    [changePassword]
  );

  return (
    <Drawer
      title="Change Password"
      placement="right"
      closeIcon={null}
      destroyOnClose
      maskClosable={false}
      open={visible}
      footer={
        <Space>
          <Button type="default" onClick={closeDrawer}>
            Cancel
          </Button>
          <Tooltip title={online ? "" : "Currently offline"}>
            <Button
              disabled={shouldDisable || !online || isLoading}
              type="primary"
              htmlType="submit"
              form="change-password-form"
            >
              Save
            </Button>
          </Tooltip>
        </Space>
      }
    >
      <ChangePasswordForm
        onSubmit={submitUpdateProfile}
        changeSubmitBtnDisabledState={changeSubmitBtnDisabledState}
      />
    </Drawer>
  );
};

export default ChangePasswordDrawer;
