import { SafetyValveReport } from "@/reports/types/SafetyValveReport";
import { Alert, Button, Modal, Space, Spin, message } from "antd";
import useBoolean from "@/hooks/useBoolean";
import styled from "@emotion/styled";
import { colors } from "@/config/theme";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Location } from "@/reports/config/locations";
import { PUBLISHED } from "@/reports/config/status";
import useBulkChangeReportStatus from "@/reports/hooks/useBulkChangeReportStatus";

const Title = styled.b`
  color: ${colors.daybreakBlue[100]};
`;

const ModalIcon = styled(ExclamationCircleOutlined)`
  color: ${colors.yellow[600]} !important;
`;

type BulkExportBannerProps = {
  selectedRows: SafetyValveReport[];
  setSelectedRowKeys: (keys: string[]) => void;
  location: Location | undefined;
};

const BulkChangeStatusBanner = ({
  selectedRows,
  setSelectedRowKeys,
  location,
}: BulkExportBannerProps) => {
  const { changeStatus } = useBulkChangeReportStatus();

  const { value: spinning, setValue: setSpinning } = useBoolean(false);

  return (
    <>
      <Alert
        message={<Title>Change report status</Title>}
        type="info"
        action={
          <Space>
            <Button type="default" onClick={() => setSelectedRowKeys([])}>
              Deselect All
            </Button>
            <Button
              type="primary"
              onClick={() => {
                Modal.info({
                  className: "antd-custom-warning-modal",
                  icon: <ModalIcon />,
                  title: "Change report status to Published",
                  okCancel: true,
                  content:
                    "Are you sure you want to change the status of all selected reports to Published?",
                  okText: "Change Status",
                  onOk: async () => {
                    setSpinning(true);

                    const succeed = await changeStatus(
                      selectedRows,
                      PUBLISHED,
                      location
                    );
                    setSpinning(false);
                    setSelectedRowKeys([]);
                    if (succeed) {
                      message.success("Report statuses successfully changed");
                    } else {
                      message.error(
                        "Failed to change report status, please try again later"
                      );
                    }
                  },
                });
              }}
            >
              Change status to Published
            </Button>
          </Space>
        }
      />
      <Spin spinning={spinning} fullscreen />
    </>
  );
};

export default BulkChangeStatusBanner;
