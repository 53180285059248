import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import axios from "@/lib/axios";
import { UpsertManyReportsResponse } from "../types/Api";
import { REPORTS_API } from "../config/api";

export const upsertManyReports = async (
  formData: FormData
): Promise<AxiosResponse<UpsertManyReportsResponse>> =>
  axios.put("/api/safetyValveReport", formData);

export const useUpsertManyReports = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: upsertManyReports,
    onSettled: () => {
      queryClient.invalidateQueries([REPORTS_API]);
    },
  });
};

type UploadAttachmentsParams = {
  location: string;
  reportId: number;
  formData: FormData;
};

export const uploadAttachments = async ({
  location,
  reportId,
  formData,
}: UploadAttachmentsParams): Promise<AxiosResponse> =>
  axios.post(
    `/api/safetyValveReport/${location}/${reportId}/attachment`,
    formData
  );

type DuplicateAndArchiveReportParams = {
  location: string;
  reportId: number;
};

export const duplicateAndArchiveReport = async ({
  location,
  reportId,
}: DuplicateAndArchiveReportParams) =>
  axios.post(`/api/safetyValveReport/${location}/${reportId}/w-archive`);
