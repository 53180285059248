import CheckboxDropdown from "@/components/CheckboxDropdown";
import SearchDropdown from "@/components/SearchDropdown";
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGINATION_SETTINGS,
} from "@/config/pagination";
import { Condition } from "@/types/Api";
import { CustomColumn, CustomColumns, SearchHandler } from "@/types/Table";
import { SearchOutlined } from "@ant-design/icons";
import { InputRef, TablePaginationConfig } from "antd";
import {
  FilterConfirmProps,
  FilterDropdownProps,
  FilterValue,
  SorterResult,
} from "antd/es/table/interface";
import React, { useCallback, useMemo, useRef, useState } from "react";

const paginationSettings = DEFAULT_PAGINATION_SETTINGS;

type Config<T> = {
  initialPage?: number;
  initialFilters?: Condition[];
  initialSorter?: SorterResult<T> | undefined;
  columns: CustomColumns<T>;
};

const getSearchColumnProps = <T,>(
  column: CustomColumn<T>,
  searchInput: React.RefObject<InputRef>,
  handleSearch: SearchHandler<T>,
  existingFilter?: Condition
): Partial<CustomColumn<T>> => {
  return {
    filtered: existingFilter !== undefined,
    filterDropdown: (props) => (
      <SearchDropdown<T>
        {...props}
        column={column}
        searchInputRef={searchInput}
        handleSearch={handleSearch}
        initialValue={existingFilter?.value || ""}
      />
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          // Notes: The reason we put focus event is because it will trigger the virtual keyboard on tablet when the user clicks on the search icon
          searchInput.current?.focus();
          searchInput.current?.select();
        }, 100);
      }
    },
  };
};

const getCheckboxColumnProps = <T,>(
  column: CustomColumn<T>,
  onApplyFilters: (values: string[]) => void,
  onClearFilters: () => void,
  existingFilters?: Condition[]
): Partial<CustomColumn<T>> => {
  const existingFilter =
    existingFilters?.filter((item) => item.column === column.dataIndex) ?? [];

  return {
    ...column,
    filtered: existingFilter.length > 0,
    filterDropdown: (props: FilterDropdownProps) => {
      return (
        <CheckboxDropdown
          options={column.filters}
          confirm={props.confirm}
          defaultCheckedValues={existingFilter.map((item) => item.value)}
          onClearFilters={onClearFilters}
          onApplyFilters={onApplyFilters}
        />
      );
    },
  };
};

const getSortedColumn = <T,>(
  column: CustomColumn<T>,
  initialSorter: SorterResult<T> | undefined
): CustomColumn<T> => {
  if (initialSorter && initialSorter.columnKey === column.dataIndex) {
    return {
      ...column,
      ...initialSorter,
      defaultSortOrder: initialSorter.order,
    };
  }

  return column;
};

const usePaginatedTable = <T,>({
  initialPage = 1,
  columns,
  initialFilters,
  initialSorter,
}: Config<T>) => {
  const searchInput = useRef<InputRef>(null);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: initialPage,
    pageSize: DEFAULT_LIMIT,
  });
  const [filters, setFilters] = useState<Condition[]>(initialFilters || []);

  // we'll only support one sorter at a time
  const [sorter, setSorter] = useState<SorterResult<T> | undefined>(
    initialSorter
  );

  const onChange = useCallback(
    (
      { current: currentPage, pageSize }: TablePaginationConfig,
      _filters: Record<string, FilterValue | null>,
      sortObject: SorterResult<T> | SorterResult<T>[]
    ) => {
      setPagination({
        current: currentPage,
        pageSize,
      });
      const sort = Array.isArray(sortObject) ? sortObject[0] : sortObject;
      setSorter(sort);
    },
    []
  );
  const handleTextSearch = useCallback(
    (
      selectedKeys: string[],
      confirm: (param?: FilterConfirmProps) => void,
      column: CustomColumn<T>
    ) => {
      const [search] = selectedKeys;
      setFilters((state) => {
        const clone = [...state];
        const columnIndex = clone.findIndex(
          (condition) => condition.column === column.dataIndex
        );
        const exists = columnIndex >= 0;

        const condition: Condition = {
          column: column.dataIndex,
          operator: "LIKE",
          value: (search || "").trim(),
        };
        if (exists) {
          clone[columnIndex] = condition;
        } else {
          clone.push(condition);
        }
        const validConditions = clone.filter(({ value }) => !!value);
        return validConditions;
      });

      confirm({ closeDropdown: false });
    },
    []
  );
  const columnsWithFilters = useMemo(() => {
    return columns.map((column) => {
      const { filterType } = column;
      if (filterType === "search") {
        const filteredColumn = {
          ...column,
          ...getSearchColumnProps(
            column,
            searchInput,
            handleTextSearch,
            filters.find((condition) => condition.column === column.dataIndex)
          ),
        };

        const sortedColumn = getSortedColumn(filteredColumn, initialSorter);

        return sortedColumn;
      }

      if (filterType === "checkbox") {
        const applyCheckboxFilters = (values: string[]) => {
          setPagination({
            ...pagination,
            current: 1,
          });
          setFilters((state) => {
            const clone = [...state];

            // remove existing filter for the column
            const filteredClone = clone.filter(
              (condition) => condition.column !== column.dataIndex
            );

            values.forEach((value) => {
              const condition: Condition = {
                column: column.dataIndex,
                operator: "IN",
                value,
              };
              filteredClone.push(condition);
            });

            const validConditions = filteredClone.filter(
              ({ value }) => !!value
            );
            return validConditions;
          });
        };

        const clearCheckboxFilters = () => {
          setPagination({
            ...pagination,
            current: 1,
          });
          setFilters((state) => {
            const clone = [...state];
            return clone.filter(
              (condition) => condition.column !== column.dataIndex
            );
          });
        };

        return getCheckboxColumnProps(
          column,
          applyCheckboxFilters,
          clearCheckboxFilters,
          filters
        );
      }

      if (initialSorter) {
        if (initialSorter.columnKey === column.dataIndex) {
          const sortedColumn = {
            ...column,
            ...initialSorter,
            defaultSortOrder: initialSorter.order,
          };

          return sortedColumn;
        }

        return { ...column, defaultSortOrder: undefined };
      }

      return column;
    });
  }, [columns, handleTextSearch, filters, pagination, initialSorter]);

  return useMemo(
    () => ({
      pagination: {
        ...paginationSettings,
        ...pagination,
      },
      setPagination,
      onChange,
      columns: columnsWithFilters,
      filters,
      setFilters,
      sorter,
      setSorter,
    }),
    [
      pagination,
      setPagination,
      onChange,
      columnsWithFilters,
      filters,
      setFilters,
      sorter,
      setSorter,
    ]
  );
};
export default usePaginatedTable;
