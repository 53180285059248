import {
  FileTextOutlined,
  UnlockFilled,
  UserOutlined,
  LogoutOutlined,
  EyeOutlined,
  ClusterOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { ADMIN, VIEWER, Role, TECHNICIAN } from "@/auth/config/roles";
import React, { useMemo } from "react";
import useRole from "@/auth/hooks/useRole";
import useNetwork from "@/stores/network";
import { NavLink } from "react-router-dom";

type Position = "top" | "bottom";
type MenuItem = Required<MenuProps>["items"][number] & {
  roles: Role[];
  position: Position;
  to: string;
  onClick?: () => void;
};

const createItem = ({
  label,
  key,
  roles,
  icon,
  children,
  position,
  to,
  onClick,
  title = "",
}: {
  label: React.ReactNode;
  key: React.Key;
  roles: Role[];
  icon?: React.ReactNode;
  children?: MenuItem[];
  position?: Position;
  to: string;
  onClick?: () => void;
  // Notes: Title value is for tooltip when the menu collapses. And we don't want to show the tooltip, so we set it to empty string by default.
  title?: string;
}): MenuItem => {
  return {
    key,
    icon,
    children,
    label: (
      <NavLink to={to} className={({ isActive }) => (isActive ? "active" : "")}>
        {label}
      </NavLink>
    ),
    roles,
    position: position || "top",
    to,
    onClick,
    title,
  } as MenuItem;
};

const useNavigationItems = () => {
  const { role } = useRole();
  const { online } = useNetwork();

  const items: MenuItem[] = useMemo(
    () => [
      createItem({
        label: "Safety Valve Data Report",
        key: "reports",
        icon: <FileTextOutlined />,
        roles: [ADMIN, TECHNICIAN, VIEWER],
        to: "/",
      }),
      ...(online
        ? [
            createItem({
              label: "Lookups",
              key: "lookups",
              icon: <EyeOutlined />,
              roles: [ADMIN],
              to: "/lookups",
            }),
            createItem({
              label: "User Management",
              key: "user-management",
              to: "/user-management",
              icon: <UnlockFilled />,
              roles: [ADMIN],
            }),
            createItem({
              label: "Sites",
              key: "sites",
              to: "/sites",
              icon: <ClusterOutlined />,
              roles: [ADMIN],
            }),
            createItem({
              label: "My Profile",
              key: "profile-top",
              to: "/profile",
              icon: <UserOutlined />,
              roles: [TECHNICIAN, VIEWER],
              position: "top",
            }),
            createItem({
              label: "My Profile",
              key: "profile-bottom",
              to: "/profile",
              icon: <UserOutlined />,
              roles: [ADMIN],
              position: "bottom",
            }),
            createItem({
              label: "Log Out",
              key: "logout",
              icon: <LogoutOutlined />,
              roles: [ADMIN, TECHNICIAN, VIEWER],
              position: "bottom",
              to: "/logout",
            }),
          ]
        : []),
    ],
    [online]
  );
  const navItems = useMemo(
    () =>
      items.filter((item) => {
        return role && item.roles.includes(role);
      }),
    [role, items]
  );
  return navItems;
};
export default useNavigationItems;
