import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "@/auth/stores/user";
import { useQueryClient } from "@tanstack/react-query";
import { usePreferenceStore } from "../stores/preference";

const Logout = () => {
  const navigate = useNavigate();

  const logout = useUserStore((state) => state.clearUser);
  const { clearAllPreferences } = usePreferenceStore();
  const queryClient = useQueryClient();

  useEffect(() => {
    // Clear user data
    logout();
    clearAllPreferences();
    queryClient.clear();
    window.localStorage.clear();
    // Navigate to login page
    navigate("/login");
  }, [navigate, logout, queryClient, clearAllPreferences]);

  return null;
};

export default Logout;
