import styled from "@emotion/styled";

const Page = styled.section`
  margin: calc(var(--main-padding) * -1);
  height: calc(100% + var(--main-padding) * 2);
  display: flex;
  flex-direction: column;
  position: relative;
`;
export default Page;
