import { useState } from "react";
import {
  Attachment,
  SafetyValveReport,
} from "@/reports/types/SafetyValveReport";
import { Button, message } from "antd";
import useNetwork from "@/stores/network";
import useBoolean from "@/hooks/useBoolean";
import { useParams } from "react-router-dom";
import useRole from "@/auth/hooks/useRole";
import { getSafetyValveReport } from "@/reports/api";
import { getImagesOrientations } from "@/reports/utils/pdf";
import { Location } from "@/reports/config/locations";
import PdfModal from "./PdfModal";
import ImageAttachmentWarningModal from "./ImageAttachmentWarningModal";

const ExportPdfButton = () => {
  const { online } = useNetwork();
  const { isViewer } = useRole();
  const { location, id } = useParams();

  const {
    value: showPdfModal,
    setTrue: setPdfModalOpen,
    setFalse: setPdfModalClose,
  } = useBoolean(false);
  const {
    value: isLoadingLiveData,
    setTrue: setIsLoadingLiveData,
    setFalse: setIsNotLoadingLiveData,
  } = useBoolean(false);

  const [liveData, setLiveData] = useState<SafetyValveReport | undefined>(
    undefined
  );

  const { value: showWarningModal, setValue: setShowWarningModal } =
    useBoolean(false);

  const [imageAttachments, setImageAttachments] = useState<Attachment[]>([]);
  const [imageOrientations, setImageOrientations] = useState<string[]>([]);

  const handleExportButtonClick = async () => {
    setShowWarningModal(true);
  };

  const handleExport = async (includeImage: boolean) => {
    setShowWarningModal(false);
    if (location && id) {
      setIsLoadingLiveData();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const report: any = await getSafetyValveReport(
        {
          onServer: true,
          value: id,
        },
        location as Location,
        isViewer
      );

      const reportData: SafetyValveReport = isViewer
        ? report.data
        : report.data.data;

      let images = reportData.Attachments?.filter((item) =>
        item.FileType.includes("image")
      );

      if (images === undefined) images = [];

      // If the user wants to include image attachments
      if (includeImage) {
        // Get image orientations
        const orientations = await getImagesOrientations(images);
        setImageOrientations(orientations);
        setImageAttachments(images);
      } else {
        setImageOrientations([]);
        setImageAttachments([]);
      }

      setLiveData(isViewer ? report.data : report.data.data);
      setIsNotLoadingLiveData();
      setPdfModalOpen();
    } else {
      message.error("Invalid location or report id, cannot export pdf");
    }
  };

  return (
    <>
      <Button
        key="export"
        type="primary"
        onClick={handleExportButtonClick}
        disabled={!online}
        loading={isLoadingLiveData}
      >
        Export PDF
      </Button>
      <ImageAttachmentWarningModal
        visible={showWarningModal}
        onCancel={() => setShowWarningModal(false)}
        onExport={handleExport}
      />
      <PdfModal
        data={liveData}
        location={location}
        visible={showPdfModal}
        onClose={() => setPdfModalClose()}
        imageAttachments={imageAttachments}
        imageOrientations={imageOrientations}
      />
    </>
  );
};

export default ExportPdfButton;
