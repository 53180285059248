import React from "react";
import useDownloadLookups from "@/reports/hooks/useDownloadLookups";
import useDownloadSites from "@/reports/hooks/useDownloadSites";
import SyncOfflineReportModal from "@/reports/components/SyncOfflineReportModal";

type Props = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const SetupReports = ({ setIsLoading }: Props) => {
  useDownloadLookups();
  useDownloadSites();
  return <SyncOfflineReportModal setIsLoading={setIsLoading} />;
};

export default SetupReports;
