import { useCallback, useMemo } from "react";
import { UpdateSiteType } from "@/sites/types/Site";
import { useUpdateSite } from "@/sites/api";

const useSubmitSiteUpdate = () => {
  const { mutateAsync: update, isLoading, isError, error } = useUpdateSite();
  const save = useCallback(
    async (values: UpdateSiteType) => {
      await update(values);
    },
    [update]
  );
  return useMemo(
    () => ({ save, isLoading, isError, error }),
    [save, isLoading, isError, error]
  );
};

export default useSubmitSiteUpdate;
