import styled from "@emotion/styled";
import { Tabs, TabsProps } from "antd";

const TabsWrapper = styled.div`
  .ant-tabs-nav {
    margin: 0;
  }
`;

const PageTabs = (props: TabsProps) => {
  return (
    <TabsWrapper>
      <Tabs {...props} />
    </TabsWrapper>
  );
};

export default PageTabs;
