/* eslint-disable no-param-reassign */
import { LookupDictionary, LookupRecord } from "@/lookups/types/Lookup";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface LookupState {
  lookups: LookupDictionary;
  setAll: (lookups: LookupRecord[]) => void;
}

const useLookupStore = create<LookupState>()(
  persist(
    (set) => ({
      lookups: {},
      setAll: (lookups) =>
        set((state) => ({
          ...state,
          lookups: lookups.reduce<LookupDictionary>((lookupMap, current) => {
            if (!lookupMap[current.FieldName]) {
              lookupMap[current.FieldName] = [];
            }
            // Only add to lookup map if Show is true
            if (current.Show) {
              lookupMap[current.FieldName]?.push(current);
            }
            return lookupMap;
          }, {}),
        })),
    }),
    {
      name: "lookup-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
export default useLookupStore;
