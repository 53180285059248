/* eslint-disable import/no-cycle */
import {
  Condition,
  PaginatedResponse,
  ServerPaginatedResponse,
} from "@/types/Api";
import axios from "@/lib/axios";
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { USERS } from "@/user-control/config/api";
import { UserInfo } from "@/user-control/types/UserInfo";
import { AxiosResponse } from "axios";
import {
  ChangePasswordType,
  EditAccountType,
  SubmitCreateAccount,
} from "@/user-control/types/Account";
import { message } from "antd";

interface GetUserParams {
  page?: number;
  limit?: number;
  search?: Condition[];
}

const getUsers = async (
  params: GetUserParams
): Promise<ServerPaginatedResponse<UserInfo>> =>
  axios.get("/api/user", {
    params: {
      ...params,
      search: params.search ? JSON.stringify(params.search) : undefined,
    },
  });

export const useGetUsers = (
  params: GetUserParams,
  options?: UseQueryOptions<
    ServerPaginatedResponse<UserInfo>,
    Error,
    PaginatedResponse<UserInfo>
  >
) => {
  return useQuery({
    queryFn: async () => getUsers(params),
    queryKey: [USERS, "GET /", params, params.search],
    select: (axiosResponse) => {
      return axiosResponse.data.data;
    },
    ...options,
  });
};

const createAccount = async (
  values: SubmitCreateAccount
): Promise<AxiosResponse<void>> => axios.post("/api/user", values);

type useCreateAccountProps = {
  onSuccessFn: (values: SubmitCreateAccount) => void;
};

export const useCreateAccount = ({ onSuccessFn }: useCreateAccountProps) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createAccount,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([USERS]);
      onSuccessFn(variables);
    },
  });
};

export const checkEmailExists = async ({
  email,
}: {
  email: string;
}): Promise<ServerPaginatedResponse<UserInfo>> =>
  axios.get("/api/user", {
    params: {
      page: 1,
      limit: 1,
      search: JSON.stringify([
        { column: "Email", operator: "EQUALS", value: email },
      ]),
    },
  });

const editAccount = async (
  values: EditAccountType
): Promise<AxiosResponse<void>> => axios.put("/api/user", values);

export const useEditAccount = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: editAccount,
    onSuccess: () => {
      queryClient.invalidateQueries([USERS]);
      message.success("Account saved");
    },
  });
};

const changeAccountPassword = async (
  values: ChangePasswordType
): Promise<AxiosResponse<void>> =>
  axios.post("/api/user/changePassword", values);

type useChangeAccountPasswordProps = {
  onSuccessFn: (newPassword: string) => void;
};

export const useChangeAccountPassword = ({
  onSuccessFn,
}: useChangeAccountPasswordProps) => {
  return useMutation({
    mutationFn: changeAccountPassword,
    onSuccess(_, variables) {
      onSuccessFn(variables.Password);
    },
  });
};
