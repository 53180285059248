import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import {
  Condition,
  PaginatedResponse,
  ServerPaginatedResponse,
} from "@/types/Api";
import { ReportId, SafetyValveReport } from "@/reports/types/SafetyValveReport";
import axios from "@/lib/axios";
import { Location } from "@/reports/config/locations";
import { SorterResult } from "antd/es/table/interface";
import { DIRECTIONS } from "@/constants/directions";
import { REPORTS_API } from "@/reports/config/api";
import { AxiosResponse } from "axios";
import { getASME } from "@/reports/utils/asme";

interface SafetyValveReportParams {
  page?: number;
  limit?: number;
  search?: Condition[];
  location?: Location;
  sorter?: SorterResult<SafetyValveReport>;
}

export const getSafetyValveReports = async (
  params: SafetyValveReportParams,
  isViewer?: boolean
): Promise<ServerPaginatedResponse<SafetyValveReport>> => {
  const defaultSorter = params?.sorter?.order
    ? {
        direction: DIRECTIONS[params.sorter.order],
        column: params.sorter.columnKey,
      }
    : {
        direction: DIRECTIONS.DESC,
        column: "updatedAt",
      };

  return axios.get(
    `${isViewer ? "/api/viewer/reports" : "/api/safetyValveReport"}`,
    {
      params: {
        ...params,
        search: params.search ? JSON.stringify(params.search) : undefined,
        sort: JSON.stringify(defaultSorter),
      },
    }
  );
};

export const useGetSafetyValveReports = (
  params: SafetyValveReportParams,
  isViewer?: boolean,
  options?: UseQueryOptions<
    ServerPaginatedResponse<SafetyValveReport>,
    Error,
    PaginatedResponse<SafetyValveReport>
  >
) =>
  useQuery({
    queryFn: async () => getSafetyValveReports(params, isViewer),
    queryKey: [REPORTS_API, "GET /", params, params.search, isViewer],
    select: (axiosResponse) => {
      return axiosResponse.data.data;
    },
    ...options,
  });

export const getSafetyValveReport = async (
  id: ReportId,
  location: Location,
  isViewer?: boolean
): Promise<AxiosResponse<{ data: SafetyValveReport }>> => {
  if (isViewer) {
    return axios.get(`/api/viewer/reports/${location}/${id.value}`);
  }
  return axios.get(`/api/safetyValveReport/${id.value}`, {
    params: { location },
  });
};

export const useGetSafetyValveReport = (
  id: ReportId,
  location: Location,
  isViewer?: boolean
) =>
  useQuery({
    queryFn: async () => getSafetyValveReport(id, location, isViewer),
    queryKey: [REPORTS_API, "GET /:id", id, location, isViewer],
    select: (response) => {
      // TODO: Address this "any" type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const report: any = isViewer ? response.data : response.data.data;
      report.ASME = getASME(report);

      // Provide default values for these fields that used in Attachment tab
      report.NewAddedAttachments = [];
      report.DeletedAttachments = [];
      return report;
    },
  });
