import dayjs from "dayjs";
import { Key } from "react";
import { SafetyValveReport } from "@/reports/types/SafetyValveReport";
import { CustomColumn, CustomColumns } from "@/types/Table";
import styled from "@emotion/styled";
import { isTablet } from "@/utils/deviceCheck";
import { handleFilter } from "@/utils/table";
import notSyncedIcon from "@/assets/icons/not-syncedBase64String";
import {
  ALL,
  FOR_REVIEW,
  IN_PROGRESS,
  PUBLISHED,
  SIGNED_OFF,
  STATUS_OPTIONS,
  Status,
  WAREHOUSE,
  W_ARCHIVE,
} from "@/reports/config/status";

const Span = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NotSyncedImagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotSyncedImage = styled.img`
  width: 25px;
`;

const VV_NUMBER: CustomColumn<SafetyValveReport> = {
  title: "VV #",
  dataIndex: "vVVNumber",
  key: "vVVNumber",
  filterType: "search",
  width: "18%",
  sorter: true,
  sortDirections: ["descend", "ascend"],
  className: "vv-number-column",
};

const WO_NUMBER: CustomColumn<SafetyValveReport> = {
  title: "WO #",
  dataIndex: "vWONumber",
  key: "vWONumber",
  filterType: "search",
  width: "12%",
};

const PSV_NUMBER: CustomColumn<SafetyValveReport> = {
  title: "PSV #",
  dataIndex: "vPSVNumber",
  key: "vPSVNumber",
  filterType: "search",
  width: "18%",
};

const SERIAL_NUMBER: CustomColumn<SafetyValveReport> = {
  title: "Serial #",
  dataIndex: "vSerialNumber",
  key: "vSerialNumber",
  filterType: "search",
  width: "18%",
};

const CUSTOMER: CustomColumn<SafetyValveReport> = {
  title: "Site",
  dataIndex: "vCustomer",
  key: "vCustomer",
  filterType: "search",
  width: isTablet ? undefined : "20%",
};

const LAST_UPDATED: CustomColumn<SafetyValveReport> = {
  title: "Last Updated",
  dataIndex: "updatedAt",
  key: "updatedAt",
  render: (value: string) => (
    <Span>{dayjs(value).format("MMM DD, YYYY h:mm A")}</Span>
  ),
  sorter: true,
  defaultSortOrder: "descend",
  width: isTablet ? 140 : "20%",
  className: "last-updated-column",
};

const statusFilterOptions = [
  { text: "In Progress", value: IN_PROGRESS },
  { text: "For Review", value: FOR_REVIEW },
  { text: "Signed Off", value: SIGNED_OFF },
  { text: "Published", value: PUBLISHED },
  { text: "Warehouse", value: WAREHOUSE },
  { text: "W-Archived", value: W_ARCHIVE },
];

const STATUS: CustomColumn<SafetyValveReport> = {
  title: "Status",
  dataIndex: "status",
  key: "status",
  render: (value: string) => (
    <Span>
      {STATUS_OPTIONS.find((item) => item.value === value)?.label ?? value}
    </Span>
  ),
  filterType: "checkbox",
  filters: statusFilterOptions,
  width: "14%",
};

export const onlineTableColumns = (
  status: Status,
  isAdmin: boolean
): CustomColumns<SafetyValveReport> => {
  if (status !== ALL)
    return [VV_NUMBER, PSV_NUMBER, SERIAL_NUMBER, CUSTOMER, LAST_UPDATED];

  return [
    { ...VV_NUMBER, defaultSortOrder: "descend" },
    PSV_NUMBER,
    SERIAL_NUMBER,
    CUSTOMER,
    {
      ...STATUS,
      filters: isAdmin
        ? statusFilterOptions
        : statusFilterOptions.filter((item) => item.value !== W_ARCHIVE),
    },
  ];
};

export const getViewerTableColumns = (
  status: Status
): CustomColumns<SafetyValveReport> => {
  return [
    { ...VV_NUMBER, width: "12%" },
    WO_NUMBER,
    { ...PSV_NUMBER, width: "12%" },
    SERIAL_NUMBER,
    CUSTOMER,
    {
      ...LAST_UPDATED,
      title: status !== WAREHOUSE ? "Published On" : LAST_UPDATED.title,
    },
  ];
};

export const offlineTableColumns: CustomColumns<SafetyValveReport> = [
  {
    title: "Synced",
    dataIndex: "notSynced",
    key: "notSynced",
    render: (value: number) =>
      value === 1 ? (
        <NotSyncedImagContainer>
          <NotSyncedImage src={notSyncedIcon} alt="Not Synced" />
        </NotSyncedImagContainer>
      ) : (
        ""
      ),
    width: isTablet ? undefined : "6%",
  },
  {
    ...VV_NUMBER,
    onFilter: (value: boolean | Key, record: SafetyValveReport) =>
      handleFilter(value, record, "vVVNumber"),
    sorter: (a: SafetyValveReport, b: SafetyValveReport) => {
      if (a.vVVNumber && b.vVVNumber)
        return a.vVVNumber.localeCompare(b.vVVNumber);
      return 0;
    },
  },
  {
    ...PSV_NUMBER,
    onFilter: (value: boolean | Key, record: SafetyValveReport) =>
      handleFilter(value, record, "vPSVNumber"),
  },
  {
    ...SERIAL_NUMBER,
    onFilter: (value: boolean | Key, record: SafetyValveReport) =>
      handleFilter(value, record, "vSerialNumber"),
  },
  {
    ...CUSTOMER,
    onFilter: (value: boolean | Key, record: SafetyValveReport) =>
      handleFilter(value, record, "vCustomer"),
  },
  {
    ...LAST_UPDATED,
    sorter: (a: SafetyValveReport, b: SafetyValveReport) =>
      dayjs(a.updatedAt).valueOf() - dayjs(b.updatedAt).valueOf(),
  },
];
