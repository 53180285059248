import dayjs from "dayjs";
import { DatePicker as DateInput } from "antd";
import type { DatePickerProps } from "antd";
import React from "react";
import { useController } from "react-hook-form";
import { SerializedStyles } from "@emotion/react";
import FieldWrapper from "@/components/FieldWrapper";
import { ErrorMessage } from "@hookform/error-message";
import FieldError from "../FieldError";

export type TextFieldProps = {
  name: string;
  label: React.ReactNode;
  css?: SerializedStyles;
} & DatePickerProps;

const DateField = ({ name, label, css, ...props }: TextFieldProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
  });

  return (
    <FieldWrapper css={css}>
      <label htmlFor={name}>{label}</label>
      <DateInput
        id={name}
        placeholder={typeof label === "string" ? label : undefined}
        status={error ? "error" : undefined}
        {...props}
        {...field}
        value={field.value ? dayjs(field.value) : undefined}
        format="MMM DD, YYYY"
        // Make input readOnly to avoid popup keyboard in tablet
        inputReadOnly
      />
      <ErrorMessage name={name} as={FieldError} />
    </FieldWrapper>
  );
};

export default DateField;
