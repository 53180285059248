import { StyleSheet, Text, View } from "@react-pdf/renderer";
import Checkbox from "./Checkbox";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 4,
    paddingHorizontal: 4,
  },
  rowLight: {
    backgroundColor: "#F0F0F0",
  },
  rowDark: {
    backgroundColor: "#E0E0E0",
  },
  rowCell: {
    flex: 1,
    height: 10,
  },
  grayText: {
    fontSize: 8,
    color: "#616161",
  },
  darkerGrayText: {
    fontSize: 8,
    color: "#424242",
  },
  extraContentRowCell: {
    flex: 1,
    height: 10,
  },
  extraContentRowCellValue: {
    height: 10,
    flex: 0.5,
  },
});

type RowDataProps = {
  title: string;
  value: string | number | boolean | undefined | null;
  dark?: boolean;
  extraContent?:
    | {
        title: string;
        value: string | number | boolean | undefined | null;
      }
    | undefined;
};

const RowData = ({
  title,
  value = "",
  dark = false,
  extraContent = undefined,
}: RowDataProps) => {
  const hasExtraContent = extraContent !== undefined;

  const renderValue = (input: string | number | boolean | undefined | null) => {
    if (typeof input === "boolean") {
      return <Checkbox isTrue={input} />;
    }

    return <Text style={styles.darkerGrayText}>{value}</Text>;
  };

  return (
    <View style={[styles.row, dark ? styles.rowDark : styles.rowLight]}>
      <View
        style={hasExtraContent ? styles.extraContentRowCell : styles.rowCell}
      >
        <Text style={[styles.grayText]}>{title}</Text>
      </View>
      <View
        style={
          hasExtraContent ? styles.extraContentRowCellValue : styles.rowCell
        }
      >
        {renderValue(value)}
      </View>
      {hasExtraContent && (
        <>
          <View style={styles.extraContentRowCell}>
            <Text style={styles.grayText}>{extraContent.title}</Text>
          </View>
          <View style={styles.extraContentRowCellValue}>
            {renderValue(extraContent.value)}
          </View>
        </>
      )}
    </View>
  );
};

export default RowData;
