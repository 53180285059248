import { nameRegexRule } from "@/user-control/types/Account";
import { z } from "zod";

export const UpdateProfileSchema = z.object({
  Name: z
    .string()
    .min(1)
    .refine((value) => nameRegexRule.test(value)),
  Email: z.string().email(),
});

export type UpdateProfileType = z.infer<typeof UpdateProfileSchema>;
