import { Category } from "@/lookups/types/Lookup";

export const LOOKUP_TABS_DICTIONARY: {
  [tabKey: string]: {
    key: Category;
    label: string;
  }[];
} = {
  "Valve Data": [
    {
      key: "Manufacturer",
      label: "Manufacturer",
    },
    {
      key: "Orifice",
      label: "Orifice",
    },
    {
      key: "Inlet",
      label: "Inlet",
    },
    {
      key: "Outlet",
      label: "Outlet",
    },
    {
      key: "Unit",
      label: "Unit",
    },
    {
      key: "CapType",
      label: "Cap Type",
    },
    {
      key: "Service",
      label: "Service",
    },
    {
      key: "Bonnet",
      label: "Bonnet",
    },
    {
      key: "BellowsMat",
      label: "Bellows Mat",
    },
    {
      key: "BodyMaterial",
      label: "Body Material",
    },
    {
      key: "SoftSeat",
      label: "Seat Material",
    },
  ],
  "Pre Test Data": [
    {
      key: "MaintenanceFor",
      label: "Maintenance For",
    },
    {
      key: "Staff",
      label: "Technicians",
    },
    {
      key: "PreTestNotes",
      label: "Pre Test Notes",
    },
  ],
  Parts: [
    {
      key: "Condition",
      label: "Condition",
    },
    {
      key: "Action",
      label: "Action Taken",
    },
    {
      key: "Recommendation",
      label: "Recommendation",
    },
  ],
  "Final Test": [
    {
      key: "TestMedia",
      label: "Test Media",
    },
  ],
};
