import React, { useState } from "react";
import { colors, sizes, spacing } from "@/config/theme";
import { LookupRecord, editLookupSchema } from "@/lookups/types/Lookup";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  EditOutlined,
  MinusCircleFilled,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Input, Tooltip } from "antd";
import useBoolean from "@/hooks/useBoolean";

const Li = styled.li`
  padding-inline: ${spacing.xxxs};
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: ${colors.gray[400]};
    .action-area {
      display: block;
    }

    .empty-warning {
      display: block;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
`;

const ActionArea = styled.div`
  display: none;
  height: ${sizes[spacing.xl]};
`;

const EmptyWarningText = styled.span`
  text-align: end;
  color: ${colors.gray[700]};
  display: none;
`;

const NameInput = styled(Input)`
  width: 100%;
  margin-right: ${spacing.xxxs};
`;

const greenButtonStyle: React.CSSProperties = {
  color: colors.green[600],
};

const grayButtonStyle: React.CSSProperties = {
  color: colors.gray[700],
};

const disabledButtonStyle: React.CSSProperties = {
  color: colors.gray[500],
};

type ListItemProps = {
  record: LookupRecord;
  editRecord: (record: LookupRecord, newValue: string) => void;
  removeRecord: (record: LookupRecord) => void;
  allowDelete: boolean;
};

const ListItem = ({
  record,
  editRecord,
  removeRecord,
  allowDelete,
}: ListItemProps) => {
  const {
    value: editMode,
    setTrue: enableEditMode,
    setFalse: disableEditMode,
  } = useBoolean(false);
  const [editValue, setEditValue] = useState<string>(record.Lookup);
  const { value: hasError, setValue: setHasError } = useBoolean(false);

  const handleSaveClick = () => {
    editRecord(record, editValue.trim());
    disableEditMode();
  };

  const closeEditMode = () => {
    disableEditMode();
    setEditValue(record.Lookup);
    setHasError(false);
  };

  return (
    <Li>
      <Wrapper>
        {editMode ? (
          <>
            <NameInput
              value={editValue}
              onChange={(e) => {
                setEditValue(e.target.value);

                const validationResult = editLookupSchema.safeParse(
                  e.target.value
                );
                setHasError(!validationResult.success);
              }}
              placeholder="Item name"
              onPressEnter={!hasError ? handleSaveClick : undefined}
            />
            <Button
              icon={<CheckCircleFilled />}
              disabled={hasError}
              onClick={handleSaveClick}
              type="text"
              style={hasError ? disabledButtonStyle : greenButtonStyle}
            />
            <Button
              icon={<CloseCircleFilled />}
              onClick={closeEditMode}
              type="text"
              style={grayButtonStyle}
            />
          </>
        ) : (
          <>
            <span>{record.Lookup}</span>
            <ActionArea className="action-area">
              <Button
                type="text"
                icon={<EditOutlined />}
                onClick={enableEditMode}
              />

              <Tooltip
                title={
                  allowDelete
                    ? "Deleting this item will not affect any existing reports or delete its content. Only newly created reports will be affected by the change."
                    : ""
                }
              >
                <Button
                  icon={<MinusCircleFilled />}
                  type="text"
                  style={{
                    color: allowDelete ? colors.dustRed[600] : colors.gray[500],
                  }}
                  onClick={() => {
                    removeRecord(record);
                  }}
                  disabled={!allowDelete}
                />
              </Tooltip>
            </ActionArea>
          </>
        )}
      </Wrapper>
      {!allowDelete && (
        <EmptyWarningText className="empty-warning">
          Category cannot be empty.
        </EmptyWarningText>
      )}
    </Li>
  );
};

export default ListItem;
