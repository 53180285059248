import { useMemo } from "react";
import styled from "@emotion/styled";
import { Flex, Modal } from "antd";
import {
  SIGNED_OFF,
  SubmitAndTransitionStateArgs,
} from "@/reports/config/status";
import { SafetyValveReport } from "@/reports/types/SafetyValveReport";
import { useFormContext } from "react-hook-form";
import { FormElement, createFormElement } from "@/types/Form";
import AutocompleteField from "@/components/AutocompleteField";
import { transformRecordsToOptions } from "@/lookups/utils/transform";
import useLookupStore from "@/lookups/stores/lookups";
import { spacing } from "@/config/theme";
import FieldRenderer from "./ReportForm/utils";

type Props = {
  isOpen: boolean;
  setModalClose: () => void;
  submitAndTransitionState: ({
    newStatus,
    onComplete,
    isSaving,
  }: SubmitAndTransitionStateArgs) => void;
  onCancel?: () => void;
};

const SelectWrapper = styled.div`
  margin-bottom: ${spacing.xs};
`;

const SignOffReportModal = ({
  isOpen,
  setModalClose,
  submitAndTransitionState,
  onCancel,
}: Props) => {
  const { lookups } = useLookupStore();
  const { resetField, watch } = useFormContext<SafetyValveReport>();

  const handleCancel = () => {
    resetField("qSignOff");
    setModalClose();
  };

  const handleOk = () => {
    submitAndTransitionState({
      newStatus: SIGNED_OFF,
      onComplete: () => {
        setModalClose();
        resetField("qSignOff");
        onCancel?.();
      },
      isSaving: false,
    });
  };

  const fields = useMemo<FormElement[]>(
    () => [
      createFormElement(AutocompleteField, {
        label: "Technician Name",
        name: "qSignOff",
        placeholder: "Select a technician",
        options: transformRecordsToOptions(lookups.Staff),
        showSearch: true,
      }),
    ],
    [lookups.Staff]
  );

  return (
    <Modal
      className="antd-custom-warning-modal"
      title="Sign Off Report"
      open={isOpen}
      closeIcon={false}
      cancelText="Cancel"
      onCancel={handleCancel}
      okText="Sign Off"
      okButtonProps={{
        disabled: !watch("qSignOff"),
      }}
      onOk={handleOk}
    >
      <Flex vertical gap="small">
        Select your name from the list below to sign off the report.
        <SelectWrapper>
          <FieldRenderer fields={fields} />
        </SelectWrapper>
      </Flex>
    </Modal>
  );
};

export default SignOffReportModal;
