import { AutoComplete, Input } from "antd";
import { DefaultOptionType } from "antd/es/select";
import type { AutoCompleteProps } from "antd";
import React from "react";
import useBoolean from "@/hooks/useBoolean";
import { useController } from "react-hook-form";
import { SerializedStyles } from "@emotion/react";
import FieldWrapper from "@/components/FieldWrapper";
import { ErrorMessage } from "@hookform/error-message";
import { DownOutlined } from "@ant-design/icons";
import FieldError from "../FieldError";
import RequiredIndicator from "../RequiredIndicator";
import NotFoundContent from "../NotFoundContent";

const filterOption = (inputValue: string, option?: DefaultOptionType) =>
  String(option?.value || "")
    .toUpperCase()
    .indexOf(inputValue.toUpperCase()) !== -1;

export type AutocompleteFieldProps = {
  name: string;
  label: React.ReactNode;
  css?: SerializedStyles;
  required?: boolean;
} & AutoCompleteProps;

const AutocompleteField = ({
  name,
  label,
  css,
  ...props
}: AutocompleteFieldProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef = React.useRef<any>(null);

  const { value: showFullList, setValue: setShowFullList } = useBoolean(true);

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
  });

  return (
    <FieldWrapper css={css}>
      <label htmlFor={name}>
        <RequiredIndicator show={props.required} />
        {label}
      </label>
      <AutoComplete
        placeholder={label}
        id={name}
        status={error ? "error" : undefined}
        {...field}
        {...props}
        filterOption={showFullList === true ? false : filterOption}
        suffixIcon={<DownOutlined />}
        ref={inputRef}
        // Notes: We need to set the value to true when the input is focused so that the list will show all the options
        onFocus={() => {
          setShowFullList(true);
        }}
        onChange={(value) => {
          field.onChange(value);
          setShowFullList(false);
        }}
        onSelect={() => {
          // Hide keyboard on tablet after user chooses an option in list
          inputRef.current?.blur();
        }}
        onBlur={() => {
          setShowFullList(true);
        }}
        notFoundContent={<NotFoundContent />}
      >
        {/* Set the virtual keyboard type to "text" rather than the default search type keyboard from antd */}
        <Input type="text" />
      </AutoComplete>
      <ErrorMessage name={name} as={FieldError} />
    </FieldWrapper>
  );
};

export default AutocompleteField;
