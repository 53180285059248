import React, { useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import TextField from "@/components/TextField";
import { colors, font, spacing } from "@/config/theme";
import { zodResolver } from "@hookform/resolvers/zod";
import { Role, TECHNICIAN, VIEWER } from "@/auth/config/roles";
import { Button, Input } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { generateRandomPassword } from "@/user-control/utils/password";
import {
  EditTechnicianAccountSchema,
  EditTechnicianAccountType,
  EditViewerAccountSchema,
  EditViewerAccountType,
} from "@/user-control/types/Account";
import { UserStatus } from "@/user-control/types/UserStatus";
import { UserInfo } from "@/user-control/types/UserInfo";
import FieldWrapper from "@/components/FieldWrapper";
import LocationSelector from "@/user-control/components/LocationSelector";
import SiteSelector from "@/user-control/components/SiteSelector";
import FirstTimePasswordInputField from "@/user-control/components/FirstTimePasswordInputField";
import StatusSelector from "@/user-control/components/StatusSelector";
import { displayRoleName } from "@/utils/display";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${spacing.lg};
`;

const Form = styled.form`
  margin-bottom: ${spacing.lg};
  width: 100%;
`;

const ResetPwdBtn = styled(Button)`
  align-self: flex-end;
  margin-top: ${spacing.sm};
`;

const Subtitle = styled.p`
  font-size: ${font.size[16]};
  font-weight: 600;
  color: ${colors.gray[800]};
`;

interface EditAccountFormImplProps {
  changeSubmitBtnDisabledState: React.Dispatch<React.SetStateAction<boolean>>;
  selectedUser: UserInfo | null;
  shouldResetPassword?: boolean;
  setTrue: () => void;
}

const EditAccountFormImpl = ({
  changeSubmitBtnDisabledState,
  selectedUser = null,
  shouldResetPassword,
  setTrue,
}: EditAccountFormImplProps) => {
  const {
    formState: { isValid },
  } = useFormContext<EditTechnicianAccountType | EditViewerAccountType>();

  const isPending = useMemo(
    () => selectedUser?.Status === UserStatus.PENDING,
    [selectedUser]
  );

  useEffect(() => {
    changeSubmitBtnDisabledState(!isValid);
  }, [changeSubmitBtnDisabledState, isValid]);

  if (selectedUser === null) {
    return null;
  }

  return (
    <FormWrapper>
      <Subtitle>{displayRoleName(selectedUser.Role)} Info</Subtitle>
      <TextField label="Full Name" name="Name" autoComplete="off" disabled />
      <TextField label="Email" name="Email" autoComplete="off" disabled />
      {shouldResetPassword ? (
        <FirstTimePasswordInputField label="ResetPassword" name="Password" />
      ) : (
        <FieldWrapper>
          <label htmlFor="FakePassword">{"Password" as React.ReactNode}</label>
          <Input
            id="FakePassword"
            name="FakePassword"
            value="**********"
            prefix={<LockOutlined />}
            suffix={!isPending ? "Password changed by user." : ""}
            disabled
          />
          <ResetPwdBtn onClick={setTrue}>Reset Password</ResetPwdBtn>
        </FieldWrapper>
      )}
      {selectedUser.Role === TECHNICIAN && <LocationSelector />}
      {!isPending && <StatusSelector />}
      {selectedUser.Role === VIEWER && (
        <>
          <Subtitle>Sites</Subtitle>
          <SiteSelector />
        </>
      )}
    </FormWrapper>
  );
};

interface EditAccountFormProps {
  userRole: Role;
  changeSubmitBtnDisabledState: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (data: EditTechnicianAccountType | EditViewerAccountType) => void;
  selectedUser?: UserInfo | null;
  shouldResetPassword?: boolean;
  setTrue: () => void;
}

const EditAccountForm = ({
  changeSubmitBtnDisabledState,
  onSubmit,
  selectedUser = null,
  shouldResetPassword,
  setTrue,
}: EditAccountFormProps) => {
  const methods = useForm<EditTechnicianAccountType | EditViewerAccountType>({
    defaultValues: {
      UserId: selectedUser?.UserId,
      Name: selectedUser?.Name,
      Email: selectedUser?.Email,
      Password: generateRandomPassword(),
      Location: selectedUser?.Location,
      Status: selectedUser?.Status,
      Sites: selectedUser?.Sites?.map((site) => site.SiteId),
    },
    resolver: zodResolver(
      selectedUser?.Role === TECHNICIAN
        ? EditTechnicianAccountSchema
        : EditViewerAccountSchema
    ),
    mode: "onBlur",
    reValidateMode: "onSubmit",
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <Form
        id="edit-account-form"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <EditAccountFormImpl
          changeSubmitBtnDisabledState={changeSubmitBtnDisabledState}
          selectedUser={selectedUser}
          shouldResetPassword={shouldResetPassword}
          setTrue={setTrue}
        />
      </Form>
    </FormProvider>
  );
};

export default EditAccountForm;
