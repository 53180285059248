import { colors } from "@/config/theme";
import { SafetyValveReport } from "@/reports/types/SafetyValveReport";
import { StyleSheet, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  footer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 12,
    paddingTop: 4,
  },
  footerText: {
    fontSize: 8,
    color: colors.gray[800],
  },
});

type PageFooterProps = {
  data: SafetyValveReport;
};

const PageFooter = ({ data }: PageFooterProps) => {
  return (
    <View style={styles.footer} fixed>
      <Text style={styles.footerText}>
        {`${data.vCustomer}_${data.vPSVNumber ?? ""} | VanValve`}
      </Text>
      <Text
        render={({ pageNumber, totalPages }) =>
          `Page ${pageNumber} of ${totalPages}`
        }
        style={styles.footerText}
      />
    </View>
  );
};

export default PageFooter;
