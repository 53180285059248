import { z } from "zod";
import { Role } from "@/auth/config/roles";
import { Location } from "@/reports/config/locations";

export const LoginSchema = z.object({
  Email: z.string().email(),
  Password: z.string().min(8),
});

export type LoginType = z.infer<typeof LoginSchema>;

export type LoginTypeResponse = {
  Name: string;
  Email: string;
  Role: Role;
  Status: string;
  UserId: number;
  Token: string;
  Location: Location | undefined;
};
