import { colors, spacing } from "@/config/theme";
import styled from "@emotion/styled";

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xxxs};
  margin-bottom: ${spacing.xs};
  position: relative;

  .ant-input-disabled {
    color: ${colors.gray[900]} !important;
  }

  .ant-select-disabled {
    .ant-select-selection-item {
      color: ${colors.gray[900]} !important;
    }
    .ant-select-arrow {
      display: none !important;
    }
  }

  .ant-input-number-input:disabled {
    color: ${colors.gray[900]} !important;
  }

  .ant-picker-input {
    input:disabled {
      color: ${colors.gray[900]} !important;
    }
    .ant-picker-suffix {
      display: none !important;
    }
  }

  .ant-checkbox-wrapper-disabled {
    .ant-checkbox-inner:after {
      border-color: ${colors.gray[900]} !important;
    }
    span {
      color: ${colors.gray[900]} !important;
    }
  }
`;
export default FieldWrapper;
