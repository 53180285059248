import { VIEWER, Role, TECHNICIAN, ADMIN } from "@/auth/config/roles";

export interface UserGroupOption {
  value: Role;
  label: string;
}

export const USER_GROUP_OPTIONS: UserGroupOption[] = [
  {
    label: "Technicians",
    value: TECHNICIAN,
  },
  {
    label: "Viewers",
    value: VIEWER,
  },
  {
    label: "Admins",
    value: ADMIN,
  },
];
