import PasswordField from "@/components/PasswordField";
import { spacing } from "@/config/theme";
import {
  ChangePasswordSchema,
  ChangePasswordType,
} from "@/profile/types/ChangePassword";
import styled from "@emotion/styled";
import { zodResolver } from "@hookform/resolvers/zod";
import { ConfigProvider } from "antd";
import React, { useEffect } from "react";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  useFormContext,
} from "react-hook-form";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${spacing.lg};
`;

const Form = styled.form`
  margin-bottom: ${spacing.lg};
  width: 100%;
`;

type ChangePasswordFormImplProps = {
  changeSubmitBtnDisabledState: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChangePasswordFormImpl = ({
  changeSubmitBtnDisabledState,
}: ChangePasswordFormImplProps) => {
  const {
    formState: { isDirty, isValid },
  } = useFormContext<ChangePasswordType>();

  useEffect(() => {
    changeSubmitBtnDisabledState(!isValid || !isDirty);
  }, [changeSubmitBtnDisabledState, isDirty, isValid]);

  return (
    <FormWrapper>
      <ConfigProvider input={{ autoComplete: "new-password" }}>
        <PasswordField
          label="Current Password"
          name="CurrentPassword"
          type="password"
          showErrorMessage
        />
        <PasswordField
          label="New Password"
          name="NewPassword"
          type="password"
          showPasswordStrength
        />
      </ConfigProvider>
    </FormWrapper>
  );
};

type ChangePasswordFormProps = {
  onSubmit: SubmitHandler<ChangePasswordType>;
  changeSubmitBtnDisabledState: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChangePasswordForm = ({
  onSubmit,
  changeSubmitBtnDisabledState,
}: ChangePasswordFormProps) => {
  const methods = useForm<ChangePasswordType>({
    resolver: zodResolver(ChangePasswordSchema),
    mode: "onBlur",
    reValidateMode: "onSubmit",
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <Form id="change-password-form" onSubmit={handleSubmit(onSubmit)}>
        <ChangePasswordFormImpl
          changeSubmitBtnDisabledState={changeSubmitBtnDisabledState}
        />
      </Form>
    </FormProvider>
  );
};

export default ChangePasswordForm;
