import { Button, Alert, Tooltip } from "antd";
import styled from "@emotion/styled";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  useFormContext,
} from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import TextField from "@/components/TextField";
import PasswordField from "@/components/PasswordField";
import { font, spacing } from "@/config/theme";
import useNetwork from "@/stores/network";
import { useUserStore } from "@/auth/stores/user";
import { LoginType, LoginSchema } from "@/auth/types/Login";
import { NEW_USER } from "@/auth/config/roles";
import {
  MAX_RETRY_ATTEMPTS,
  RETRY_WARNING_THRESHOLD,
} from "@/auth/config/retry";
import { generateErrorMessage } from "@/auth/utils/login";

type Props = {
  onSubmit: SubmitHandler<LoginType>;
  isLoading: boolean;
  isError: boolean;
};

const Form = styled.form`
  margin-top: ${spacing.lg};
  margin-bottom: ${spacing.lg};
  width: 100%;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${spacing.lg};
`;

const NewUserPrompt = styled.div`
  font-size: ${font.size[16]};
  font-weight: 600;
`;

const ForgotPasswordPrompt = styled.div`
  font-size: ${font.size[12]};
  align-self: center;
`;

type LoginFormImplProps = {
  isLoading: boolean;
  isError: boolean;
};

const LoginFormImpl = ({ isLoading, isError }: LoginFormImplProps) => {
  const {
    formState: { isDirty, isValid },
  } = useFormContext<LoginType>();
  const [searchParams] = useSearchParams();
  const { attempts, retryAfter } = useUserStore();
  const { online } = useNetwork();
  const type = searchParams.get("type");
  return (
    <FormWrapper>
      {type === NEW_USER && (
        <NewUserPrompt>
          Please enter the Login Credentials provided by your admin.
        </NewUserPrompt>
      )}
      {(isError || attempts > RETRY_WARNING_THRESHOLD) && (
        <Alert
          message={`Incorrect email or password. ${generateErrorMessage(
            attempts,
            retryAfter
          )}`}
          type="error"
          showIcon
        />
      )}
      <TextField label="Email" name="Email" />
      <PasswordField label="Password" name="Password" type="password" />
      <ForgotPasswordPrompt>
        Forgot password? Contact your administrator
      </ForgotPasswordPrompt>
      <Tooltip placement="bottom" title={online ? "" : "Currently offline"}>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          disabled={
            !online || !isValid || !isDirty || attempts >= MAX_RETRY_ATTEMPTS
          }
        >
          Log in
        </Button>
      </Tooltip>
    </FormWrapper>
  );
};

const LoginForm = ({ onSubmit, ...props }: Props) => {
  const methods = useForm<LoginType>({
    resolver: zodResolver(LoginSchema),
    reValidateMode: "onSubmit",
  });
  const { handleSubmit } = methods;
  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <LoginFormImpl {...props} />
      </Form>
    </FormProvider>
  );
};

export default LoginForm;
