import { SafetyValveReport } from "@/reports/types/SafetyValveReport";
import Dexie, { Table } from "dexie";

export class ReportsDB extends Dexie {
  reports!: Table<SafetyValveReport>;

  constructor() {
    super("ReportsDB");
    this.version(1).stores({
      reports: "++SafetyValveReportId, notSynced",
    });
  }
}

export const db = new ReportsDB();
