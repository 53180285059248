import { colors, font, spacing } from "@/config/theme";
import styled from "@emotion/styled";
import React from "react";

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-left: 4px solid ${colors.daybreakBlue[600]};
`;

const Title = styled.h1`
  padding-left: ${spacing.sm};
  font-size: ${font.size[24]};
  font-weight: 600;
  line-height: 1.33;
  display: flex;
  flex-direction: column;
  gap: ${spacing.xxs};
`;

type Props = {
  title: React.ReactNode;
  children?: React.ReactNode;
};
const PageTitle = ({ title, children = null }: Props) => {
  return (
    <TitleContainer>
      <Title>{title}</Title>
      {children}
    </TitleContainer>
  );
};

export default PageTitle;
