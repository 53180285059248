import {
  Attachment,
  SafetyValveReport,
} from "@/reports/types/SafetyValveReport";
import { Button, Modal } from "antd";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { getASME } from "@/reports/utils/asme";
import { colors, sizes, spacing } from "@/config/theme";
import dayjs from "dayjs";
import { NANAIMO } from "@/reports/config/locations";
import styled from "@emotion/styled";
import { isTablet } from "@/utils/deviceCheck";
import PageFooter from "./PageFooter";
import AttachmentPages from "./AttachmentPages";
import RowData from "./RowData";
import PartsRow from "./PartsRow";

const FooterArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
`;

const ExportNotice = styled.p`
  margin-right: ${spacing.md};
  font-size: ${sizes[12]};
  color: ${colors.gray[700]};
`;

// Create PDF Viewer styles
export const styles = StyleSheet.create({
  pdfViewerStyle: {
    marginTop: 24,
    width: "100%",
    height: "85vh",
  },
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    paddingHorizontal: 24,
    paddingTop: 8,
  },
  documentTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 8,
  },
  main: {
    flexDirection: "column",
    flex: 1,
  },
  section: {
    flexDirection: "column",
    borderWidth: 1,
    borderColor: colors.gray[500],
    borderTopWidth: 0,
  },
  sectionHeader: {
    backgroundColor: colors.gray[700],
  },
  sectionTitle: {
    color: "white",
    fontSize: 8,
    fontWeight: "bold",
    paddingHorizontal: 4,
    paddingVertical: 4,
  },
  sectionContent: {
    flexDirection: "row",
    gap: 4,
    width: "100%",
    flexGrow: 1,
  },
  sectionContentColumn: {
    flex: 1,
  },
  partsHeader: {
    flexDirection: "row",
    width: "100%",
    flexGrow: 1,
    paddingVertical: 4,
  },
  partsHeaderTitle: {
    flex: 1,
    color: "white",
    fontSize: 8,
    fontWeight: "bold",
    paddingHorizontal: 4,
  },
  subtitle: {
    fontSize: 7,
    fontWeight: "bold",
    marginTop: 4,
    marginBottom: 4,
    paddingHorizontal: 4,
  },
  commentContainer: {
    paddingHorizontal: 4,
  },
  commentTitle: {
    fontSize: 8,
    color: colors.gray[800],
    fontWeight: "bold",
    marginVertical: 4,
  },
  commentText: {
    fontSize: 8,
    color: colors.gray[900],
  },
});

export const displayPdfName = (
  siteName: string,
  psvNumber: string | null | undefined,
  withCompanyName = false,
  location = ""
) => {
  const pdfName = `${siteName}_${
    !psvNumber ? "" : psvNumber.replaceAll(".", "-")
  }`;

  if (withCompanyName) {
    const companyName =
      location === NANAIMO
        ? "Nanaimo Valve Service Ltd. Relief Valve Data Sheet"
        : "Van. Valve Service Centre & Sales Ltd. Relief Valve Data Sheet";

    return `[${pdfName}] ${companyName}`;
  }

  return pdfName;
};

type PdfDocumentProps = {
  imageAttachments: Attachment[];
  imageOrientations: string[];
  data: SafetyValveReport | undefined;
  location: string | undefined;
};

export const PdfDocument = ({
  data,
  imageAttachments,
  imageOrientations,
  location,
}: PdfDocumentProps) => {
  if (!data) return null;

  return (
    <Document title={`${displayPdfName(data.vCustomer, data.vPSVNumber)}`}>
      <Page size="A4" style={styles.page} wrap={false}>
        <Text style={styles.documentTitle}>
          {`${displayPdfName(data.vCustomer, data.vPSVNumber, true, location)}`}
        </Text>
        <View style={styles.main}>
          {/* Valve Data */}
          <View style={styles.section}>
            <View style={styles.sectionHeader}>
              <Text style={styles.sectionTitle}>Valve Data</Text>
            </View>
            <View style={styles.sectionContent}>
              <View style={styles.sectionContentColumn}>
                {/* TODO: Optimize this section to avoid repetition */}
                <RowData title="Site" value={data.vCustomer} />
                <RowData
                  title={location === NANAIMO ? "NV #" : "VV #"}
                  value={data.vVVNumber}
                  dark
                />
                <RowData title="Model #" value={data.vModelNumber} />
                <RowData title="Valve Size" value={data.vValveSize} dark />
                <RowData title="Set Pressure" value={data.vSetPressure} />
                <RowData title="Unit" value={data.vUnit} dark />
                <RowData title="Cap Type" value={data.vCapType} />
                <RowData title="Bonnet" value={data.vBonnet} dark />
                <RowData title="Body Material" value={data.vBodyMaterial} />
                <RowData title="ASME" value={getASME(data)} dark />
              </View>
              <View style={styles.sectionContentColumn}>
                <RowData title="PO #" value={data.vPONumber} />
                <RowData title="WO #" value={data.vWONumber} dark />
                <RowData title="Serial #" value={data.vSerialNumber} />
                <RowData title="Inlet" value={data.vInlet} dark />
                <RowData title="Back Pressure" value={data.vBackPressure} />
                <RowData title="Capacity" value={data.vCapacity} dark />
                <RowData title="Service" value={data.vService} />
                <RowData title="Bellows Mat" value={data.vBellowsMat} dark />
                <RowData title="Seat Material" value={data.vSeatMaterial} />
                <RowData
                  title="Seal Broken"
                  value={data.vSealBroken}
                  dark
                  extraContent={{
                    title: "Restricted Lift",
                    value: data.vRestrictedLift,
                  }}
                />
              </View>
              <View style={styles.sectionContentColumn}>
                <RowData title="CustID / PSV #" value={data.vPSVNumber} />
                <RowData title="Manufacturer" value={data.vManufacturer} dark />
                <RowData title="Orifice" value={data.vOrifice} />
                <RowData title="Outlet" value={data.vOutlet} dark />
                <RowData
                  title="Diff Set Pressure"
                  value={data.vColdDiffSetPressure}
                />
                <RowData title="CRN #" value={data.vCRNNumber} dark />
                <RowData title="Comp Screw" value={data.vCompScrew} />
                <RowData title="BD Ring Upper" value={data.vBDRingUpper} dark />
                <RowData title="BD Ring Lower" value={data.vBDRingLower} />
                <RowData title="Lift" value={data.vLift} dark />
              </View>
            </View>
          </View>
          {/* Pre Test Data */}
          <View style={styles.section}>
            <View style={styles.sectionHeader}>
              <Text style={styles.sectionTitle}>Pre Test Data</Text>
            </View>
            <View style={styles.sectionContent}>
              <View style={styles.sectionContentColumn}>
                <RowData title="Maintenance For" value={data.rMaintenanceFor} />
                <RowData title="Leaked" value={data.rLeaked} dark />
                <RowData title="Name Plate" value={data.rNamePlate} />
                <RowData
                  title="Set Pressure Change"
                  value={data.rSetPressureChange}
                  dark
                />

                <Text style={styles.subtitle}>Original Nameplate Info</Text>
                <RowData title="Set Pressure" value={data.oSetPressure} />
                <RowData title="Capacity" value={data.oCapacity} dark />
              </View>
              <View style={styles.sectionContentColumn}>
                <RowData
                  title="Date"
                  value={
                    data.rDate ? dayjs(data.rDate).format("MMM DD, YYYY") : ""
                  }
                />

                <RowData title="Pre Popped" value={data.rPrePopped} dark />
                <RowData title="Prev Repair" value={data.rPrevRepairCompany} />
                <RowData title="New Pressure" value={data.rNewPressure} dark />

                <Text style={styles.subtitle}> </Text>
                <RowData title="Temperature" value={data.oTemperature} />
                <RowData title="Model Number" value={data.oModelNumber} dark />
              </View>
              <View style={styles.sectionContentColumn}>
                <RowData title="Received By" value={data.rRecievedBy} />
                <RowData
                  title="Pre Test Notes"
                  value={data.rPreTestNotes}
                  dark
                />
                <RowData title="Dismb / Insp By" value={data.rDismbInspBy} />
                <RowData title="New Cap" value={data.rNewCap} dark />
                <Text style={styles.subtitle}>Previous Repair Nameplate</Text>
                <RowData title="Set Pressure" value={data.oSetPressure} />
                <RowData title="Capacity" value={data.oCapacity} dark />
              </View>
            </View>
          </View>
          {/* Parts */}
          <View style={styles.section}>
            <View style={[styles.sectionHeader, styles.partsHeader]}>
              <Text style={styles.partsHeaderTitle}>Parts</Text>
              <Text style={styles.partsHeaderTitle}>Conditions</Text>
              <Text style={styles.partsHeaderTitle}>Actions Taken</Text>
              <Text style={styles.partsHeaderTitle}>Recommendation</Text>
            </View>
            <View style={styles.sectionContent}>
              <View style={styles.sectionContentColumn}>
                <PartsRow
                  title="Cap / Lever"
                  condition={data.CapLeverC}
                  actionTaken={data.CapLeverA}
                  recommendation={data.CapLeverR}
                />
                <PartsRow
                  title="Spring Adj Screw"
                  condition={data.SpringAdjScrewC}
                  actionTaken={data.SpringAdjScrewA}
                  recommendation={data.SpringAdjScrewR}
                  dark
                />
                <PartsRow
                  title="Spring"
                  condition={data.SpringC}
                  actionTaken={data.SpringA}
                  recommendation={data.SpringR}
                />
                <PartsRow
                  title="Buttons"
                  condition={data.ButtonsC}
                  actionTaken={data.ButtonsA}
                  recommendation={data.ButtonsR}
                  dark
                />
                <PartsRow
                  title="Spindle"
                  condition={data.SpindleC}
                  actionTaken={data.SpindleA}
                  recommendation={data.SpindleR}
                />
                <PartsRow
                  title="Spindle Retainer"
                  condition={data.SpindleRetainerC}
                  actionTaken={data.SpindleRetainerA}
                  recommendation={data.SpindleRetainerR}
                  dark
                />
                <PartsRow
                  title="Guide"
                  condition={data.GuideC}
                  actionTaken={data.GuideA}
                  recommendation={data.GuideR}
                />
                <PartsRow
                  title="Disc Holder"
                  condition={data.DiscHolderC}
                  actionTaken={data.DiscHolderA}
                  recommendation={data.DiscHolderR}
                  dark
                />
                <PartsRow
                  title="Disc Retainer"
                  condition={data.DiscRetainerC}
                  actionTaken={data.DiscRetainerA}
                  recommendation={data.DiscRetainerR}
                />
                <PartsRow
                  title="Disc"
                  condition={data.DiscC}
                  actionTaken={data.DiscA}
                  recommendation={data.DiscR}
                  dark
                />
                <PartsRow
                  title="Nozzle"
                  condition={data.NozzleC}
                  actionTaken={data.NozzleA}
                  recommendation={data.NozzleR}
                />
                <PartsRow
                  title="BD Ring Upper"
                  condition={data.BDRingUpperC}
                  actionTaken={data.BDRingUpperA}
                  recommendation={data.BDRingUpperR}
                  dark
                />
                <PartsRow
                  title="BD Ring Lower"
                  condition={data.BDRingLowerC}
                  actionTaken={data.BDRingLowerA}
                  recommendation={data.BDRingLowerR}
                />
                <PartsRow
                  title="Gaskets"
                  condition={data.GasketsC}
                  actionTaken={data.GasketsA}
                  recommendation={data.GasketsR}
                  dark
                />
                <PartsRow
                  title="Bellows"
                  condition={data.BellowsC}
                  actionTaken={data.BellowsA}
                  recommendation={data.BellowsR}
                />
                <PartsRow
                  title="Piston"
                  condition={data.PistonC}
                  actionTaken={data.PistonA}
                  recommendation={data.PistonR}
                  dark
                />
                <PartsRow
                  title="Sleeve"
                  condition={data.SleeveC}
                  actionTaken={data.SleeveA}
                  recommendation={data.SleeveR}
                />
                <PartsRow
                  title="Flanges"
                  condition={data.FlangesC}
                  actionTaken={data.FlangesA}
                  recommendation={data.FlangesR}
                  dark
                />
                <PartsRow
                  title="Body/Bonnet"
                  condition={data.BonnetC}
                  actionTaken={data.BonnetA}
                  recommendation={data.BonnetR}
                />
              </View>
            </View>
          </View>
          {/* Final Test Data */}
          <View style={styles.section}>
            <View style={styles.sectionHeader}>
              <Text style={styles.sectionTitle}>Final Test Data</Text>
            </View>
            <View style={styles.sectionContent}>
              <View style={styles.sectionContentColumn}>
                <RowData title="Set Pressure" value={data.tSetPressure} />
                <RowData title="Simmer" value={data.tSimmer} dark />
                <RowData title="BD Ring Upper" value={data.tBDRingUpper} />
                <RowData title="BD Ring Lower" value={data.tBDRingLower} dark />
                <RowData title="Assembled By" value={data.tAssembledBy} />
              </View>
              <View style={styles.sectionContentColumn}>
                <RowData title="Back Pressure" value={data.tBackPressure} />
                <RowData
                  title="Final Set Pressure"
                  value={data.tFinalSetPressure}
                  dark
                />
                <RowData title="Gauge" value={data.tGauge} />
                <RowData
                  title="Date Tested"
                  value={
                    data.tDateTested
                      ? dayjs(data.tDateTested).format("MMM DD, YYYY")
                      : ""
                  }
                  dark
                />
                <RowData title="Tested By" value={data.tTestedBy} />
              </View>
              <View style={styles.sectionContentColumn}>
                <RowData title="CDTP" value={data.tCDTP} />
                <RowData title="BPM @ 90%" value={data.tBPM} dark />
                <RowData
                  title="Back Press Test @"
                  value={data.tBackPressTest}
                />
                <RowData title="Test Media" value={data.tTestMedia} dark />
                <RowData title="Witnessed By" value={data.tWitnessedBy} />
              </View>
            </View>
            <View style={styles.sectionHeader}>
              <Text style={styles.sectionTitle}>QC / Checklist</Text>
            </View>
            <View style={styles.sectionContent}>
              <View style={styles.sectionContentColumn}>
                <RowData title="Painted" value={data.qPainted} />
                <RowData
                  title="Tagged/Checked"
                  value={data.qTaggedChecked}
                  dark
                />
                <RowData title="Bellows Vented" value={data.qBellowsVented} />
              </View>
              <View style={styles.sectionContentColumn}>
                <RowData title="Sealed" value={data.qSealed} />
                <RowData
                  title="Shipped Upright"
                  value={data.qShippedUpright}
                  dark
                />
                <RowData title="Signed Off" value={data.qSignOff} />
              </View>
              <View style={styles.sectionContentColumn}>
                <RowData title="Taped/Covered" value={data.qTapedCovered} />
                <RowData
                  title="Vent Holes Plugged"
                  value={data.qVentHolesPlugged}
                  dark
                />
              </View>
            </View>
            <View style={styles.commentContainer}>
              <View style={styles.sectionContent}>
                <Text style={styles.commentTitle}>Comments</Text>
              </View>
              <View style={styles.sectionContent}>
                <Text style={styles.commentText}>{data.qComments}</Text>
              </View>
            </View>
          </View>
        </View>
        <PageFooter data={data} />
      </Page>
      {/* Render attachments here */}
      <AttachmentPages
        data={data}
        imageAttachments={imageAttachments}
        imageOrientations={imageOrientations}
      />
    </Document>
  );
};

type PdfModalProps = {
  visible: boolean;
  location: string | undefined;
  onClose: () => void;
  data: SafetyValveReport | undefined;
  imageAttachments: Attachment[];
  imageOrientations: string[];
};

const PdfModal = ({
  visible,
  location,
  onClose,
  data,
  imageAttachments,
  imageOrientations,
}: PdfModalProps) => {
  if (!data) return null;

  return (
    <Modal
      width={!isTablet ? window.screen.width * 0.8 : undefined}
      open={visible}
      onCancel={onClose}
      destroyOnClose
      style={{
        height: "100vh",
        top: !isTablet ? 10 : undefined,
      }}
      // eslint-disable-next-line react/no-unstable-nested-components
      footer={() => {
        if (!data) return null;
        return (
          <FooterArea>
            <PDFDownloadLink
              document={
                <PdfDocument
                  data={data}
                  location={location}
                  imageAttachments={imageAttachments}
                  imageOrientations={imageOrientations}
                />
              }
              fileName={`${displayPdfName(
                data.vCustomer,
                data.vPSVNumber
              )}.pdf`}
            >
              <Button type="primary">Download</Button>
            </PDFDownloadLink>
            {data.Attachments?.some((attachment) =>
              attachment.FileType.includes("pdf")
            ) && (
              <ExportNotice>
                PDF attachments will not be included as part of the exported
                file; you must download these separately.
              </ExportNotice>
            )}
          </FooterArea>
        );
      }}
    >
      {!isTablet ? (
        <PDFViewer style={styles.pdfViewerStyle}>
          <PdfDocument
            location={location}
            data={data}
            imageAttachments={imageAttachments}
            imageOrientations={imageOrientations}
          />
        </PDFViewer>
      ) : (
        <p>
          PDF Viewer not supported on tablet, please use a desktop device to
          view the file. But you can still download it by pressing the Download
          button below.
        </p>
      )}
    </Modal>
  );
};

export default PdfModal;
