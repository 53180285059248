import SearchDropdown from "@/components/SearchDropdown";
import { CustomColumn, CustomColumns, SearchHandler } from "@/types/Table";
import { SearchOutlined } from "@ant-design/icons";
import { InputRef } from "antd";
import { FilterConfirmProps } from "antd/es/table/interface";
import React, { useCallback, useMemo, useRef } from "react";
import useBoolean from "./useBoolean";

type Config<T> = {
  columns: CustomColumns<T>;
};

const getSearchColumnProps = <T,>(
  column: CustomColumn<T>,
  searchInput: React.RefObject<InputRef>,
  handleSearch: SearchHandler<T>,
  setHasSearched: React.Dispatch<React.SetStateAction<boolean>>
): Partial<CustomColumn<T>> => {
  return {
    filterDropdown: (props) => (
      <SearchDropdown<T>
        {...props}
        column={column}
        searchInputRef={searchInput}
        handleSearch={handleSearch}
      />
    ),
    filterIcon: (filtered: boolean) => {
      if (filtered) {
        setHasSearched(true);
      }

      return (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          // Notes: The reason we put focus event is because it will trigger the virtual keyboard on tablet when the user clicks on the search icon
          searchInput.current?.focus();
          searchInput.current?.select();
        }, 100);
      }
    },
  };
};

const useNonPaginatedTable = <T,>({ columns }: Config<T>) => {
  const searchInput = useRef<InputRef>(null);
  const handleSearch = useCallback(
    (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void) => {
      confirm();
    },
    []
  );

  const { value: hasSearched, setValue: setHasSearched } = useBoolean(false);

  const columnsWithFilters = useMemo(() => {
    return columns.map((column) => {
      const { filterType } = column;
      if (filterType === "search") {
        return {
          ...column,
          ...getSearchColumnProps(
            column,
            searchInput,
            handleSearch,
            setHasSearched
          ),
        };
      }
      return column;
    });
  }, [columns, handleSearch, setHasSearched]);

  return useMemo(
    () => ({
      columns: columnsWithFilters,
      hasSearched,
    }),
    [columnsWithFilters, hasSearched]
  );
};

export default useNonPaginatedTable;
