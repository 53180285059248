import * as ExifReader from "exifreader";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import {
  Attachment,
  SafetyValveReport,
} from "@/reports/types/SafetyValveReport";
import {
  PdfDocument,
  displayPdfName,
} from "@/reports/components/ReportForm/ExportPdf/PdfModal";
import { message } from "antd";

export const getImagesOrientations = async (
  images: Attachment[]
): Promise<string[]> => {
  // Get image orientations for render in pdf viewer later
  // NOTES: This is because a bug in react-pdf where it does not render portrait images correctly.
  // SEE: https://github.com/diegomura/react-pdf/issues/1848
  return Promise.all(
    images.map(async (imageObj) => {
      const res = await fetch(imageObj.Link);
      const blob = await res.blob();
      const arrBuff = await blob.arrayBuffer();
      const tags = ExifReader.load(arrBuff);
      return tags.Orientation?.description ?? "unknown";
    })
  );
};

export const exportReportsZipFile = async (
  reports: SafetyValveReport[],
  includeImage: boolean,
  location: string | undefined
) => {
  try {
    const resultZip = new JSZip();

    await Promise.all(
      reports.map(async (report): Promise<void> => {
        if (report) {
          let images = report.Attachments?.filter((item) =>
            item.FileType.includes("image")
          );

          if (images === undefined || !includeImage) images = [];

          // Get image orientations
          const orientations = includeImage
            ? await getImagesOrientations(images)
            : [];

          // Generate pdf blob based on report data
          const blob = pdf(
            <PdfDocument
              data={report}
              location={location}
              imageAttachments={images}
              imageOrientations={orientations}
            />
          ).toBlob();

          // Add pdf blob to zip
          resultZip.file(
            `${displayPdfName(report.vCustomer, report.vPSVNumber)}.pdf`,
            blob
          );
        }
      })
    );

    // Generate zip file and trigger browser download
    const url = URL.createObjectURL(
      await resultZip.generateAsync({ type: "blob" })
    );
    saveAs(url, "VanValve Reports.zip");
    message.success("Reports successfully exported");
  } catch (error) {
    message.error("Error exporting reports, please try again later");
  }
};
