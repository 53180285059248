import { useEffect } from "react";
import { useGetAllLookups } from "@/lookups/api";
import useLookupStore from "@/lookups/stores/lookups";

const useDownloadLookups = () => {
  const { data: allLookups } = useGetAllLookups();
  const { setAll } = useLookupStore();

  useEffect(() => {
    if (allLookups) {
      setAll(allLookups);
    }
  }, [allLookups, setAll]);
};
export default useDownloadLookups;
