import useLookupStore from "@/lookups/stores/lookups";
import useSiteStore from "@/sites/stores/sites";
import AutocompleteField from "@/components/AutocompleteField";
import SelectField from "@/components/SelectField";
import { useMemo } from "react";
import TextField from "@/components/TextField";
import { createFormElement, FormElement } from "@/types/Form";
import CheckboxField from "@/components/CheckboxField";
import { getAsmeOptions } from "@/reports/utils/asme";
import { DEFAULT_SITE_NAME } from "@/reports/config/sites";
import { useLocation, useParams } from "react-router-dom";
import { transformRecordsToOptions } from "@/lookups/utils/transform";
import { NANAIMO } from "@/reports/config/locations";
import FieldRenderer, { checkboxContainerStyles, FieldWrapper } from "./utils";

type Props = {
  disabled?: boolean;
};

const useFields = () => {
  const { location } = useParams();
  const routeLocation = useLocation();

  const { lookups } = useLookupStore();
  const { sites } = useSiteStore();
  const uniqueSites = useMemo(
    () => [...new Set(sites.map((site) => site.SiteName))],
    [sites]
  );

  return useMemo<FormElement[]>(
    () => [
      createFormElement(SelectField, {
        label: "Site",
        name: "vCustomer",
        placeholder: "Site",
        options: [
          {
            label: DEFAULT_SITE_NAME,
            value: DEFAULT_SITE_NAME,
          },
        ].concat(
          uniqueSites.map((siteName) => ({
            label: siteName,
            value: siteName,
          }))
        ),
        required: true,
        showSearch: true,
      }),
      createFormElement(TextField, {
        label: "PO #",
        name: "vPONumber",
      }),
      createFormElement(TextField, {
        label: "CustID / PSV #",
        name: "vPSVNumber",
      }),
      createFormElement(TextField, {
        label: location === NANAIMO ? "NV #" : "VV #",
        name: "vVVNumber",
        inputMode: "decimal",
        transformInput: (value: string, previousValue = "") => {
          // Remove any existing hyphens
          let targetValue = value.replace(/-/g, "");

          // Remove hyphen if user is deleting
          if (value.length === 2 && previousValue.length === 3) {
            targetValue = targetValue.slice(0, 1);
          }

          // Insert a hyphen after the 2nd character
          if (targetValue.length >= 2) {
            targetValue = `${targetValue.slice(0, 2)}-${targetValue.slice(2)}`;
          }
          return targetValue;
        },
        autoFocus: routeLocation.state?.fromDuplicate ?? false,
      }),
      createFormElement(TextField, {
        label: "WO #",
        name: "vWONumber",
      }),
      createFormElement(AutocompleteField, {
        label: "Manufacturer",
        name: "vManufacturer",
        options: transformRecordsToOptions(lookups.Manufacturer),
        showSearch: true,
      }),
      createFormElement(TextField, {
        label: "Model #",
        name: "vModelNumber",
      }),
      createFormElement(TextField, {
        label: "Serial #",
        name: "vSerialNumber",
      }),
      createFormElement(AutocompleteField, {
        label: "Orifice",
        name: "vOrifice",
        options: transformRecordsToOptions(lookups.Orifice),
        showSearch: true,
      }),
      createFormElement(TextField, {
        label: "Valve Size",
        name: "vValveSize",
      }),
      createFormElement(AutocompleteField, {
        label: "Inlet",
        name: "vInlet",
        options: transformRecordsToOptions(lookups.Inlet),
        showSearch: true,
      }),
      createFormElement(AutocompleteField, {
        label: "Outlet",
        name: "vOutlet",
        options: transformRecordsToOptions(lookups.Outlet),
        showSearch: true,
      }),
      createFormElement(TextField, {
        label: "Set Pressure",
        name: "vSetPressure",
        type: "number",
        inputMode: "decimal",
      }),
      createFormElement(TextField, {
        label: "Back Pressure",
        name: "vBackPressure",
        inputMode: "decimal",
      }),
      createFormElement(TextField, {
        label: "Diff Set Pressure",
        name: "vColdDiffSetPressure",
        type: "number",
        inputMode: "decimal",
      }),
      createFormElement(AutocompleteField, {
        label: "Unit",
        name: "vUnit",
        options: transformRecordsToOptions(lookups.Unit),
      }),
      createFormElement(TextField, {
        label: "Capacity",
        name: "vCapacity",
      }),
      createFormElement(TextField, {
        label: "CRN #",
        name: "vCRNNumber",
      }),
      createFormElement(AutocompleteField, {
        label: "Cap Type",
        name: "vCapType",
        options: transformRecordsToOptions(lookups.CapType),
        showSearch: true,
      }),
      createFormElement(AutocompleteField, {
        label: "Service",
        name: "vService",
        options: transformRecordsToOptions(lookups.Service),
        showSearch: true,
      }),
      createFormElement(TextField, {
        label: "Comp Screw",
        name: "vCompScrew",
        inputMode: "decimal",
      }),
      createFormElement(AutocompleteField, {
        label: "Bonnet",
        name: "vBonnet",
        options: transformRecordsToOptions(lookups.Bonnet),
        showSearch: true,
      }),
      createFormElement(AutocompleteField, {
        label: "Bellows Mat",
        name: "vBellowsMat",
        options: transformRecordsToOptions(lookups.BellowsMat),
        showSearch: true,
      }),
      createFormElement(TextField, {
        label: "BD Ring Upper",
        name: "vBDRingUpper",
      }),
      createFormElement(AutocompleteField, {
        label: "Body Material",
        name: "vBodyMaterial",
        options: transformRecordsToOptions(lookups.BodyMaterial),
        showSearch: true,
      }),
      createFormElement(AutocompleteField, {
        label: "Seat Material",
        name: "vSeatMaterial",
        options: transformRecordsToOptions(lookups.SoftSeat),
        showSearch: true,
      }),
      createFormElement(TextField, {
        label: "BD Ring Lower",
        name: "vBDRingLower",
      }),
      createFormElement(AutocompleteField, {
        label: "ASME",
        name: "ASME",
        options: getAsmeOptions(),
        showSearch: true,
      }),
      createFormElement(CheckboxField, {
        label: "Seal Broken",
        name: "vSealBroken",
        elementContainerCss: checkboxContainerStyles,
      }),
      createFormElement(CheckboxField, {
        label: "Restricted Lift",
        name: "vRestrictedLift",
        elementContainerCss: checkboxContainerStyles,
      }),
      createFormElement(TextField, {
        label: "Lift",
        name: "vLift",
      }),
    ],
    [lookups, uniqueSites, location, routeLocation]
  );
};

const ValveData = ({ disabled }: Props) => {
  const fields = useFields();
  return (
    <FieldWrapper>
      <FieldRenderer fields={fields} disabled={disabled} />
    </FieldWrapper>
  );
};

export default ValveData;
