import { useState } from "react";
import PageHeader from "@/components/PageHeader";
import PageTitle from "@/components/PageTitle";
import Page from "@/components/Page";
import PageContent from "@/components/PageContent";
import useRole from "@/auth/hooks/useRole";
import PageTabs from "@/components/PageTabs";
import LookupTab from "@/lookups/components/LookupTab";

const tabItems = [
  {
    key: "Valve Data",
    label: "Valve Data",
    children: <LookupTab tabKey="Valve Data" />,
  },
  {
    key: "Pre Test Data",
    label: "Pre Test Data",
    children: <LookupTab tabKey="Pre Test Data" />,
  },
  {
    key: "Parts",
    label: "Parts",
    children: <LookupTab tabKey="Parts" />,
  },
  {
    key: "Final Test",
    label: "Final Test",
    children: <LookupTab tabKey="Final Test" />,
  },
];

const Index = () => {
  const { isAdmin } = useRole();

  const [tab, setTab] = useState(tabItems[0].key);
  const activeTab = tabItems.find((item) => item.key === tab);

  return (
    <Page>
      <PageHeader withTabs>
        <PageTitle title="Lookups" />
        {isAdmin && (
          <PageTabs
            defaultActiveKey={tab}
            items={tabItems.map((item) => ({ ...item, children: undefined }))}
            onChange={setTab}
          />
        )}
      </PageHeader>
      <PageContent>{activeTab?.children}</PageContent>
    </Page>
  );
};

export default Index;
